import { PairingSettingDetailFormData } from "./types";

export function structurePairing(data: PairingSettingDetailFormData) {
  const immutData = JSON.parse(JSON.stringify(data));
  const { pairing_list } = immutData
  if (pairing_list) {
    const { client_product_ids, competitor_product_ids } = JSON.parse(JSON.stringify(pairing_list));
    const pairing_map = client_product_ids.reduce(
      (items: Array<{ client_product: string, competitor_product: string }>,
        i: string,
        index: number) => {
        items.push({ client_product: i, competitor_product: competitor_product_ids[index] });
        return items;
      }, []);
    delete immutData.pairing_list;
    return Object.assign({}, immutData, { pairing_map })
  }

}

export function unstructurePairing(data: any) {
  if (!data.pairing_map) {
    return data;
  }
  const immutData = JSON.parse(JSON.stringify(data));
  const { pairing_map } = immutData;

  const pairing_list = pairing_map.reduce((
    { client_product_ids, competitor_product_ids }:
      { client_product_ids: string[], competitor_product_ids: string[] },
    item: any) => {
    return {
      client_product_ids: client_product_ids.concat(item.client_product),
      competitor_product_ids: competitor_product_ids.concat(item.competitor_product)
    }
  }, { client_product_ids: [], competitor_product_ids: [] });
  delete immutData.pairing_map;
  return Object.assign({}, immutData, { pairing_list });
}
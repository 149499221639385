export interface Manufacturer {
    _id: string;
    name: string;
    logo_url: string;
    model_latest_version: string;
    market_share: string[];
    osa_brands: string;
    osa_list: string;
    adjacency: string[];
    cv_labels: string[];
    cv_modelwise_products: string[];
    is_client: boolean;
    createdAt: any;
    createdTime?: any;
    planogram: string[];
    countries: string[];
    categories: string[];
    tracked: string[];
    rsp_list: string[];
    sequence: string[];
    updatedAt: any;
    updatedTime?: any;
    verification_labels: any[];
}

export interface ManufacturerDetailFormData {
    _id: string;
    name: string;
    logo_url: string;
    model_latest_version: string;
    market_share: string[];
    osa_brands: string;
    osa_list: string;
    adjacency: string[];
    cv_labels: string[];
    cv_modelwise_products: string[];
    is_client: boolean;
    createdAt: any;
    createdTime?: any;
    planogram: string[];
    countries: string[];
    categories: string[];
    tracked: string[];
    rsp_list: string[];
    sequence: string[];
    updatedAt: any;
    updatedTime?: any;
    verification_labels: any[];
}

export const manufacturer_default = {
    _id: "",
    name: "",
    logo_url: "",
    model_latest_version: "",
    market_share: [],
    osa_brands: "",
    osa_list: "",
    adjacency: [],
    cv_labels: [],
    cv_modelwise_products:[],
    is_client: false,
    createdAt: "",
    createdTime: "",
    planogram: [],
    countries: [],
    categories: [],
    tracked: [],
    rsp_list: [],
    sequence: [],
    updatedAt: "",
    updatedTime: "",
    verification_labels: []
}

export interface ManufacturerUpdateProps {
    adjacency: string[];
    countries: string[];
    categories: string[];
    createdAt: string;
    cv_labels: string[];
    cv_modelwise_products: string[];
    is_client: boolean;
    logo_url: string;
    market_share: string[];
    model_latest_version: string;
    name: string;
    osa_brands: string;
    osa_list: string;
    planogram: string[];
    rsp_list: string[];
    sequence: string[];
    tracked: string[];
    updatedAt: string;
    _id: string;
    verification_labels: string[];
}

export const updateParams = (params: ManufacturerUpdateProps) => {
    return {
        adjacency: params.adjacency,
        countries: params.countries,
    categories: params.categories,
    createdAt: params.createdAt,
    cv_labels: params.cv_labels,
    cv_modelwise_products: params.cv_modelwise_products,
    is_client: params.is_client,
    logo_url: params.logo_url,
    market_share: params.market_share,
    model_latest_version: params.model_latest_version,
    name:params.name,
    osa_brands: params.osa_brands,
    osa_list:params.osa_list,
    planogram: params.planogram,
    rsp_list: params.rsp_list,
    sequence: params.sequence,
    tracked: params.tracked,
    updatedAt: params.updatedAt,
    _id: params._id,
    verification_labels: params.verification_labels,
    };
}
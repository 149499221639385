export interface Language {
    _id: string,
    language: string,
}

export const language_default = {
    _id: "",
    language: "",
}

export interface LanguageDetailFormData {
    _id: string;
    language: string;
}

export interface LanguageUpdateProps {
    language: string;
    _id: string;
}

export const updateParams = (params: LanguageUpdateProps) => {
    return {
        _id: params._id,
        language: params.language,
    };
}
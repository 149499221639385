export interface RspSetting {
    _id: string,
    date: string,
    manufacturer: string,
    manufacturer_name: string,
    country:string,
    country_name:string,
    category:string,
    category_name:string,
    rsp:Array<{products:string[],values:number[],regions:string[],channels:string[],outlet_classes:string[],retailers:string[]}>
}

export const rsp_setting_default = {
    _id: "",
    date: "",
    manufacturer: "",
    manufacturer_name: "",
    country:"",
    country_name: "",
    category:"",
    category_name: "",
    rsp:Array({products:[], values:[0],regions:[],channels:[],outlet_classes:[],retailers:[]})
}

export interface RspSettingDetailFormData {
    _id: string;
    date: string;
    manufacturer: string;
    manufacturer_name: string,
    country:string;
    country_name:string;
    category:string;
    category_name:string;
    rsp:Array<{products:string[],values:number[],regions:string[],channels:string[],outlet_classes:string[],retailers:string[]}>
}

export interface RspSettingUpdateProps {
    date: string;
    _id: string;
    manufacturer: string;
    country:string;
    category:string;
    rsp:Array<{products:string[],values:number[],regions:string[],channels:string[],outlet_classes:string[],retailers:string[]}>
}

export const updateParams = (params: RspSettingUpdateProps) => {
    return {
        _id: params._id,
        date: params.date,
        manufacturer: params.manufacturer,
        country:params.country,
        category:params.category,
        rsp:params.rsp
    };
}
import { message } from "antd";
import { ProductUpdateProps } from "../../pages/ProductsPage/types";
import apiClient from "../authorizedApiClient";
import { Modal } from "antd/lib/";

class ProductService {
  async getAll(page: string | number): Promise<any> {
    try {
      const result = await apiClient()
        .get("/products/all?page=" + page)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content: "Preview: " + JSON.stringify(error.response.data),
              });
            //else window.location.href = "/products";
          } else if (error.request) {
          } else {
          }
        });
      if (result)
        for (let i = 0; i < result.data.products.length; i++) {
          if (result.data.products[i].categories)
            for (
              let j = 0;
              j < result.data.products[i].categories.length;
              j++
            ) {
              result.data.products[i].categories[j].isDelete = false;
            }
        }
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }

  async searchAll(data: any, type: string): Promise<any> {
    try {
      const result = await apiClient().post(
        `/products/all/search/${type}`,
        data
      );
      for (let i = 0; i < result.data.length; i++) {
        if (result.data[i].categories)
          for (let j = 0; j < result.data[i].categories.length; j++) {
            result.data[i].categories[j].isDelete = false;
          }
      }
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }

  async checkLabelExists(data: any): Promise<any> {
    try {
      const result = await apiClient()
        .post(`/products/:id/categoryLabelExists`, data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            // else window.location.href = "/products";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async search1(data: any): Promise<any> {
    try {
      const result = await apiClient()
        .get(
          "/products/search?fields=" +
          data[0].fields +
          "&q=" +
          data[0].key +
          ":" +
          data[0].value +
          "&limit=10"
        )
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/products";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async searchByCountry(data: any): Promise<any> {
    try {
      if (
        data[0].page === null ||
        data[0].page === undefined ||
        data[0].page === ""
      )
        data[0].page = 1;
      if (data[0] && data[0].country_id && data[0].country_id !== "")
        var result = await apiClient()
          .get(
            "/products/search?q=" +
            data[0].key +
            ":" +
            data[0].value +
            "&country_id=" +
            data[0].country_id +
            "&page=" +
            data[0].page
          )
          .catch(function (error) {
            if (error.response) {
              let result = JSON.stringify(error.response.data).includes("jwt");
              if (result === false)
                Modal.error({
                  title: "Error details",
                  content:
                    "Payload: " +
                    JSON.stringify(data) +
                    " Preview: " +
                    JSON.stringify(error.response.data),
                });
              //else window.location.href = "/products";
            } else if (error.request) {
            } else {
            }
          });
      else
        var result = await apiClient()
          .get(
            "/products/search?q=" +
            data[0].key +
            ":" +
            data[0].value +
            "&page=" +
            data[0].page
          )
          .catch(function (error) {
            if (error.response) {
              let result = JSON.stringify(error.response.data).includes("jwt");
              if (result === false)
                Modal.error({
                  title: "Error details",
                  content:
                    "Payload: " +
                    JSON.stringify(data) +
                    " Preview: " +
                    JSON.stringify(error.response.data),
                });
              //else window.location.href = "/products";
            } else if (error.request) {
            } else {
            }
          });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async search(data: any): Promise<any> {
    try {
      var result = await apiClient()
        .get("/products/search/milkywayids?id=" + data[0].value)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/products";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async searchExact(data: any): Promise<any> {
    try {
      var result = await apiClient()
        .get("/products/search/exact?id=" + data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/products";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async updateProduct(data: ProductUpdateProps): Promise<any> {
    try {
      const result = await apiClient()
        .post("/products/update", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/products";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      //message.error(error);
    }
  }

  async addProduct(data: ProductUpdateProps): Promise<any> {
    try {
      const result = await apiClient()
        .post("/products/add", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/products";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      //message.error(error);
    }
  }

  async getSuggestion(data: string): Promise<string | undefined> {
    try {
      const result = await apiClient().get(`/products/search/${data}`);
      // console.log(result);

      return result.toString();
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      message.error(error);
    }
  }
}

export default ProductService;

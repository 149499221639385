import {
  Form,
  Input,
  Drawer,
  Button,
  Modal,
  message,
  Spin,
} from "antd/lib/";
import React, { useEffect, useState } from "react";
import {
  SubCategory3,
  SubCategory3DetailFormData,
} from "./types";
import { useForm } from "antd/lib/form/Form";
import { subCategoriesService3 } from "../../services/SubCategoriesService3";
import { updateParams } from "./types";

interface ISubCategory3Props {
  values: SubCategory3DetailFormData;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  onSubmit?: (data: SubCategory3DetailFormData) => void;
  onClose: () => void;
}

const SubCategory3Form: React.FC<ISubCategory3Props> = (props) => {
  const [form] = useForm();
  const [state, setState] = useState({
    loading: false,
    visible: false,
    isCreate: false,
  });
  const [changed, setChanged] = useState(false);

  var ObjectID = require("bson-objectid");

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields(["_id", "subcategory3"]);
      props.setVisible(false);
    }
  };

  async function validateProduct() {
    try {
      await form.validateFields();
      return true;
    } catch {
      return false;
    }
  }

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
    }
  }

  const onSubmit = async () => {
    try {
      const formValues = (await form.getFieldsValue()) as SubCategory3;

      let category;

      if (props.isCreate === true) {
        const _id = ObjectID();
        category = updateParams({
          ...formValues,
          _id: _id,
        });
      } else
        category = updateParams({
          ...formValues,
          _id: props.values._id,
        });
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await subCategoriesService3.updateSubCategory3(category);
        else res = await subCategoriesService3.addSubCategory3(category);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields(["subcategory3"]);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "subcategory3"]);
    props.setVisible(false);
  };

  useEffect(() => {
    form.setFieldsValue(props.values)
  }, [props.values]);

  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {props.visible && (
          <Spin tip="Loading..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              initialValues={props.values}
              style={{ fontWeight: "bold" }}
              onChange={() => {
                setChanged(true);
              }}
            >
              <Form.Item label="ID" style={{ display: "none" }} name={"_id"}>
                <Input value={props.values._id} disabled />
              </Form.Item>
              <Form.Item
                label="Sub Category"
                name={"subcategory3"}
                rules={[{ required: true, message: "" }]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Drawer>
    </>
  );
};

export default SubCategory3Form;

import { message } from "antd";
import { useState, useEffect } from "react";
import apiClient from "../../services/authorizedApiClient";

const usePopulateSettingsIdentifier = (data: any) => {
  const [region, setRegion] = useState([] as any);
  const [channel, setChannel] = useState([] as any);
  const [outletclass, setOutletclass] = useState([] as any);
  const [retailer, setRetailer] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function () {
      if (!data) {
        return;
      }
      setIsLoading(true);
      if (data.regions && data.regions.length) {
        try {
          const result = await apiClient().post("/regions/getByIds", {
            ids: data.regions
          });
          if (result.data) {
            setRegion(result.data.map((i: { _id: string, region: string }) =>
              ({ value: i._id, label: i.region })));
          }
        } catch {
          message.error("Something went wrong in fetching the region names from ids")
        }
      }
      if (data.channels && data.channels.length) {
        try {
          const result = await apiClient().post("/channels/getByIds", {
            ids: data.channels
          });
          if (result.data) {
            setChannel(result.data.map((i: { _id: string, channel: string }) =>
              ({ value: i._id, label: i.channel })));
          }
        } catch {
          message.error("Something went wrong in fetching the channel names from ids")
        }
      }

      if (data.outlet_classes && data.outlet_classes.length) {
        try {
          const result = await apiClient().post("/outlet_classes/getByIds", {
            ids: data.outlet_classes
          });
          if (result.data) {
            setOutletclass(result.data.map((i: { _id: string, outlet_class: string }) =>
              ({ value: i._id, label: i.outlet_class })));
          }
        } catch {
          message.error("Something went wrong in fetching the outlet names from ids")
        }
      }
      if (data.retailers && data.retailers.length) {
        try {
          const result = await apiClient().post("/retailers/getByIds", {
            ids: data.retailers
          });
          if (result.data) {
            setRetailer(result.data.map((i: { _id: string, name: string }) =>
              ({ value: i._id, label: i.name })));
          }
        } catch {
          message.error("Something went wrong in fetching the retailers names from ids")
        }
      }
      setIsLoading(false);
    })();
  }, []);

  return [region, channel, outletclass, retailer, isLoading, setRegion, setChannel, setOutletclass, setRetailer, setIsLoading];
}
export default usePopulateSettingsIdentifier;

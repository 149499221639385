import {
  Form,
  Input,
  Select,
  Drawer,
  Button,
  Modal,
  message,
  Spin,
} from "antd/lib/";
import React, { useEffect, useState } from "react";
import { Country, CountryDetailFormData, country_default } from "./types";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { countryService } from "../../services/CountryService";
import { updateParams } from "./types";
import apiClient from "../../services/authorizedApiClient";

interface ICOuntrylProps {
  values: Country;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  form: FormInstance<any>;
  onSubmit?: (data: CountryDetailFormData) => void;
  onClose: () => void;
}

const CountryForm: React.FC<ICOuntrylProps> = (props) => {
  const [state, setState] = useState({
    loading: false,
    visible: false,
  });
  const [changed, setChanged] = useState(false);
  const [values, setValues] = useState(props.values);
  const [languages, setLanguages] = useState([] as any);

  var ObjectID = require("bson-objectid");

  function makeid(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [form] = useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields(["_id", "country", "currency"]);
      props.setVisible(false);
    }
  };

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  async function validateProduct() {
    let validate = true;
    const formValues = (await form.getFieldsValue()) as CountryDetailFormData;

    let country = formValues.country ? formValues.country : values.country;
    if (country === "" || country === undefined) validate = false;

    let currency = formValues.currency ? formValues.currency : values.currency;
    if (currency === "" || currency === undefined) validate = false;

    return validate;
  }

  const onSubmit = async () => {
    try {
      const formValues = (await form.getFieldsValue()) as Country;

      let language;

      if (props.isCreate === true) {
        const _id = ObjectID();
        language = updateParams({
          ...values,
          ...formValues,
          languages: formValues.languages
            ? formValues.languages
            : props.values.languages,
          country: formValues.country
            ? formValues.country
            : props.values.country,
          currency: formValues.currency
            ? formValues.currency
            : props.values.currency,
          _id: _id,
        });
      } else
        language = updateParams({
          ...values,
          ...formValues,
          languages: formValues.languages
            ? formValues.languages
            : props.values.languages,
          country: formValues.country
            ? formValues.country
            : props.values.country,
          currency: formValues.currency
            ? formValues.currency
            : props.values.currency,
          _id: props.values._id,
        });
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await countryService.updateCountry(language);
        else res = await countryService.addCountry(language);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields(["language"]);
          setValues(country_default);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "channel"]);
    props.setVisible(false);
  };


  function handleLanguage(value: any) {
    props.values.languages = value;
    console.log(props.values.languages);
  }

  async function fetchLanguage(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrLanguages = [];
      const result = await apiClient().get(
        "/languages/search?q=language:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.languages.length; i++) {
          arrLanguages.push({
            value: result.data.languages[i]._id,
            label: result.data.languages[i].language,
          });
        }
      }
      await setLanguages(arrLanguages);
      setState({ ...state, loading: false });
    }
  }

  useEffect(() => {

    form.resetFields(["_id", "country", "currency"]);
    async function fetchCountryDetail() {
      setState({ ...state, loading: true });
      if (props.values.languages.length > 0) {
        try {
          const result = await apiClient().post("/languages/getByIds", {
            ids: props.values.languages
          });
          if (result.data) {
            setLanguages(result.data.map((i: { _id: string, language: string }) =>
              ({ value: i._id, label: i.language })));
          }
        } catch {
          message.error("Something went wrong in fetching the language names from ids")
        }
      }
      setState({ ...state, loading: false });

    }
    fetchCountryDetail();
  }, [props.values]);
  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {props.visible && (
          <Spin tip="Loading..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              initialValues={props.values}
              style={{ fontWeight: "bold" }}
              onChange={() => {
                setChanged(true);
              }}
            >
              <Form.Item label="ID" style={{ display: "none" }} name={"_id"}>
                <Input value={props.values._id} disabled />
              </Form.Item>
              <Form.Item
                label="Country"
                rules={[{ required: true, message: "" }]}
                name={"country"}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Currency"
                rules={[{ required: true, message: "" }]}
                name={"currency"}
              >
                <Input />
              </Form.Item>
              <Form.Item label={"Languages"} initialValue={[]}>
                <Select
                  style={{ width: "100%", fontWeight: "normal" }}
                  placeholder="Please select"
                  defaultValue={props.values.languages}
                  showSearch
                  showArrow={true}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchLanguage(e)}
                  onChange={(e) => handleLanguage(e)}
                  options={languages}
                ></Select>
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Drawer>
    </>
  );
};

export default CountryForm;

export interface Country {
    _id: string,
    country: string,
    currency: string,
    languages:string[]
}

export const country_default = {
    _id: "",
    country: "",
    currency: "",
    languages:[]
}

export interface CountryDetailFormData {
    _id: string;
    country: string;
    currency: string;
    languages:[];
}

export interface CountryUpdateProps {
    country: string;
    currency: string;
    _id: string;
    languages:string[];
}

export const updateParams = (params: CountryUpdateProps) => {
    return {
        _id: params._id,
        country: params.country,
        currency: params.currency,
        languages: params.languages,
    };
}
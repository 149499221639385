import React from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import { Menu, Row } from "antd";

const NavigationDrawer: React.FC = function (props) {
  const location = useLocation();
  const { SubMenu } = Menu;
  return (
    <Row>
      <Menu
        //mode="inline"
        mode="vertical"
        style={{ width: 200 }}
        defaultSelectedKeys={["/products"]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <Link to="/" key="sub0">
            Home
          </Link>
        </Menu.Item>
        <Menu.Item key="/brand">
          <Link to="/brand" key="sub4">
            Brands
          </Link>
        </Menu.Item>{" "}
        <Menu.Item key="/categories">
          <Link to="/categories" key="sub9">
            Categories
          </Link>
        </Menu.Item>
        <Menu.Item key="/channels">
          <Link to="/channels" key="sub10">
            Channels
          </Link>
        </Menu.Item>
        <Menu.Item key="/country">
          <Link to="/country" key="sub12">
            Countries
          </Link>
        </Menu.Item>
        <Menu.Item key="/languages">
          <Link to="/languages" key="sub11">
            Languages
          </Link>
        </Menu.Item>
        <Menu.Item key="/manufacturer">
          <Link to="/manufacturer" key="sub3">
            Manufacturers
          </Link>
        </Menu.Item>
        <Menu.Item key="/outlet_classes">
          <Link to="/outlet_classes" key="sub8">
            Outlet Classes
          </Link>
        </Menu.Item>
        <Menu.Item key="/products">
          <Link to="/products" key="sub1">
            Products
          </Link>
        </Menu.Item>
        <Menu.Item key="/regions">
          <Link to="/regions" key="sub13">
            Regions
          </Link>
        </Menu.Item>
        <Menu.Item key="/retailers">
          <Link to="/retailers" key="sub6">
            Retailers
          </Link>
        </Menu.Item>
        <SubMenu key="sub1" title="Settings">
          <Menu.Item key="/adjacency_settings">
            <Link to="/adjacency_settings" key="sub6">
              Adjacency
            </Link>
          </Menu.Item>
          <Menu.Item key="/eye_level_settings">
            <Link to="/eye_level_settings" key="sub12">
              Eye Level
            </Link>
          </Menu.Item>
          <Menu.Item key="/market_share_settings">
            <Link to="/market_share_settings" key="sub6">
              Market Share
            </Link>
          </Menu.Item>{" "}
          <Menu.Item key="/min_facing_settings">
            <Link to="/min_facing_settings" key="sub6">
              Min Facing
            </Link>
          </Menu.Item>
          <Menu.Item key="/new_product_settings">
            <Link to="/new_product_settings" key="sub6">
              New Product
            </Link>
          </Menu.Item>
          <Menu.Item key="/osa">
            <Link to="/osa" key="sub8">
              OSA
            </Link>
          </Menu.Item>
          <Menu.Item key="/pairing_settings">
            <Link to="/pairing_settings" key="sub6">
              Pairing
            </Link>
          </Menu.Item>
          <Menu.Item key="/rsp_settings">
            <Link to="/rsp_settings" key="sub6">
              RSP
            </Link>
          </Menu.Item>
          <Menu.Item key="/same_shelf_settings">
            <Link to="/same_shelf_settings" key="sub6">
              Same Shelf
            </Link>
          </Menu.Item>
          <Menu.Item key="/sequence_settings">
            <Link to="/sequence_settings" key="sub6">
              Sequence
            </Link>
          </Menu.Item>
          <Menu.Item key="/shelf_block_settings">
            <Link to="/shelf_block_settings" key="sub6">
              Shelf Block
            </Link>
          </Menu.Item>
          <Menu.Item key="/space_share_settings">
            <Link to="/space_share_settings" key="sub6">
              Space Share
            </Link>
          </Menu.Item>
          <Menu.Item key="/vertival_block_settings">
            <Link to="/vertival_block_settings" key="sub6">
              Vertical Block
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/stores">
          <Link to="/stores" key="sub7">
            Stores
          </Link>
        </Menu.Item>
        <Menu.Item key="/subbrand">
          <Link to="/subbrand" key="sub5">
            Sub Brand
          </Link>
        </Menu.Item>
        <Menu.Item key="/subcategory1">
          <Link to="/subcategory1" key="sub5">
            Sub Category 1
          </Link>
        </Menu.Item>
        <Menu.Item key="/subcategory2">
          <Link to="/subcategory2" key="sub5">
            Sub Category 2
          </Link>
        </Menu.Item>
        <Menu.Item key="/subcategory3">
          <Link to="/subcategory3" key="sub5">
            Sub Category 3
          </Link>
        </Menu.Item>
        <Menu.Item key="/users">
          <Link to="/users" key="sub2">
            Users
          </Link>
        </Menu.Item>
        <Menu.Item key="/delete">
          <Link to="/delete" key="delete">
            Delete
          </Link>
        </Menu.Item>
      </Menu>
    </Row>
  );
};

export default withRouter(NavigationDrawer);

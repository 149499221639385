import { message } from "antd";
import apiClient from "../authorizedApiClient";
import { Modal } from "antd/lib/";

class StoreService {
  async getAll(page: string | number): Promise<any> {
    try {
      const result = await apiClient()
        .get("/stores/all?page=" + page)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content: "Preview: " + JSON.stringify(error.response.data),
              });
            //else window.location.href = "/stores";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async searchAll(data: any, type: string): Promise<any> {
    try {
      if (
        data[0].page === null ||
        data[0].page === undefined ||
        data[0].page === ""
      )
        data[0].page = 1;
      const result = await apiClient()
        .get(
          "/stores/search?q=" +
            data[0].key +
            ":" +
            data[0].value +
            "&page=" +
            data[0].page
        )
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/stores";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      // message.error(error);
    }
  }

  async searchAll1(data: any, type: string): Promise<any> {
    try {
      console.log(data);
      const result = await apiClient()
        .post(`/stores/all/search/${type}`, data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/stores";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async search(data: any): Promise<any> {
    try {
      const result = await apiClient()
        .get(
          "/stores/search?fields=name&q=" + data[0].key + ":" + data[0].value
        )
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/stores";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async updateStore(data: any): Promise<any> {
    //try {
    const result = await apiClient()
      .post("/stores/update", data)
      .catch(function (error) {
        if (error.response) {
          let result = JSON.stringify(error.response.data).includes("jwt");
          if (result === false)
            Modal.error({
              title: "Error details",
              content:
                "Payload: " +
                JSON.stringify(data) +
                " Preview: " +
                JSON.stringify(error.response.data),
            });
          //else window.location.href = "/stores";
        } else if (error.request) {
        } else {
        }
      });

    return result;
    /*} catch (err: any) {
            const error = err.toString().indexOf('401') ? 'Failed to update!' : err.toString();
            message.error(error);
        }*/
  }

  async addStore(data: any): Promise<any> {
    try {
      const result = await apiClient()
        .post("/stores/add", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            // else window.location.href = "/stores";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to add!"
        : err.toString();
      //message.error(error);
    }
  }

  async checkIfExists(name: string): Promise<boolean | string> {
    try {
      const result = await apiClient().get(
        `/manufacturers/checkIfExists/${name}`
      );
      return result.status === 200;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "The name doesn't exist!"
        : "Can't validate name at this time";
      return error;
    }
  }

  async getManufacturerName(id: string): Promise<string> {
    try {
      // id = "5ffce78fac0ca954b0adeb5f";
      const result = await apiClient().get(
        `/manufacturers/getManufacturerName/${id}`
      );
      if (result.status === 200 && result.data !== "") {
        return result.data;
      }
      return "Can't get name at this time";
    } catch (err: any) {
      return "Can't get name at this time";
    }
  }
  async getManfSuggestions(query: string): Promise<[]> {
    try {
      // id = "5ffce78fac0ca954b0adeb5f";
      const result = await apiClient().get(`/manufacturers/search/${query}`);
      if (result.status === 200 && result.data !== "") {
        return result.data.results;
      }
      message.error("Can't get suggestions now");
      return [];
    } catch (_) {
      // return "Can't get name at this time";
      message.error("Can't get suggestions now");
      return [];
    }
  }
}

export default StoreService;

import { message } from "antd";
import { BrandUpdateProps } from "../../pages/BrandsPage/types";
import apiClient from "../authorizedApiClient";
import { Modal } from "antd/lib/";

class BrandService {
  async getAll(page: string | number): Promise<any> {
    try {
      const result = await apiClient()
        .get("/brands/all?page=" + page)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content: "Preview: " + JSON.stringify(error.response.data),
              });
            //else window.location.href = "/brand";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async searchAll(data: any, type: string): Promise<any> {
    try {
      if (
        data[0].page === null ||
        data[0].page === undefined ||
        data[0].page === ""
      )
        data[0].page = 1;
      const result = await apiClient()
        .get(
          "/brands/search?q=" +
            data[0].key +
            ":" +
            data[0].value +
            "&page=" +
            data[0].page
        )
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/brand";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async searchAll1(data: any, type: string): Promise<any> {
    try {
      const result = await apiClient()
        .post(`/brands/all/search/${type}`, data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/brand";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async search(data: any): Promise<any> {
    try {
      const result = await apiClient().get(
        "/products/search?fields=" +
          data[0].fields +
          "&q=" +
          data[0].key +
          ":" +
          data[0].value +
          "&limit=10"
      );
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }

  async updateBrand(data: BrandUpdateProps): Promise<any> {
    try {
      const result = await apiClient()
        .post("/brands/update", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/brand";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      //message.error(error);
    }
  }

  async addBrand(data: BrandUpdateProps): Promise<any> {
    try {
      const result = await apiClient()
        .post("/brands/add", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/brand";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      //message.error(error);
    }
  }

  async getSuggestion(data: string): Promise<string | undefined> {
    try {
      const result = await apiClient().get(`/products/search/${data}`);
      // console.log(result);

      return result.toString();
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      message.error(error);
    }
  }
}

export default BrandService;

export interface MarketShareSetting {
    _id: string,
    date: string,
    manufacturer: string,
    manufacturer_name: string,
    country:string,
    country_name:string,
    category:string,
    category_name:string,
    market_share:any[]
}

export const market_share_setting_default = {
    _id: "",
    date: "",
    manufacturer: "",
    manufacturer_name: "",
    country:"",
    country_name: "",
    category:"",
    category_name: "",
    market_share:Array({products:[], share:[],regions:[],channels:[],outlet_classes:[],retailers:[]})
}

export interface  MarketShareSettingDetailFormData {
    _id: string;
    date: string;
    manufacturer: string;
    manufacturer_name: string,
    country:string;
    country_name:string;
    category:string;
    category_name:string;
    market_share:Array<{products:string[],share:number[],regions:string[],channels:string[],outlet_classes:string[],retailers:string[]}>
}

export interface  MarketShareSettingUpdateProps {
    date: string;
    _id: string;
    manufacturer: string;
    country:string;
    category:string;
    market_share:any[];
}

export const updateParams = (params:  MarketShareSettingUpdateProps) => {
    return {
        _id: params._id,
        date: params.date,
        manufacturer: params.manufacturer,
        country:params.country,
        category:params.category,
        market_share:params.market_share
    };
}
import { MarketShareSettingDetailFormData } from "./types";

export function structureMSS(data: MarketShareSettingDetailFormData): any {
  const { market_share } = data;
  const market_share_data = market_share.map((i) => {
    const immutdata = JSON.parse(JSON.stringify(i));
    const { products, share } = immutdata
    if (products) {
      const product_map = products.reduce((pmap: { product: string; share: string; }[], p: string, i: string | number) => {
        pmap.push(({ product: p, share: share[i] }));
        return pmap;
      }, []);
      delete immutdata.products;
      delete immutdata.share;
      return Object.assign({}, immutdata, { product_mss: product_map });
    } else {
      return immutdata
    }
  });
  return Object.assign({}, data, { market_share: market_share_data })
}

export function unStructureMSS(data: any): any {
  const { market_share } = data;
  const market_share_data = market_share.map((i: any) => {
    const immutdata = JSON.parse(JSON.stringify(i));
    const { product_mss } = immutdata;
    const { products, share } = product_mss.reduce((
      { products, share }: { products: string[]; share: string[]; },
      p: { product: string, share: string }) => {
      if (p) {
        products.push(p.product);
        share.push(p.share);
      }
      return { products, share };
    }, { products: [], share: [] });
    delete immutdata.product_mss;
    return Object.assign({}, immutdata, { products, share });
  });
  return Object.assign({}, data, { market_share: market_share_data })
}

export interface Category {
    _id: string,
    category: string,
}

export const category_default = {
    _id: "",
    category: "",
    default_unidentified: "",
}

export interface CategoryDetailFormData {
    _id: string;
    category: string;
    default_unidentified: string,
}

export interface CategoryUpdateProps {
    category: string;
    _id: string;
    default_unidentified: string,
}

export const updateParams = (params: CategoryUpdateProps) => {
    return {
        _id: params._id,
        category: params.category,
        default_unidentified: params.default_unidentified,
    };
}
import { FormInstance, message } from "antd";
import { useEffect, useState } from "react";
import apiClient from "../../services/authorizedApiClient";

interface Option {
  value: string,
  label: string
};

function usePopulateIdentifierItems(form: FormInstance, identifier_type: string, identifier_id_var: any):

  [boolean, Option[], React.Dispatch<React.SetStateAction<boolean>>, React.Dispatch<React.SetStateAction<Option[]>>] {
  const typeofidentifierId = typeof identifier_id_var;
  const identifier_id = typeofidentifierId === 'string' || !Array.isArray(identifier_id_var) ? [identifier_id_var] : identifier_id_var;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [identifierIDs, setIdentifierID] = useState<Option[]>([]);
  const fetchDetails = async () => {

    if (identifier_type && identifier_id && identifier_id.length > 0) {
      setIsLoading(true);
      if (identifier_type === "product") {
          setIdentifierID(identifier_id.map((i: string) => ({ label: i, value: i})));
      } else if (identifier_type === "sub_brand") {
        try {
          const result = await apiClient().post("/sub_brands/getByIds", {
            ids: identifier_id
          });
          if (result.data) {
            setIdentifierID(result.data.map((i: { _id: string, sub_brand: string }) =>
              ({ value: i._id, label: i.sub_brand })));
          }
        } catch {
          message.error("Something went wrong in fetching the sub brand names from ids")
        }
      } else if (identifier_type === "brand") {
        try {
          const result = await apiClient().post("/brands/getByIds", {
            ids: identifier_id
          });
          if (result.data) {
            setIdentifierID(result.data.map((i: { _id: string, brand: string }) =>
              ({ value: i._id, label: i.brand })));
          }
        } catch {
          message.error("Something went wrong in fetching the brand names from ids")
        }
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  return [isLoading, identifierIDs, setIsLoading, setIdentifierID];
}

export default usePopulateIdentifierItems;

export interface KpiOsa {
    _id: string,
    manufacturer: string,
    manufacturer_name: string,
    country: string,
    country_name: string,
    category:string,
    category_name:string,
    date: string,
    osa: any[],
    cv_labels:string[]
}

export const kpi_osa_default = {
    _id: "",
    manufacturer: "",
    manufacturer_name: "",
    country: "",
    country_name: "",
    category:"",
    category_name: "",
    date: "",
    osa: [{ regions: [], channels: [],  outlet_classes: [], retailers: [], product_list: [], osa_type: [],target:0 }],
    cv_labels:[]
}

export interface KpiOsaDetailFormData {
    _id: string,
    manufacturer: string,
    manufacturer_name: string,
    country: string,
    country_name: string,
    category:string;
    category_name:string;
    date: string,
    osa:Array<{regions:string[],channels:string[],outlet_classes:string[],retailers:string[],product_list:string[],osa_type:string[],target:number}>
}

export interface KpiOsaUpdateProps {
    _id: string,
    manufacturer: string,
    country: string,
    category:string;
    date: string,
    osa: any[],
}

export const updateParams = (params: KpiOsaUpdateProps) => {
    return {
        _id: params._id,
        manufacturer: params.manufacturer,
        country: params.country,
        date: params.date,
        category:params.category,
        osa: params.osa,
    };
}

type ComputedProperties = 'full_name' | 'initials';

class User {
  first_name: string;
  last_name: string;
  email: string;

  constructor(data: {[key: string]: any}) {
    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';
    this.email = data.email_id || '';
  }

  get(key: ComputedProperties) {
    switch(key){
      case 'full_name': return `${this.first_name} ${this.last_name}`;
      case 'initials': return `${this.first_name.substring(0, 1)} ${this.last_name.substring(0, 1)}`;
      default: return this[key];
    }
  }
}

export default User;

import {
  DeleteFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  List,
  message,
  Modal,
  Progress,
  Row,
  Spin,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Product,
  updateParams,
} from "../../pages/ProductsPage/types";
import apiClient, { getToken } from "../../services/authorizedApiClient";
import { Image } from "antd";
import { productService } from "../../services/ProductService";
export const API_HOST = process.env.REACT_APP_API_SERVER;

interface ModalImageProps {
  images: string[];
  milkywayID: string;
  products: Product;
  formValues: Product;
  setProduct: React.Dispatch<React.SetStateAction<Product>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadImage: React.FC<ModalImageProps> = (props) => {
  const ref = useRef(null) as any;
  const [user, setUser ] = useState<null | any>(null);
  const [state, setState] = useState({
    images: props.images,
    loading: false,
    selectedFile: null as any,
    uploadPercentage: 0,
    uploading: false,
  });

  useEffect(() => {
    setState({
      loading: false,
      selectedFile: null as any,
      uploadPercentage: 0,
      images: props.images,
      uploading: false,
    });
    if (ref as any && ref.current) {
      ref.current.value = ''
    }
  }, [props.images])
  useEffect(() => {
    (async function() {
      const token = await getToken();
      const user = parseJwt(token);
      setUser(user)
    })();
    
  }, []);

  const [page, setPage] = useState(1);
  const handleCancel = () => {
    props.setVisible(false);
  };
  const handleOk = () => {
    props.setVisible(false);
  };
  const style = { padding: "8px 0", height: "auto", width: "auto" };
  function warning(image: string, index: number) {
    Modal.warning({
      cancelText: "Cancel",
      okText: "Delete",
      onOk: () => deleteImageHandler(image, index),
      centered: true,
      title: "Warning",
      content: "Do you really want to delete this image!",
      okCancel: true,
    });
  }

  const delay = (delay: number) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  function parseJwt(token: any) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const deleteImageHandler = async (image: string, index: number) => {
    const email = user?.emails[0] || ''
    try {
      const result = await apiClient().post("/products/deleteProductImage", {
        fileUrl: image,
        milkywayID: props.products.milkywayID,
        updated_by: email
      });
      if (result.status === 200) {
        const data: Product = result.data;
        const {base_picture_url } = data;
        let images = [];
        if (base_picture_url.length > 0) {
          images = base_picture_url;
        } else {
          images = [...state.images];
          images.splice(index, 1);

        }
        setState({
          ...state,
          images,
        })
        
        props.setProduct({
          ...props.products,
          ...props.formValues,
          base_picture_url: images,
        });
      } else {
        message.error("Error while deleting image!");
      }
    } catch (error: any) {
      message.error(error.toString());
    }
  };

  const fileChangeHandler = (event: any) => {
    setState({
      ...state,
      selectedFile: event.target.files[0],
    });
  };
  const uploadFileHandler = async () => {
    const formData = new FormData();
    formData.append("file", state.selectedFile, state.selectedFile.name);
    const result = await apiClient({
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data`,
    }).post(`/products/uploadImage/${props.milkywayID}`, formData, {
      onUploadProgress: (progress) => {
        setState({
          ...state,
          loading: true,
          uploading: true,
          uploadPercentage: Math.round(
            (progress.loaded / progress.total) * 100
          ),
        });
      },
    });
    if (result.status !== 200) message.error("Error while deleting image!");
    else {
      state.images.push(result.data);

      const products = updateParams({
        ...props.products,
        image_urls: props.products.image_urls as string[],
        base_picture_url: state.images as string[],
      });
      const token = await getToken();
      const user = parseJwt(token);
      let user_email = "";
      if (user) {
        user_email = user.emails[0];
      }
      products.updated_by = user_email;
      try {
        products.created_at =
          props.products.created_at === ""
            ? moment().toDate()
            : moment(props.products.created_at)
                .add(moment.duration(props.products.created_time))
                .toDate();
        const res = await productService.updateProduct(products);
        if (res.status !== 200) {
          message.error("Failed to fetch!");
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
      }
      await delay(4000).then((_) => {
        setState({
          images: state.images,
          loading: false,
          selectedFile: null,
          uploadPercentage: 0,
          uploading: false,
        });
        if (ref as any && ref.current) {
          ref.current.value = ''
        }
        props.setProduct({
          ...props.products,
          ...props.formValues,
          base_picture_url: state.images,
        });
      });
      // }
    }
  };
  return (
    <>
      <Modal
        title="Images"
        visible={props.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ marginTop: -90 }}
      >
        {props.images.length === 0 ? (
          <div>No images available to display</div>
        ) : state.loading ? (
          <Spin />
        ) : (
          <List
            grid={{
              gutter: 8,
              xs: 2,
            }}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "500px",
              maxWidth: "1000px",
              minWidth: "270px",
            }}
            pagination={{
              defaultCurrent: 1,
              total: state.images.length,
              showSizeChanger: false,
              pageSize: 40,
              onChange(current) {
                setPage(current);
              },
            }}
            dataSource={state.images}
            renderItem={(image, index) => (
              <p className="col-3">
                {index % 4 === 0}
                <br />
                <Image style={style} src={image} />
                <Button
                  icon={<DeleteFilled />}
                  onClick={() => {
                    warning(image, (page - 1) * 40 + index);
                  }}
                />
              </p>
            )}
          ></List>
        )}

        <Row style={{ marginTop: 20 }}>
          <Col>
            <input
              type="file"
              ref={ref}
              onChange={fileChangeHandler}
            ></input>
          </Col>
          {state.selectedFile !== null && (
            <Col>
              <Button
                icon={<DeleteFilled />}
                onClick={() => {
                  setState({
                    ...state,
                    selectedFile: null as any,
                    uploadPercentage: 0,
                    uploading: false,
                  });
                }}
              />
            </Col>
          )}
          <Col>
            <Button onClick={uploadFileHandler}> Upload</Button>
          </Col>
        </Row>
        <Row>
          {state.uploading && (
            <Progress percent={state.uploadPercentage} showInfo={false} />
          )}
        </Row>
      </Modal>
    </>
  );
};

export default UploadImage;

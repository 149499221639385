import { KpiOsa } from "./types";

function structureOSA(data: KpiOsa): any {
  const { osa } = data;
  const osa_data = osa.map((i) => {
    const immutdata = JSON.parse(JSON.stringify(i));
    const { product_list, osa_type } = immutdata
    if (product_list) {
      const product_map = product_list.reduce((pmap: { product: string; osa_type: string; }[], p: string, i: string | number) => {
        pmap.push(({ product: p, osa_type: osa_type[i] }));
        return pmap;
      }, []);
      delete immutdata.product_list;
      delete immutdata.osa_type;
      return Object.assign({}, immutdata, { product_osa: product_map });
    } else {
      return immutdata
    }
  });
  return Object.assign({}, data, { osa: osa_data })
}

function unStructureOSA(data: any): any {
  const { osa } = data;
  const osa_data = osa.map((i: any) => {
    const immutdata = JSON.parse(JSON.stringify(i));
    const { product_osa } = immutdata;
    const { product_list, osa_type } = product_osa.reduce((
      { product_list, osa_type }: { product_list: string[]; osa_type: string[]; },
      p: { product: string, osa_type: string }) => {
      if (p) {
        product_list.push(p.product);
        osa_type.push(p.osa_type);
      }
      return { product_list, osa_type };
    }, { product_list: [], osa_type: [] });
    delete immutdata.product_osa;
    return Object.assign({}, immutdata, { product_list, osa_type });
  });
  return Object.assign({}, data, { osa: osa_data })
}

export { structureOSA, unStructureOSA }
export interface VerticalBlockSetting {
  _id: string;
  date: string;
  manufacturer: string;
  manufacturer_name: string;
  country: string;
  country_name: string;
  category: string;
  category_name: string;
  vertical_block: any[];
}

export const vertical_block_setting_default = {
  _id: "",
  date: "",
  manufacturer: "",
  manufacturer_name: "",
  country: "",
  country_name: "",
  category: "",
  category_name: "",
  vertical_block: Array({
    regions: [],
    channels: [],
    outlet_classes: [],
    retailers: [],
    identifier_type: [],
    identifier_id: [],
    points: [],
    type: [],
    target: [],
  }),
};

export interface VerticalBlocktSettingDetailFormData {
  _id: string;
  date: string;
  manufacturer: string;
  manufacturer_name: string;
  country: string;
  country_name: string;
  category: string;
  category_name: string;
  vertical_block: Array<{
    regions: string[];
    channels: string[];
    outlet_classes: string[];
    retailers: string[];
    identifier_type: string[];
    identifier_id: any[][][];
    points: number[];
    type: string[];
    target: number[][][];
  }>;
}

export interface VerticalBlockSettingUpdateProps {
  date: string;
  _id: string;
  manufacturer: string;
  country: string;
  category: string;
  vertical_block: any[];
}

export const updateParams = (params: VerticalBlockSettingUpdateProps) => {
  return {
    _id: params._id,
    date: params.date,
    manufacturer: params.manufacturer,
    country: params.country,
    category: params.category,
    vertical_block: params.vertical_block,
  };
};

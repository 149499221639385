import {
  Form,
  Input,
  DatePicker,
  Select,
  Drawer,
  Button,
  Modal,
  Collapse,
  message,
  Spin,
  Row,
  Col,
  Divider,
  List,
} from "antd/lib/";
import React, { useEffect, useState } from "react";
import Option from "antd/lib/select";
import moment from "moment";
import {
  ShelfBlockSetting,
  ShelfBlocktSettingDetailFormData,
  shelf_block_setting_default,
} from "./types";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { shelfBlockSettingsService } from "../../services/ShelfBlockSettingsService";
import {
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { updateParams } from "./types";
import apiClient from "../../services/authorizedApiClient";
import NumberFormat from "react-number-format";
import { unStructureShelf } from "./util";
import ShelfBlockItem from "./ShelfBlockItem";
const { Panel } = Collapse;

interface IChannelProps {
  values: ShelfBlocktSettingDetailFormData;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  form: FormInstance<any>;
  onSubmit?: (data: ShelfBlocktSettingDetailFormData) => void;
  onClose: () => void;
}

const ShelfBlockSettingsForm: React.FC<IChannelProps> = (props) => {
  const [state, setState] = useState({
    loading: false,
    visible: false,
  });
  const [changed, setChanged] = useState(false);
  const [manufacturer, setManufacturer] = useState([] as any);
  const [country, setCountry] = useState([] as any);
  const [category, setCategory] = useState([] as any);
  const [pageSize, setPageSize] = useState(5);
  const [current, setCurrent] = useState(1);
  var ObjectID = require("bson-objectid");


  const [form] = useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };


  const deleteItem = (abc: () => void) => (
    <DeleteOutlined
      style={{ color: "#DC3445", fontSize: 16 }}
      onClick={() => {
        abc()
      }}
    />
  );

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields(["_id", "channel"]);
      props.setVisible(false);
    }
  };


  async function validateProduct() {
    try {
      await form.validateFields();
      return true
    } catch {
      return false;
    }
  }

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  const onSubmit = async () => {
    try {
      let channel;
      const sformValues = (await form.getFieldsValue()) as ShelfBlockSetting;
      const formValues = unStructureShelf(sformValues);
      if (props.isCreate === true) {
        const _id = ObjectID();
        channel = updateParams({
          ...formValues,
          _id: _id,
          ...formValues.date && { date: formValues.date.toISOString() }
        });
      } else
        channel = updateParams({
          ...formValues,
          _id: props.values._id,
          ...formValues.date && { date: formValues.date.toISOString() }
        });
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await shelfBlockSettingsService.update(channel);
        else res = await shelfBlockSettingsService.add(channel);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields(["channel"]);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "channel"]);
    props.setVisible(false);
  };

  async function fetchManufacturer(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrManufacturers = [];
      const result = await apiClient().get(
        "/manufacturers/search?q=name:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.manufacturers.length; i++) {
          arrManufacturers.push({
            value: result.data.manufacturers[i]._id,
            label: result.data.manufacturers[i].name,
          });
        }
      }
      await setManufacturer(arrManufacturers);
      setState({ ...state, loading: false });
    }
  }

  async function fetchCountry(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrCountries = [];
      const result = await apiClient().get(
        "/countries/search?q=country:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.countries.length; i++) {
          arrCountries.push({
            value: result.data.countries[i]._id,
            label: result.data.countries[i].country,
          });
        }
      }
      await setCountry(arrCountries);
      setState({ ...state, loading: false });
    }
  }


  async function fetchCategory(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrCatagories = [];
      const result = await apiClient().get(
        "/category/search?q=category:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.categories.length; i++) {
          arrCatagories.push({
            value: result.data.categories[i]._id,
            label: result.data.categories[i].category,
          });
        }
      }
      await setCategory(arrCatagories);
      setState({ ...state, loading: false });
    }
  }

  useEffect(() => {
    let arrManufacturer = [];
    setManufacturer([]);
    arrManufacturer.push({
      value: props.values.manufacturer,
      label: props.values.manufacturer_name,
    });
    setManufacturer(arrManufacturer);

    let arrCountry = [];
    setCountry([]);
    arrCountry.push({
      value: props.values.country,
      label: props.values.country_name,
    });
    setCountry(arrCountry);

    let arrCategory = [];
    setCategory([]);
    arrCategory.push({
      value: props.values.category,
      label: props.values.category_name,
    });
    setCategory(arrCategory);
    form.setFieldsValue(Object.assign({}, props.values, props.values.date && { date: moment(props.values.date) }));
  }, [props.values]);

  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {props.visible && (
          <Spin tip="Loading..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              initialValues={Object.assign({}, props.values, props.values.date && { date: moment(props.values.date) })}
              style={{ fontWeight: "bold" }}
              onChange={() => {
                setChanged(true);
              }}
            >
              <Form.Item label="ID" style={{ display: "none" }} name={"_id"}>
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true, message: "" }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Manufacturer"
                rules={[{ required: true, message: "" }]}
                name="manufacturer"
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  showSearch={true}
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchManufacturer(e)}
                  options={manufacturer}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                </Select>
              </Form.Item>
              <Form.Item
                label="Country"
                rules={[{ required: true, message: "" }]}
                name="country"
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchCountry(e)}
                  options={country}
                  getPopupContainer={(trigger) => trigger.parentNode}
                ></Select>
              </Form.Item>
              <Form.Item
                label="Category"
                rules={[{ required: true, message: "" }]}
                name="category"
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchCategory(e)}
                  options={category}
                  getPopupContainer={(trigger) => trigger.parentNode}
                ></Select>
              </Form.Item>
              <Form.Item label="Shelf Block" name="shelf_block">
                <Form.List name="shelf_block">
                  {(fields, { add, remove }) =>
                    <Collapse style={{ fontWeight: "normal" }}>
                      <Panel header="Items" key="shelf_block-1">
                        <Button
                          type="primary"
                          onClick={() => {
                            add();
                            setState({ ...state, loading: false });
                            setCurrent(~~(fields.length/pageSize + 1))
                          }}
                          style={{ marginBottom: 10 }}
                        >
                          Add New
                        </Button>
                        <List
                          itemLayout="vertical"
                          size="large"
                          pagination={{
                            current,
                            pageSize,
                            showQuickJumper: true,
                            onShowSizeChange: (cur, ps) => setPageSize(ps),
                            onChange: (cur, size) => {
                              setCurrent(cur);
                              setPageSize(size)
                            }
                          }}
                          dataSource={fields}
                          renderItem={(field: any) => (
                            <Collapse key={field.key}>
                              <Panel
                                header={`Item`}
                                key={field.name + "shelf_block"}
                                extra={deleteItem(() => remove(field.name))}
                              >
                                <ShelfBlockItem
                                  formListName={"shelf_block"}
                                  form={form}
                                  key={`shelf_block-${field.key}`}
                                  field={field}
                                  data={form.getFieldValue("shelf_block")[field.name]}
                                />
                              </Panel>
                            </Collapse>
                          )} />
                      </Panel>
                    </Collapse>
                  }
                </Form.List>
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Drawer>
    </>
  );
};


export default ShelfBlockSettingsForm;

export interface PairingSetting {
    _id: string,
    client_id: string,
    manufacturer_name: string,
    country:string,
    country_name:string,category:string,
    category_name:string,
    pairing_list:{}
}

export const pairing_setting_default = {
    _id: "",
    client_id: "",
    manufacturer_name: "",
    country:"",
    country_name: "",
    category:"",
    category_name: "",
    pairing_list:{client_product_ids:[], competitor_product_ids:[]}
}

export interface PairingSettingDetailFormData {
    _id: string;
    client_id: string;
    manufacturer_name: string,
    country:string;
    country_name:string;category:string;
    category_name:string;
    pairing_list:{client_product_ids:string[],competitor_product_ids:string[]}
}

export interface PairingSettingUpdateProps {
    _id: string;
    client_id: string;
    country:string;
    category:string;
    pairing_list:{client_product_ids:string[],competitor_product_ids:string[]};
}

export const updateParams = (params: PairingSettingUpdateProps) => {
    return {
        _id: params._id,
        client_id: params.client_id,
        country:params.country,
        category:params.category,
        pairing_list:params.pairing_list
    };
}
import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { Product } from "../../pages/ProductsPage/types";
import { search } from "../../services/ProductService/ProductAutoCompleteService";
import { CollapsibleProps } from "../Collapsible/types";
import { enumToField, enumToValues, TYPE } from "./type";
import debounce from "lodash/debounce";

interface IAutoCompleteInput {
  // values: Product;
  collapsible?: boolean;
  type: TYPE;
  collapsibleProps: CollapsibleProps;
  setValue: React.Dispatch<React.SetStateAction<Product>>;
}

const AutoCompleteInput: React.FC<IAutoCompleteInput> = (props) => {
  const [state, setState] = useState({
    list: [],
    loading: false,
    values: props.collapsibleProps.products,
    collValues: props.collapsibleProps,
  });

  useEffect(() => {

    setState({
      ...state,
      values: props.collapsibleProps.products,
      collValues: props.collapsibleProps,
    })
  },[props.collapsibleProps]);
  
  const search_list = async (val: string) => {
    //if (val === "" || val === null) val = "null";
    setState({ ...state, loading: true });
    try {
      const list = [
        TYPE.Manufacturer,
        TYPE.Brand,
        TYPE.MilkywayID,
        TYPE.Category_Manufacturer,
        TYPE.Country,
      ].includes(props.type)
        ? await search(
            val,
            "",
            enumToValues.get(props.type) || "",
            enumToField.get(props.type) || "name"
          )
        : [TYPE.SubBrand].includes(props.type)
        ? await search(
            val,
            "",
            enumToValues.get(props.type) || "",
            enumToField.get(props.type) || "brand"
          )
        : [TYPE.Country].includes(props.type)
        ? await search(
            val,
            "country",
            enumToValues.get(props.type) || "",
            enumToField.get(props.type) || "country"
          )
        : [TYPE.SubCategory1].includes(props.type)
        ? await search(
            val,
            "subcategory1",
            "category/subcategory1",
            enumToField.get(props.type) || "subcategory1"
          )
        : [TYPE.SubCategory2].includes(props.type)
        ? await search(
            val,
            "subcategory2",
            "category/subcategory2",
            enumToField.get(props.type) || "subcategory2"
          )
        : [TYPE.SubCategory3].includes(props.type)
        ? await search(
            val,
            "subcategory3",
            "category/subcategory3",
            enumToField.get(props.type) || "subcategory3"
          )
        : await search(
            val,
            "category",
            enumToValues.get(props.type) || "",
            enumToField.get(props.type) || "name"
          );
  
      setState({ ...state, list, loading: false });
      if (list && list.length === 0) {
        if (TYPE.MilkywayID === props.type) {
          setState({
            ...state,
            values: {
              ...state.values,
              milkywayid_parent: val,
            },
          });
          props.setValue({
            ...state.values,
            milkywayid_parent: val,
          });
        }
      }
    } catch(err) {
      setState({ ...state, loading: false });
    }
  };

  const onChangeHandler = async (e: string) => {
    if (e !== "") search_list(e);
    else {
      switch (props.type) {
        case TYPE.Country:
          {
            setState({
              ...state,
              values: {
                ...state.values,
                country: "",
                country_name: "",
              },
            });
            props.setValue({
              ...state.values,
              country: "",
              country_name: "",
            });
          }
          break;
        case TYPE.Manufacturer:
          {
            setState({
              ...state,
              values: {
                ...state.values,
                manufacturer: "",
                manufacturer_name: "",
              },
            });
            props.setValue({
              ...state.values,
              manufacturer: "",
              manufacturer_name: "",
            });
          }
          break;
        case TYPE.Brand:
          {
            setState({
              ...state,
              values: {
                ...state.values,
                brand: "",
                brand_name: "",
              },
            });
            props.setValue({
              ...state.values,
              brand: "",
              brand_name: "",
            });
          }
          break;
        case TYPE.SubBrand:
          {
            setState({
              ...state,
              values: {
                ...state.values,
                sub_brand: "",
                sub_brand_name: "",
              },
            });
            props.setValue({
              ...state.values,
              brand: "",
              brand_name: "",
            });
          }
          break;
        case TYPE.MilkywayID:
          {
            setState({
              ...state,
              values: {
                ...state.values,
                milkywayid_parent: "",
              },
            });
            props.setValue({
              ...state.values,
              milkywayid_parent: "",
            });
          }
          break;
        case TYPE.Category:
          {
            setState({
              ...state,
              collValues: {
                ...state.collValues,
                categories: {
                  ...state.collValues?.categories,
                  category: "",
                  category_name: "",
                },
              },
            });
            state.values.categories[state.collValues.index || 0] = {
              ...state.values.categories[state.collValues.index || 0],
              category: "",
            };
            props.setValue({
              ...state.values,
              categories: state.values.categories,
            });
          }
          break;
        case TYPE.Category_Manufacturer:
          {
            setState({
              ...state,
              collValues: {
                ...state.collValues,
                categories: {
                  ...state.collValues?.categories,
                  manufacturer: "",
                  manufacturer_name: "",
                },
              },
            });
            state.values.categories[state.collValues.index || 0] = {
              ...state.values.categories[state.collValues.index || 0],
              manufacturer: null,
            };
            props.setValue({
              ...state.values,
              categories: state.values.categories,
            });
          }
          break;
        case TYPE.SubCategory1:
          {
            setState({
              ...state,
              collValues: {
                ...state.collValues,
                categories: {
                  ...state.collValues?.categories,
                  subcategory1: "",
                  subcategory1_name: "",
                },
              },
            });
            state.values.categories[state.collValues.index || 0] = {
              ...state.values.categories[state.collValues.index || 0],
              subcategory1: null,
            };
            props.setValue({
              ...state.values,
              categories: state.values.categories,
            });
          }
          break;
        case TYPE.SubCategory2:
          {
            setState({
              ...state,
              collValues: {
                ...state.collValues,
                categories: {
                  ...state.collValues?.categories,
                  subcategory2: "",
                  subcategory2_name: "",
                },
              },
            });
            state.values.categories[state.collValues.index || 0] = {
              ...state.values.categories[state.collValues.index || 0],
              subcategory2: null,
            };
            props.setValue({
              ...state.values,
              categories: state.values.categories,
            });
          }
          break;
        case TYPE.SubCategory3:
          {
            setState({
              ...state,
              collValues: {
                ...state.collValues,
                categories: {
                  ...state.collValues?.categories,
                  subcategory3: "",
                  subcategory3_name: "",
                },
              },
            });
            state.values.categories[state.collValues.index || 0] = {
              ...state.values.categories[state.collValues.index || 0],
              subcategory3: null,
            };
            props.setValue({
              ...state.values,
              categories: state.values.categories,
            });
          }
          break;
      }
    }
    // if(props.isManufacturer)
    // 	setState({ ...state, values: {...state.values, manufacturer_name: e} });
    // else setState({ ...state, values: {...state.values, brand_name: e} });
  };
  const renderValues = () => {
    //console.log(state.list);
    const key: string = enumToField.get(props.type) || 'name';
    //Todo: Need to format the whole autocomplete input
    //@ts-ignore
    const initialLabel: string = state.values[key];
    let values = [{ value: initialLabel, label: initialLabel, id: state.values._id }];
    if (state.list && state.list.length > 0) {
      state.list.forEach((e) => {
        values.push({
          label: e[enumToField.get(props.type) || "name"],
          value: e[enumToField.get(props.type) || "name"],
          id: e["_id"],
        });
      });
      values.splice(0, 1);
    }
    return values;
  };
  return (
    <>
      <AutoComplete
        style={{ width: "100%", fontWeight: "normal" }}
        //allowClear
        defaultValue={
          props.type === TYPE.Manufacturer
            ? props.collapsible || false
              ? state.collValues.categories.manufacturer_name
              : state.values.manufacturer_name
            : props.type === TYPE.Country
            ? state.values.country_name
            : props.type === TYPE.MilkywayID
            ? state.values.milkywayid_parent
            : props.type === TYPE.Brand
            ? state.values.brand_name
            : props.type === TYPE.SubBrand
            ? state.values.sub_brand_name
            : props.type === TYPE.Category
            ? state.collValues?.categories.category_name
            : props.type === TYPE.Category_Manufacturer
            ? state.collValues?.categories.manufacturer_name
            : props.type === TYPE.SubCategory1
            ? state.collValues?.categories.subcategory1_name
            : props.type === TYPE.SubCategory2
            ? state.collValues?.categories.subcategory2_name
            : state.collValues?.categories.subcategory3_name
        }
        /*onSearch={(e) => {
          onChangeHandler(e);
        }}*/
        onSearch={
          //onSearch={debounce(handleSearch, 300)}
          debounce(onChangeHandler, 300)
        }
        options={renderValues()}
        //allowClear={true}
        onSelect={(_: any, option: { [x: string]: { toString: () => any; }; }) => {
          switch (props.type) {
            case TYPE.Country:
              {
                setState({
                  ...state,
                  values: {
                    ...state.values,
                    country: option["id"]?.toString(),
                    country_name: option["value"]?.toString(),
                    manufacturer: props.collapsibleProps.products.manufacturer,
                    manufacturer_name:
                      props.collapsibleProps.products.manufacturer_name,
                    milkywayid_parent:
                      props.collapsibleProps.products.milkywayid_parent,
                    brand: props.collapsibleProps.products.brand,
                    brand_name: props.collapsibleProps.products.brand_name,
                    sub_brand: props.collapsibleProps.products.sub_brand,
                    sub_brand_name:
                      props.collapsibleProps.products.sub_brand_name,
                  },
                });
                props.setValue({
                  ...state.values,
                  country: option["id"]?.toString(),
                  country_name: option["value"]?.toString(),
                  manufacturer: props.collapsibleProps.products.manufacturer,
                  manufacturer_name:
                    props.collapsibleProps.products.manufacturer_name,
                  milkywayid_parent:
                    props.collapsibleProps.products.milkywayid_parent,
                  brand: props.collapsibleProps.products.brand,
                  brand_name: props.collapsibleProps.products.brand_name,
                  sub_brand: props.collapsibleProps.products.sub_brand,
                  sub_brand_name:
                    props.collapsibleProps.products.sub_brand_name,
                });
              }
              break;
            case TYPE.Manufacturer:
              {
                setState({
                  ...state,
                  values: {
                    ...state.values,
                    manufacturer: option["id"]?.toString(),
                    manufacturer_name: option["value"]?.toString(),
                    country: props.collapsibleProps.products.country,
                    country_name: props.collapsibleProps.products.country_name,
                    milkywayid_parent:
                      props.collapsibleProps.products.milkywayid_parent,
                    brand: props.collapsibleProps.products.brand,
                    brand_name: props.collapsibleProps.products.brand_name,
                    sub_brand: props.collapsibleProps.products.sub_brand,
                    sub_brand_name:
                      props.collapsibleProps.products.sub_brand_name,
                  },
                });
                props.setValue({
                  ...state.values,
                  manufacturer: option["id"]?.toString(),
                  manufacturer_name: option["value"]?.toString(),
                  country: props.collapsibleProps.products.country,
                  country_name: props.collapsibleProps.products.country_name,
                  milkywayid_parent:
                    props.collapsibleProps.products.milkywayid_parent,
                  brand: props.collapsibleProps.products.brand,
                  brand_name: props.collapsibleProps.products.brand_name,
                  sub_brand: props.collapsibleProps.products.sub_brand,
                  sub_brand_name:
                    props.collapsibleProps.products.sub_brand_name,
                });
              }
              break;
            case TYPE.Brand:
              {
                setState({
                  ...state,
                  values: {
                    ...state.values,
                    brand: option["id"]?.toString(),
                    brand_name: option["value"]?.toString(),
                    country: props.collapsibleProps.products.country,
                    country_name: props.collapsibleProps.products.country_name,
                    manufacturer: props.collapsibleProps.products.manufacturer,
                    manufacturer_name:
                      props.collapsibleProps.products.manufacturer_name,
                    milkywayid_parent:
                      props.collapsibleProps.products.milkywayid_parent,
                    sub_brand: props.collapsibleProps.products.sub_brand,
                    sub_brand_name:
                      props.collapsibleProps.products.sub_brand_name,
                  },
                });
                props.setValue({
                  ...state.values,
                  brand: option["id"]?.toString(),
                  brand_name: option["value"]?.toString(),
                  country: props.collapsibleProps.products.country,
                  country_name: props.collapsibleProps.products.country_name,
                  manufacturer: props.collapsibleProps.products.manufacturer,
                  manufacturer_name:
                    props.collapsibleProps.products.manufacturer_name,
                  milkywayid_parent:
                    props.collapsibleProps.products.milkywayid_parent,
                  sub_brand: props.collapsibleProps.products.sub_brand,
                  sub_brand_name:
                    props.collapsibleProps.products.sub_brand_name,
                });
              }
              break;
            case TYPE.SubBrand:
              {
                setState({
                  ...state,
                  values: {
                    ...state.values,
                    sub_brand: option["id"]?.toString(),
                    sub_brand_name: option["value"]?.toString(),
                    country: props.collapsibleProps.products.country,
                    country_name: props.collapsibleProps.products.country_name,
                    manufacturer: props.collapsibleProps.products.manufacturer,
                    manufacturer_name:
                      props.collapsibleProps.products.manufacturer_name,
                    milkywayid_parent:
                      props.collapsibleProps.products.milkywayid_parent,
                    brand: props.collapsibleProps.products.brand,
                    brand_name: props.collapsibleProps.products.brand_name,
                  },
                });
                props.setValue({
                  ...state.values,
                  sub_brand: option["id"]?.toString(),
                  sub_brand_name: option["value"]?.toString(),
                  country: props.collapsibleProps.products.country,
                  country_name: props.collapsibleProps.products.country_name,
                  manufacturer: props.collapsibleProps.products.manufacturer,
                  manufacturer_name:
                    props.collapsibleProps.products.manufacturer_name,
                  milkywayid_parent:
                    props.collapsibleProps.products.milkywayid_parent,
                  brand: props.collapsibleProps.products.brand,
                  brand_name: props.collapsibleProps.products.brand_name,
                });
              }
              break;
            case TYPE.MilkywayID:
              {
                setState({
                  ...state,
                  values: {
                    ...state.values,
                    milkywayid_parent: option["value"]?.toString(),
                    country: props.collapsibleProps.products.country,
                    country_name: props.collapsibleProps.products.country_name,
                    manufacturer: props.collapsibleProps.products.manufacturer,
                    manufacturer_name:
                      props.collapsibleProps.products.manufacturer_name,
                    brand: props.collapsibleProps.products.brand,
                    brand_name: props.collapsibleProps.products.brand_name,
                    sub_brand: props.collapsibleProps.products.sub_brand,
                    sub_brand_name:
                      props.collapsibleProps.products.sub_brand_name,
                  },
                });
                props.setValue({
                  ...state.values,
                  milkywayid_parent: option["value"]?.toString(),
                  country: props.collapsibleProps.products.country,
                  country_name: props.collapsibleProps.products.country_name,
                  manufacturer: props.collapsibleProps.products.manufacturer,
                  manufacturer_name:
                    props.collapsibleProps.products.manufacturer_name,
                  brand: props.collapsibleProps.products.brand,
                  brand_name: props.collapsibleProps.products.brand_name,
                  sub_brand: props.collapsibleProps.products.sub_brand,
                  sub_brand_name:
                    props.collapsibleProps.products.sub_brand_name,
                });
              }
              break;
            case TYPE.Category:
              {
                setState({
                  ...state,
                  collValues: {
                    ...state.collValues,
                    categories: {
                      ...state.collValues?.categories,
                      category: option["id"]?.toString(),
                      category_name: option["value"]?.toString(),
                    },
                  },
                });
                state.values.categories[state.collValues.index || 0] = {
                  ...state.values.categories[state.collValues.index || 0],
                  category: option["id"].toString(),
                };
                props.setValue({
                  ...state.values,
                  categories: state.values.categories,
                });
              }
              break;
            case TYPE.Category_Manufacturer:
              {
                setState({
                  ...state,
                  collValues: {
                    ...state.collValues,
                    categories: {
                      ...state.collValues?.categories,
                      manufacturer: option["id"]?.toString(),
                      manufacturer_name: option["value"]?.toString(),
                    },
                  },
                });
                state.values.categories[state.collValues.index || 0] = {
                  ...state.values.categories[state.collValues.index || 0],
                  manufacturer: option["id"].toString(),
                };
                props.setValue({
                  ...state.values,
                  categories: state.values.categories,
                });
              }
              break;
            case TYPE.SubCategory1:
              {
                setState({
                  ...state,
                  collValues: {
                    ...state.collValues,
                    categories: {
                      ...state.collValues?.categories,
                      subcategory1: option["id"]?.toString(),
                      subcategory1_name: option["value"]?.toString(),
                    },
                  },
                });
                state.values.categories[state.collValues.index || 0] = {
                  ...state.values.categories[state.collValues.index || 0],
                  subcategory1: option["id"].toString(),
                };
                props.setValue({
                  ...state.values,
                  categories: state.values.categories,
                });
              }
              break;
            case TYPE.SubCategory2:
              {
                setState({
                  ...state,
                  collValues: {
                    ...state.collValues,
                    categories: {
                      ...state.collValues?.categories,
                      subcategory2: option["id"]?.toString(),
                      subcategory2_name: option["value"]?.toString(),
                    },
                  },
                });
                state.values.categories[state.collValues.index || 0] = {
                  ...state.values.categories[state.collValues.index || 0],
                  subcategory2: option["id"].toString(),
                };
                props.setValue({
                  ...state.values,
                  categories: state.values.categories,
                });
              }
              break;
            case TYPE.SubCategory3:
              {
                setState({
                  ...state,
                  collValues: {
                    ...state.collValues,
                    categories: {
                      ...state.collValues?.categories,
                      subcategory3: option["id"]?.toString(),
                      subcategory3_name: option["value"]?.toString(),
                    },
                  },
                });
                state.values.categories[state.collValues.index || 0] = {
                  ...state.values.categories[state.collValues.index || 0],
                  subcategory3: option["id"].toString(),
                };
                props.setValue({
                  ...state.values,
                  categories: state.values.categories,
                });
              }
              break;
          }
        }}
      />
      {/* </Form.Item> */}
    </>
  );
};

export default AutoCompleteInput;

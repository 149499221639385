import { Form, message, Select, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import usePopulateIdentifierItems from "../../hooks/usePopulateIdentifierItems";
import apiClient from "../../services/authorizedApiClient";

interface Props {
  formListName: NamePath[],
  form: FormInstance,
  childName: string,
  restField: any,
}

;

export default function AdjencyIndentifierItems({
  formListName,
  form,
  restField,
  childName,
}: Props) {
  const identifier_type = form.getFieldValue([...formListName, "identifier_type"] as NamePath);
  const identifier_id = form.getFieldValue([...formListName, "identifier_id", childName] as NamePath);

  const [isLoading, identifierIDs, setIsLoading, setIdentifierID] = usePopulateIdentifierItems(form, identifier_type, identifier_id);

  async function fetchIndentifierID(value: string) {
    if (value !== "") {
      if (!identifier_type) {
        message.error("Please select identifier type first")
      }
      setIsLoading(true);
      const arrData = [];
      if (identifier_type === "product") {
        const result = await apiClient().get(
          "/products/search/milkywayids?id=" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i].milkywayID,
              label: result.data[i].milkywayID,
            });
          }
        }
      } else if (
        identifier_type === "sub_brand"
      ) {
        const result = await apiClient().get(
          "/sub_brands/search?q=sub_brand:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.sub_brands.length; i++) {
            arrData.push({
              value: result.data.sub_brands[i]._id,
              label: result.data.sub_brands[i].sub_brand,
            });
          }
        }
      }
      setIdentifierID(arrData);
      setIsLoading(false);
    }
  }

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Form.Item
        {...restField}
        name={[childName]}
      >
        <Select
          style={{
            fontWeight:
              "normal",
          }}
          showSearch
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option?.props.label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option?.props.value
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          showArrow={
            true
          }
          onSearch={(
            e
          ) =>
            fetchIndentifierID(e)
          }
          options={identifierIDs}
          mode={
            "multiple"
          }
          getPopupContainer={(
            trigger
          ) =>
            trigger.parentNode
          }
        ></Select>
      </Form.Item>
    </Spin>
  )
}
export interface SubCategory1 {
    _id: string,
    subcategory1: string,
}

export const subcategory1_default = {
    _id: "",
    subcategory1: "",
}

export interface SubCategory1DetailFormData {
    _id: string;
    subcategory1: string;
}

export interface SubCategory1UpdateProps {
    subcategory1: string;
    _id: string;
}

export const updateParams = (params: SubCategory1UpdateProps) => {
    return {
        _id: params._id,
        subcategory1: params.subcategory1,
    };
}
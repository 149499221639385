import {
  CloseCircleOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Table,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import search from "../../helpers/search";
import useChannels from "../../hooks/useChannels";
import { channel_default } from "./types";
import { channelService } from "../../services/ChannelService";
import ChannelsForm from "../ChannelsPage/ChannelsForm";

const { Header, Content } = Layout;

const columns = [
  {
    title: "Channel",
    dataIndex: "channel",
    key: "channel",
  },
];
const ChannelsPage: React.FC = function () {
  const [form] = useForm();
  const [searching, setSearching] = useState(false);
  const [visible, setVisible] = useState(false);
  const [channelsToDisplay, setChannelsToDisplay] = useState([]);
  const [state, setState] = useState({
    isCreate: false,
    selected: channel_default,
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    pagination_search: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [channels, loadingChannels] = useChannels(state.pagination.current);
  const onSearch = async (key: string) => {
    const searchWord = form.getFieldValue("searchWord");
    if (!searchWord) {
      message.error("Please enter a valid search string!");
      return;
    }

    setSearching(true);
    // made array to cater more query types in future
    const searchData = [
      {
        key,
        value: encodeURIComponent(searchWord),
      },
    ];

    const searchedData = await search(searchData, channelService, key);
    setChannelsToDisplay(searchedData.data);
    setState({
      ...state,
      pagination_search: {
        ...state.pagination_search,
        total: searchedData.total,
        current: 1,
      },
    });
    setSearching(false);
  };

  const onClose = async () => {
    setState({
      ...state,
    });
    setSearching(true);
    const searchWord = form.getFieldValue("searchWord");
    if (searchWord) {
      const searchData = [
        {
          key: "channel",
          value: encodeURIComponent(searchWord),
        },
      ];

      const searchedData = await search(searchData, channelService, "channel");
      setChannelsToDisplay(searchedData.data);
    } else {
      const searchedData = await channelService.getAll(
        state.pagination.current
      );
      const data_channelsToDisplay = searchedData.data.channels
        ? searchedData.data.channels
        : channels;
      setChannelsToDisplay(data_channelsToDisplay);
    }

    setSearching(false);
  };

  const onSubmit = () => { };

  const handleTableChange = async (pagination: any) => {
    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination: pagination,
    });
    const data = await channelService.getAll(pagination.current);
    setChannelsToDisplay(data.data.channels);
    setSearching(false);
  };

  const handleTableSearchChange = async (pagination: any) => {
    const searchWord = form.getFieldValue("searchWord");
    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination_search: pagination,
    });
    const searchData = [
      {
        key: "channel",
        value: encodeURIComponent(searchWord),
        page: pagination.current,
      },
    ];

    const data = await search(searchData, channelService, "channel");
    setChannelsToDisplay(data.data);
    setSearching(false);
  };

  useEffect(() => {
    setChannelsToDisplay(channels.channels);
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        total: channels.total,
      },
    });
  }, [channels]);

  const loading = searching || loadingChannels;

  return (
    <Layout style={{ padding: 20 }}>
      <Header
        style={{
          textAlign: "left",
          backgroundColor: "#F0F2F5",
          padding: 0,
          borderRadius: 15,
        }}
      >
        <Row>
          <Col span={12}>
            <h3 style={{ fontWeight: "bold", paddingLeft: 0 }}>
              Channels (
              <span style={{ color: "#0a1e96" }}>
                {channels && channels.total ? channels.total : 0}
              </span>
              )
            </h3>
          </Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: 0 }}>
            <Button
              onClick={() => {
                setVisible(true);
                setState({
                  ...state,
                  isCreate: true,
                  selected: channel_default,
                });
              }}
              type="primary"
              shape="round"
              icon={<PlusCircleFilled />}
              style={{ width: 150 }}
            >
              Add New
            </Button>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row
          style={{
            textAlign: "left",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
            marginTop: 0,
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                What are you looking for?
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Form
                form={form}
                initialValues={{ searchWord: "" }}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                }}
                onValuesChange={(changedVals) => {
                  if (changedVals.searchWord === "") {
                    onClose();
                  }
                }}
              >
                <Col>
                  <Form.Item name="searchWord">
                    <Input
                      style={{ width: 300, borderRadius: 10 }}
                      placeholder={"Search"}
                      prefix={<SearchOutlined style={{ color: "#ccc" }} />}
                      suffix={
                        <Tooltip title="Clear Search">
                          <CloseCircleOutlined
                            onClick={() => {
                              setChannelsToDisplay(channels.channels);
                              setState({
                                ...state,
                                isCreate: false,
                                pagination: {
                                  current: 1,
                                  pageSize: 10,
                                  total: channels.total,
                                },
                              });
                              form.setFieldsValue({
                                searchWord: ""
                              });
                            }}
                            style={{
                              color: "rgba(0,0,0,.45)",
                            }}
                          />
                        </Tooltip>
                      }
                    ></Input>
                  </Form.Item>

                </Col>
                <Col style={{ textAlign: "right", paddingRight: 0 }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      onClick={() => onSearch("channel")}
                      style={{ width: 160 }}
                    >
                      Search By Channel
                    </Button>
                  </Form.Item>

                </Col>
              </Form>

            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: 20,
            backgroundColor: "white",
            marginTop: 20,
            borderRadius: 15,
          }}
        >
          <Col span={24}>
            {!form.getFieldValue("searchWord") ? (
              <Table
                //pagination={{ position: ["bottomRight", "topRight"] }}
                pagination={state.pagination}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: () => {
                      setState({
                        ...state,
                        isCreate: false,
                        selected: record,
                      });
                      setVisible(true);
                    },
                  };
                }}
                dataSource={channelsToDisplay}
                columns={columns}
                onChange={handleTableChange}
              />
            ) : (
              <Table
                //pagination={{ position: ["bottomRight"] }}
                pagination={state.pagination_search}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: () => {
                      setState({
                        ...state,
                        isCreate: false,
                        selected: record,
                      });
                      setVisible(true);
                      // console.log(record);
                    },
                  };
                }}
                dataSource={channelsToDisplay}
                onChange={handleTableSearchChange}
                columns={columns}
              />
            )}
          </Col>
        </Row>
      </Content>
      {visible && (
        <ChannelsForm
          visible={visible}
          form={form}
          values={{
            ...state.selected,
          }}
          isChanged={false}
          setVisible={setVisible}
          isCreate={state.isCreate}
          onClose={onClose}
        />
      )}
    </Layout>
  );
};

export default ChannelsPage;

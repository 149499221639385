import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, InputNumber, List, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { Option } from "antd/lib/mentions";
import { useState } from "react";
import VericalblockIdentifierItems from "./VericalblockIdentifierItems";

interface Props {
  formListName: NamePath[],
  form: FormInstance,
  field: any,
  remove: () => void
}

interface Option {
  value: string,
  label: string
};

export default function VerticalBlockIdentifier({
  formListName,
  remove,
  form,
  field,
}: Props) {
  const { name, ...restfield } = field;
  const [pageSize, setPageSize] = useState(3)
  const [current, setCurrent] = useState(1);

  return (
    <>
      <Row>
        <Col span={6}>
          <Form.Item
            {...restfield}
            name={[name, "identifier_type"]}
            label="Identifier Type"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              style={{
                fontWeight: "normal",
              }}
              showArrow={true}
              optionFilterProp="children"
              getPopupContainer={(
                trigger
              ) => trigger.parentNode}
              onChange={() => {
                form.setFieldValue([...formListName, name, "identifier_id_map"], []);
              }}
            >
              <Option
                value={"product"}
              >
                Product
              </Option>
              <Option
                value={"sub_brand"}
              >
                Sub Brand
              </Option>
              <Option
                value={"brand"}
              >
                Brand
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={1}></Col>
        <Col span={15}>
          <Form.Item
            {...restfield}
            name={[name, "identifier_id_map"]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Form.List name={[name, "identifier_id_map"]}>
              {(fields, { add, remove }) =>
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', marginBottom: '8px' }}>
                    Add Identifiers
                    <PlusCircleOutlined
                      style={{
                        width: "5%",
                        textAlign: "right",
                        color: "#52c41a",
                        fontSize: 16,
                      }}
                      onClick={() => {
                        add();
                        setCurrent(~~(fields.length/pageSize + 1))
                      }}
                    />
                  </div>
                  <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                      current,
                      pageSize,
                      showQuickJumper: true,
                      onShowSizeChange: (cur, ps) => setPageSize(ps),
                      onChange: (cur, size) => {
                        setCurrent(cur);
                        setPageSize(size)
                      }
                    }}
                    dataSource={fields}
                    renderItem={({ key, name: childName, ...restField }: any) => (
                      <VericalblockIdentifierItems
                        formListName={[...formListName, name]}
                        form={form}
                        listKey={key}
                        key={`identifier_id_map-${childName}`}
                        childName={childName}
                        restField={restField}
                        parentRemove={() => remove(childName)}
                      />

                    )} />
                </>

              }
            </Form.List>
          </Form.Item>
        </Col>

        <Col
          span={2}
          style={{
            textAlign: "center",
          }}
        >
          <DeleteOutlined
            style={{
              width: "5%",
              textAlign: "right",
              color: "#DC3445",
              fontSize: 16,
              marginTop: 10,
            }}
            onClick={() => remove()}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={10}>
          <Form.Item
            label="Type"
            {...restfield}
            name={[name, "type"]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              style={{
                fontWeight: "normal",
              }}
              showArrow={true}
              optionFilterProp="children"
              getPopupContainer={(
                trigger
              ) => trigger.parentNode}
            >
              <Option value={"base"}>
                base
              </Option>
              <Option value={"bonus"}>
                bonus
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={1}></Col>
        <Col xs={5}>
          <Form.Item
            {...restfield}
            name={[name, "points"]}
            label="Points"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Divider
        style={{
          backgroundColor: "#1891FF",
        }}
      ></Divider>
    </>
  )
}
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ApplicationRoutes from "./ApplicationRoutes";
import { Spin } from 'antd';
import { getToken } from "../services/authorizedApiClient";

const Router: React.FC = function () {
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const setIsAuth = async () => {
    const token = await getToken();
    if (token) setAuthenticated(true);
    setIsLoading(false);
  }
  useEffect(() => {
    setIsAuth();
  }, [])
  if (isLoading) {
    return (
      <div className="spin-icon-container">
        <Spin size="large"/>
      </div>
    )
  }
  return (
    <BrowserRouter>
      {authenticated && <ApplicationRoutes />}
    </BrowserRouter>
  );
};

export default Router;

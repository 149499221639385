import { Select } from "antd";
import { useEffect, useState } from "react";
import { Country } from "../pages/CountryPage/types";
import { search } from "../services/ProductService/ProductAutoCompleteService";

interface Props {
  intialValue: {
    label?: string,
    value: string,
  };
  value?: string;
  onChange?: (value: string) => void;
}
export default function SelectCountry({ intialValue, onChange, value }: Props) {

  const [options, setOptions] = useState([intialValue]);
  useEffect(() => {
    setOptions([intialValue]);
  }, [value]);

  const fetchCountry = async (val: string) => {
    const list = await search(
      val,
      "",
      "countries",
      "country"
    );
    if (list && list.length > 0 ){
      setOptions(list.map((i: Country) => ({ value: i._id, label: i.country })))
    }
  }
  return (
    <Select
      style={{ width: "100%", fontWeight: "normal" }}
      value={value}
      showSearch
      showArrow={true}
      optionFilterProp="children"
      filterOption={(input: any, option: any) =>
        option?.props.label
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0 ||
        option?.props.value
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      onSearch={(e: string) => fetchCountry(e)}
      options={options}
      onChange={onChange}
      getPopupContainer={(trigger: any) => trigger.parentNode}
      />
  );
}
import { Form, message, Select, Spin } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import searchRegex from "../../helpers/searchRegex";
import { categoriesService } from "../../services/CategoriesService";
import { countryService } from "../../services/CountryService";
import { productService } from "../../services/ProductService";

interface Props {
  field: any,
  data?: { country: string, category: string, label: Array<string> }
  fetchCountry: (val: string) => Promise<Array<{ value: string, label: string }>>,
  fetchCategory: (val: string) => Promise<Array<{ value: string, label: string }>>
  form: FormInstance,
}
export default function ManufactureLabel({
  field,
  fetchCategory,
  fetchCountry,
  data,
  form
}: Props) {
  const { name, key, ...restfield } = field;
  const [countries, setCountries] = useState([] as any);
  const [categories, setCategories] = useState([] as any);
  const [labels, setLabels] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  const onSearch = async (searchWord: string) => {
    if (!searchWord) {
      return;
    }
    const searchData = [
      {
        fields: "milkywayID",
        key: "milkywayID",
        value: encodeURIComponent(searchWord),
      },
    ];
    let searchedData = [{ milkywayID: "" }];
    searchedData = await searchRegex(searchData, productService, "milkywayID");
    let arrMan = [] as any;
    if (searchedData.length > 0)
      searchedData.forEach((item) =>
        arrMan.push({
          value: item.milkywayID,
          label: item.milkywayID,
        })
      );
    setLabels(arrMan);
  };

  useEffect(() => {
    (async function () {
      if (!data) {
        return;
      }
      const { country, category } = data;
      setIsLoading(true);
      const country_name =
        !country
          ? Promise.resolve("")
          : countryService.getCountryName(country);
      const category_name =
        !category
          ? Promise.resolve("")
          : categoriesService.getCategory(category);
      try {
        const [countryLabel, categoryLabel] = await Promise.all([country_name, category_name])
        setCountries([{
          value: country,
          label: countryLabel
        }]);
        setCategories([
          {
            value: category,
            label: categoryLabel
          }
        ]);
      } catch {
        message.error("Something went wrong in getting the names from ids")
      }
      setIsLoading(false);
    })();
    // loadData();
  }, []);

  return (
    <Spin tip="Loading..." spinning={isLoading}>

      <Form.Item
        {...restfield}
        name={[name, "country"]}
        label="Country"
        rules={[{ required: true, message: "" }]}
      >
        <Select
          style={{ width: "100%", fontWeight: "normal" }}
          showSearch
          showArrow={true}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.props.label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option?.props.value
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={async (e) => {
            const data = await fetchCountry(e)
            setCountries(data)
          }}
          options={countries}
        ></Select>
      </Form.Item>
      <Form.Item
        {...restfield}
        name={[name, "category"]}
        label="Category"
        rules={[{ required: true, message: "" }]}
      >
        <Select
          style={{ width: "100%", fontWeight: "normal" }}
          showSearch
          showArrow={true}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.props.label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option?.props.value
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={async (e) => {
            const data = await fetchCategory(e);
            setCategories(data);
          }}
          options={categories}
        ></Select>
      </Form.Item>
      <Form.Item
        {...restfield}
        name={[name, "labels"]}
        initialValue={[]}
        label="Labels"
      >
        <Select
          onSearch={onSearch}
          mode="multiple"
          style={{ width: "100%" }}
          dropdownStyle={{ height: "200px" }}
          placeholder=""
          showSearch
          showArrow={true}
          options={labels}
        >
        </Select>
      </Form.Item>
    </Spin>

  )
}
import { Select } from "antd";
import { useEffect, useState } from "react";
import { Manufacturer } from "../pages/ManufacturersPage/types";
import { search } from "../services/ProductService/ProductAutoCompleteService";

interface Props {
  intialValue: {
    label?: string,
    value: string,
  };
  value?: string;
  onChange?: (value: string) => void;
}
export default function SelectManufacturer({ intialValue, onChange, value }: Props) {

  const [options, setOptions] = useState([intialValue]);
  useEffect(() => {
    setOptions([intialValue]);
  }, [value]);

  const fetchManufacturers = async (val: string) => {
    const list = await search(
      val,
      "",
      "manufacturers",
      "name"
    );
    if (list && list.length > 0) {
      setOptions(list.map((i: Manufacturer) => ({ value: i._id, label: i.name })))
    }
  }
  return (
    <Select
      style={{ width: "100%", fontWeight: "normal" }}
      value={value}
      showSearch
      showArrow={true}
      optionFilterProp="children"
      filterOption={(input: any, option: any) =>
        option?.props.label
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0 ||
        option?.props.value
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      onSearch={(e: string) => fetchManufacturers(e)}
      options={options}
      onChange={onChange}
      getPopupContainer={(trigger: any) => trigger.parentNode}
    />
  );
}
import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Table,
  Input,
  Form,
  Row,
  Col,
  message,
  Tooltip,
  Select,
} from "antd";
import Option from "antd/lib/select";
import useProducts from "../../hooks/useProducts";
import { productService } from "../../services/ProductService";
import searchByCountry from "../../helpers/searchByCountry";
import {
  CloseCircleOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import ProductsForm from "./ProductsForm";
import { useForm } from "antd/lib/form/Form";
import { product_default } from "./types";
import apiClient from "../../services/authorizedApiClient";

const { Header, Content } = Layout;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Milkyway ID",
    dataIndex: "milkywayID",
    key: "milkywayID",
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer_name",
    key: "manufacturer_name",
  },
  {
    title: "Pack Size",
    dataIndex: "pack_size",
    key: "pack_size",
  },
];

const ProductsPage: React.FC = function () {
  const [state, setState] = useState({
    searchWord: "",
    key: "name",
    isCreate: false,
    selected: product_default,
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    pagination_search: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [products, loadingProducts] = useProducts(state.pagination.current);
  const [form] = useForm();
  const [searching, setSearching] = useState(false);
  const [visible, setVisible] = useState(false);
  const [productsToDisplay, setProductsToDisplay] = useState([]);
  const [country, setCountry] = useState([] as any);

  const onAdvancedSearch = async () => {
    const { searchWord, searchType, searchCountry } = await form.getFieldsValue();
    if (!searchWord) {
      message.error("Please enter a valid search string!");
      return;
    }
    setSearching(true);
    const searchData = [
      {
        key: searchType,
        value: encodeURIComponent(searchWord),
        country_id: searchCountry,
      },
    ];

    const searchedData = await searchByCountry(
      searchData,
      productService,
      searchType
    );
    setProductsToDisplay(searchedData.data);
    setState({
      ...state,
      pagination_search: {
        ...state.pagination_search,
        total: searchedData.total,
        current: 1,
      },
    });

    if (searchedData.data && searchedData.data.length > 0) {
      for (var i = 0; i < searchedData.data.length; i++) {
        var data_temp = product_default;
        data_temp = searchedData.data[i];
        if (data_temp.categories && data_temp.categories.length > 0) {
          for (var j = 0; j < data_temp.categories.length; j++) {
            const category = data_temp.categories[j] as any;
            category['isDelete'] = false;
          }
        }
        searchedData.data[i] = data_temp;
      }
    }
    setSearching(false);
  };

  const onClose = async () => {
    const { searchWord, searchType, searchCountry } = await form.getFieldsValue();
    setState({
      ...state,
      selected: product_default,
    });
    setSearching(true);
    if (searchWord) {
      const searchData = [
        {
          key: searchType ? searchType : "name",
          value: encodeURIComponent(searchWord),
          country_id: searchCountry,
          page: state.pagination_search.current,
          collection: "products",
        },
      ];

      const searchedData = await searchByCountry(
        searchData,
        productService,
        searchType ? searchType : "name"
      );
      if (searchedData.data && searchedData.data.length > 0) {
        for (var i = 0; i < searchedData.data.length; i++) {
          var data_temp = product_default;
          data_temp = searchedData.data[i];
          if (data_temp.categories && data_temp.categories.length > 0) {
            for (var j = 0; j < data_temp.categories.length; j++) {
              const category = data_temp.categories[j] as any;
              category['isDelete'] = false;
            }
          }
          searchedData.data[i] = data_temp;
        }
      }
      setProductsToDisplay(searchedData.data);
    } else {
      const searchedData = await productService.getAll(
        state.pagination.current
      );
      setProductsToDisplay(searchedData.data.products);
    }

    setSearching(false);
  };

  const handleTableChange = async (pagination: any) => {
    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination: pagination,
    });
    const data = await productService.getAll(pagination.current);
    setProductsToDisplay(data.data.products);
    setSearching(false);
  };

  const handleTableSearchChange = async (pagination: any) => {
    const { searchWord, searchType, searchCountry } = await form.getFieldsValue();

    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination_search: pagination,
    });
    const searchData = [
      {
        key: searchType,
        value: encodeURIComponent(searchWord),
        country_id: searchCountry,
        page: pagination.current,
        collection: "products",
      },
    ];

    const data = await searchByCountry(
      searchData,
      productService,
      searchType ? searchType : "name"
    );
    if (data.data && data.data.length > 0) {
      for (var i = 0; i < data.data.length; i++) {
        var data_temp = product_default;
        data_temp = data.data[i];
        if (data_temp.categories && data_temp.categories.length > 0) {
          for (var j = 0; j < data_temp.categories.length; j++) {
            const category = data_temp.categories[j] as any;
            category['isDelete'] = false;
          }
        }
        data.data[i] = data_temp;
      }
    }
    setProductsToDisplay(data.data);
    setSearching(false);
  };

  useEffect(() => {
    if (products.products && products.products.length > 0) {
      for (var i = 0; i < products.products.length; i++) {
        if (
          products.products[i].categories &&
          products.products[i].categories.length > 0
        ) {
          for (var j = 0; j < products.products[i].categories.length; j++) {
            products.products[i].categories[j].isDelete = false;
          }
        }
      }
    }
    setProductsToDisplay(products.products);
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        total: products.total,
      },
    });
    async function fetchDetail() {
      const arrData = [];
      arrData.push({
        value: "",
        label: "Any country",
      });
      const result_country = await apiClient().get("/countries/names");
      for (let i = 0; i < result_country.data.length; i++) {
        arrData.push({
          value: result_country.data[i]._id,
          label: result_country.data[i].country,
        });
      }
      setCountry(arrData);
    }

    fetchDetail();
  }, [products]);
  const loading = searching || loadingProducts;

  return (
    <Layout style={{ padding: 20 }}>
      <Header
        style={{
          textAlign: "left",
          backgroundColor: "#F0F2F5",
          padding: 0,
          borderRadius: 15,
        }}
      >
        <Row>
          <Col span={12}>
            <h3 style={{ fontWeight: "bold", paddingLeft: 0 }}>
              Products (
              <span style={{ color: "#0a1e96" }}>
                {products && products.total ? products.total : 0}
              </span>
              )
            </h3>
          </Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: 0 }}>
            <Button
              onClick={() => {
                setVisible(true);
                setState({
                  ...state,
                  isCreate: true,
                  selected: product_default,
                });
              }}
              type="primary"
              shape="round"
              icon={<PlusCircleFilled />}
              style={{ width: 150 }}
            >
              Add New
            </Button>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row
          style={{
            textAlign: "left",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
            marginTop: 0,
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                What are you looking for?
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Form
                form={form}
                initialValues={{
                  searchWord: "",
                  searchType: "name",
                  searchCountry: ""
                }}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <Form.Item
                    name={"searchWord"}
                  >
                    <Input
                      style={{ width: 300, borderRadius: 10 }}
                      prefix={<SearchOutlined style={{ color: "#ccc" }} />}
                      placeholder={"Search"}
                      suffix={
                        <Tooltip title="Clear Search">
                          <CloseCircleOutlined
                            onClick={() => {
                              setProductsToDisplay(products.products);
                              setState({
                                ...state,
                                isCreate: false,
                                pagination: {
                                  current: 1,
                                  pageSize: 10,
                                  total: products.total,
                                },
                              });
                              form.setFieldsValue({
                                searchWord: ""
                              });
                            }}
                            style={{
                              color: "rgba(0,0,0,.45)",
                            }}
                          />
                        </Tooltip>
                      }
                    ></Input>
                  </Form.Item>
                  <Form.Item
                    name={"searchType"}
                  >
                    <Select
                      style={{
                        fontWeight: "normal",
                        marginLeft: 20,
                        width: 200,
                      }}
                      showArrow={true}
                      optionFilterProp="children"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Option value={"name"}>Name</Option>
                      <Option value={"barcode"}>Barcode</Option>
                      <Option value={"milkywayID"}>Milkyway ID</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="searchCountry"
                  >
                    <Select
                      style={{
                        fontWeight: "normal",
                        marginLeft: 20,
                        width: 200,
                      }}
                      showSearch
                      showArrow={true}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option?.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                      options={country}
                    ></Select>
                  </Form.Item>



                </Col>
                <Col style={{ textAlign: "right", paddingRight: 0 }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      onClick={() => onAdvancedSearch()}
                      style={{ width: 150 }}
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: 20,
            backgroundColor: "white",
            marginTop: 20,
            borderRadius: 15,
          }}
        >
          <Col span={24}>
            {form.getFieldValue("searchWord") === "" ? (
              <Table
                pagination={state.pagination}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: () => {
                      setState({
                        ...state,
                        isCreate: false,
                        selected: record,
                      });
                      setVisible(true);
                    },
                  };
                }}
                dataSource={productsToDisplay}
                columns={columns}
                onChange={handleTableChange}
              />
            ) : (
              <Table
                pagination={state.pagination_search}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: () => {
                      setState({
                        ...state,
                        isCreate: false,
                        selected: record,
                      });
                      console.log(record);
                      setVisible(true);
                    },
                  };
                }}
                dataSource={productsToDisplay}
                columns={columns}
                onChange={handleTableSearchChange}
              />
            )}
          </Col>
        </Row>
      </Content>
      <ProductsForm
        visible={visible}
        values={!state.isCreate ? {
          ...state.selected,
          image_urls: [],
        } : {
          ...product_default,
          image_urls: [],
          base_picture_url: []
        }}
        setVisible={setVisible}
        onClose={onClose}
      />
    </Layout>
  );
};

export default ProductsPage;


import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_SERVER;

const apiClient = axios.create({
  baseURL: API_HOST,
  withCredentials: true,
  headers: {"Content-Type": "application/json"}
});

export default apiClient;

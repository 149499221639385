import {
  CloseCircleOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Table,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import search from "../../helpers/search";
import useSubCategories2 from "../../hooks/useSubCategories2";
import { subcategory2_default } from "./types";
import SubCategory2Form from "../SubCategory2Page/SubCategory2Form";
import { subCategoriesService2 } from "../../services/SubCategoriesService2";

const { Header, Content } = Layout;

const columns = [
  {
    title: "Sub Category",
    dataIndex: "subcategory2",
    key: "subcategory2",
  },
];
const SubCategory2Page: React.FC = function () {
  const [form] = useForm();
  const [searching, setSearching] = useState(false);
  const [visible, setVisible] = useState(false);
  const [categoriesToDisplay, setCategoriesToDisplay] = useState([]);
  const [state, setState] = useState({
    isCreate: false,
    selected: subcategory2_default,
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    pagination_search: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [categories, loadingCategories] = useSubCategories2(
    state.pagination.current
  );
  const onSearch = async (key: string) => {
    const { searchWord } = await form.getFieldsValue();

    if (!searchWord) {
      message.error("Please enter a valid search string!");
      return;
    }

    setSearching(true);
    const searchData = [
      {
        key,
        value: encodeURIComponent(searchWord),
      },
    ];

    const searchedData = await search(
      searchData,
      subCategoriesService2,
      "subcategory2"
    );
    setCategoriesToDisplay(searchedData.data);
    setState({
      ...state,
      pagination_search: {
        ...state.pagination_search,
        total: searchedData.total,
        current: 1,
      },
    });
    setSearching(false);
  };

  const onClose = async () => {
    const { searchWord } = await form.getFieldsValue();

    setState({
      ...state,
    });
    setSearching(true);
    if (searchWord) {
      const searchData = [
        {
          key: "subcategory2",
          value: encodeURIComponent(searchWord),
        },
      ];

      const searchedData = await search(
        searchData,
        subCategoriesService2,
        "subcategory2"
      );
      setCategoriesToDisplay(searchedData.data);
    } else {
      const searchedData = await subCategoriesService2.getAllSubCategory2(
        state.pagination.current
      );
      const data_categoriesToDisplay = searchedData.data.subcategories
        ? searchedData.data.subcategories
        : categories;
      setCategoriesToDisplay(data_categoriesToDisplay);
    }

    setSearching(false);
  };


  const handleTableChange = async (pagination: any) => {
    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination: pagination,
    });
    const data = await subCategoriesService2.getAllSubCategory2(
      pagination.current
    );
    setCategoriesToDisplay(data.data.subcategories);
    setSearching(false);
  };

  const handleTableSearchChange = async (pagination: any) => {
    const { searchWord } = await form.getFieldsValue();

    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination_search: pagination,
    });
    const searchData = [
      {
        key: "subcategory2",
        value: encodeURIComponent(searchWord),
        page: pagination.current,
      },
    ];

    const data = await search(
      searchData,
      subCategoriesService2,
      "subcategory2"
    );
    setCategoriesToDisplay(data.data);
    setSearching(false);
  };

  useEffect(() => {
    setCategoriesToDisplay(categories.subcategories);
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        total: categories.total,
      },
    });
  }, [categories]);

  const loading = searching || loadingCategories;

  return (
    <Layout style={{ padding: 20 }}>
      <Header
        style={{
          textAlign: "left",
          backgroundColor: "#F0F2F5",
          padding: 0,
          borderRadius: 15,
        }}
      >
        <Row>
          <Col span={12}>
            <h3 style={{ fontWeight: "bold", paddingLeft: 0 }}>
              Sub Categories 2 (
              <span style={{ color: "#0a1e96" }}>
                {categories && categories.total ? categories.total : 0}
              </span>
              )
            </h3>
          </Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: 0 }}>
            <Button
              onClick={() => {
                setVisible(true);
                setState({
                  ...state,
                  isCreate: true,
                  selected: subcategory2_default,
                });
              }}
              type="primary"
              shape="round"
              icon={<PlusCircleFilled />}
              style={{ width: 150 }}
            >
              Add New
            </Button>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row
          style={{
            textAlign: "left",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
            marginTop: 0,
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                What are you looking for?
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Form
                form={form}
                initialValues={{ searchWord: "" }}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                }}
                onValuesChange={(changedVals) => {
                  if (changedVals.searchWord === "") {
                    onClose();
                  }
                }}
              >
                <Col>
                  <Form.Item name="searchWord">
                    <Input
                      style={{ width: 300, borderRadius: 10 }}
                      placeholder={"Search"}
                      prefix={<SearchOutlined style={{ color: "#ccc" }} />}
                      suffix={
                        <Tooltip title="Clear Search">
                          <CloseCircleOutlined
                            onClick={() => {
                              setCategoriesToDisplay(categories.subcategories);
                              setState({
                                ...state,
                                isCreate: false,
                                pagination: {
                                  current: 1,
                                  pageSize: 10,
                                  total: categories.total,
                                },
                              });
                              form.setFieldsValue({
                                searchWord: ""
                              });
                            }}
                            style={{
                              color: "rgba(0,0,0,.45)",
                            }}
                          />
                        </Tooltip>
                      }
                    ></Input>
                  </Form.Item>

                </Col>
                <Col style={{ textAlign: "right", paddingRight: 0 }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => onSearch("subcategory2")}
                      style={{ width: 200 }}
                      htmlType="submit"
                    >
                      Search By Sub Category
                    </Button>
                  </Form.Item>

                </Col>
              </Form>

            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: 20,
            backgroundColor: "white",
            marginTop: 20,
            borderRadius: 15,
          }}
        >
          <Col span={24}>
            {!form.getFieldValue("searchWord") ? (
              <Table
                pagination={state.pagination}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: (event) => {
                      setState({
                        ...state,
                        selected: record,
                        isCreate: false,
                      });
                      setVisible(true);
                    },
                  };
                }}
                dataSource={categoriesToDisplay}
                columns={columns}
                onChange={handleTableChange}
              />
            ) : (
              <Table
                pagination={state.pagination_search}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: () => {
                      setState({
                        ...state,
                        isCreate: false,
                        selected: record,
                      });
                      setVisible(true);
                    },
                  };
                }}
                dataSource={categoriesToDisplay}
                columns={columns}
                onChange={handleTableSearchChange}
              />
            )}
          </Col>
        </Row>
      </Content>
      <SubCategory2Form
        values={{
          ...state.selected,
        }}
        visible={visible}
        isChanged={false}
        setVisible={setVisible}
        isCreate={state.isCreate}
        onClose={onClose}
      />
    </Layout>
  );
};

export default SubCategory2Page;

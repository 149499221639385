export interface Region {
    _id: string,
    region: string,
    manufacturer: string,
    manufacturer_name: string,
    country: string,
    country_name:string,
}

export const region_default = {
    _id: "",
    region: "",
    manufacturer: "",
    country: "",
    country_name:"",
    manufacturer_name:""
}

export interface RegionDetailFormData {
    _id: string;
    region: string;
    manufacturer: string;
    country: string;
    country_name:string,
    manufacturer_name:string
}

export interface RegionUpdateProps {
    region: string;
    manufacturer: string;
    country: string;
    _id: string;
}

export const updateParams = (params: RegionUpdateProps) => {
    return {
        _id: params._id,
        region: params.region,
        country: params.country,
        manufacturer: params.manufacturer,
    };
}
export interface EyeLevelSetting {
    _id: string,
    date: string,
    manufacturer: string,
    manufacturer_name: string,
    country:string,
    country_name:string,
    category:string,
    category_name:string,
    eye_level:any[]
}

export const eye_level_setting_default = {
    _id: "",
    date: "",
    manufacturer: "",
    manufacturer_name: "",
    country:"",
    country_name: "",
    category:"",
    category_name: "",
    eye_level:Array({regions:[],channels:[],outlet_classes:[],retailers:[],identifier_type:[],identifier_id:[],points:[0],type:[],shelf_numbers:[]})
}

export interface EyeLevelSettingDetailFormData {
    _id: string;
    date: string;
    manufacturer: string;
    manufacturer_name: string,
    country:string;
    country_name:string;
    category:string;
    category_name:string;
    eye_level:Array<{regions:string[],channels:string[],outlet_classes:string[],retailers:string[],identifier_type:string[],identifier_id:string[],points:number[],type:string[],shelf_numbers:number[]}>
}

export interface EyeLevelSettingUpdateProps {
    date: string;
    _id: string;
    manufacturer: string;
    country:string;
    category:string;
    eye_level:any[];
}

export const updateParams = (params: EyeLevelSettingUpdateProps) => {
    return {
        _id: params._id,
        date: params.date,
        manufacturer: params.manufacturer,
        country:params.country,
        category:params.category,
        eye_level:params.eye_level
    };
}
import { Button, Collapse, Form, List, Select, Spin } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { useState } from "react";
import usePopulateSettingsIdentifier from "../../hooks/usePopulateSettingsIdentifier";
import apiClient from "../../services/authorizedApiClient";
import EyeLevelItemIdentifier from "./EyeLevelItemIdentifier";
const { Panel } = Collapse;
interface Props {
  formListName: NamePath,
  field: any,
  data: any,
  form: any,
}

export default function EyeLevelItem({
  formListName,
  form,
  field,
  data,
}: Props) {
  const { name, ...restfield } = field;
  const [
    region,
    channel,
    outletclass,
    retailer,
    isLoading,
    setRegion,
    setChannel,
    setOutletclass,
    setRetailer,
    setIsLoading
  ] = usePopulateSettingsIdentifier(data);

  const [pageSize, setPageSize] = useState(3)
  const [current, setCurrent] = useState(1);
  async function fetchRegion(value: string) {
    if (value !== "") {
      setIsLoading(true)
      const arrRegions = [];
      const result = await apiClient().get("/regions/search?q=region:" + value);
      if (result) {
        for (var i = 0; i < result.data.regions.length; i++) {
          arrRegions.push({
            value: result.data.regions[i]._id,
            label: result.data.regions[i].region,
          });
        }
      }
      await setRegion(arrRegions);
      setIsLoading(false)
    }
  }

  async function fetchOutletclass(value: string) {
    if (value !== "") {
      setIsLoading(true)
      const arrOutletclasses = [];
      const result = await apiClient().get(
        "/outlet_classes/search?q=outlet_class:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.outlet_classes.length; i++) {
          arrOutletclasses.push({
            value: result.data.outlet_classes[i]._id,
            label: result.data.outlet_classes[i].outlet_class,
          });
        }
      }
      await setOutletclass(arrOutletclasses);
      setIsLoading(false)
    }
  }

  async function fetchChannel(value: string) {
    if (value !== "") {
      setIsLoading(true)
      const arrChannels = [];
      const result = await apiClient().get(
        "/channels/search?q=channel:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.channels.length; i++) {
          arrChannels.push({
            value: result.data.channels[i]._id,
            label: result.data.channels[i].channel,
          });
        }
      }
      await setChannel(arrChannels);
      setIsLoading(false)
    }
  }


  async function fetchRetailer(value: string) {
    if (value !== "") {
      setIsLoading(true)
      const arrRetailers = [];
      const result = await apiClient().get("/retailers/search?q=name:" + value);
      if (result) {
        for (var i = 0; i < result.data.retailers.length; i++) {
          arrRetailers.push({
            value: result.data.retailers[i]._id,
            label: result.data.retailers[i].name,
          });
        }
      }
      await setRetailer(arrRetailers);
      setIsLoading(false)
    }
  }

  //   (async function () {
  //     if (!data) {
  //       return;
  //     }
  //     setIsLoading(true);
  //     if (data.regions.length > 0) {
  //       try {
  //         const result = await apiClient().post("/regions/getByIds", {
  //           ids: data.regions
  //         });
  //         if (result.data) {
  //           setRegion(result.data.map((i: { _id: string, region: string }) =>
  //             ({ value: i._id, label: i.region })));
  //         }
  //       } catch {
  //         message.error("Something went wrong in fetching the region names from ids")
  //       }
  //     }
  //     setChannel([]);
  //     if (data.channels) {
  //       try {
  //         const result = await apiClient().post("/channels/getByIds", {
  //           ids: data.outlet_classes
  //         });
  //         if (result.data) {
  //           setChannel(result.data.map((i: { _id: string, name: string }) =>
  //             ({ value: i._id, label: i.name })));
  //         }
  //       } catch {
  //         message.error("Something went wrong in fetching the channel names from ids")
  //       }
  //     }
  //     setOutletclass([]);

  //     if (data.outlet_classes) {
  //       try {
  //         const result = await apiClient().post("/outlet_classes/getByIds", {
  //           ids: data.outlet_classes
  //         });
  //         if (result.data) {
  //           setOutletclass(result.data.map((i: { _id: string, name: string }) =>
  //             ({ value: i._id, label: i.name })));
  //         }
  //       } catch {
  //         message.error("Something went wrong in fetching the outlet names from ids")
  //       }
  //     }
  //     setRetailer([]);
  //     if (data.retailers) {
  //       try {
  //         const result = await apiClient().post("/retailers/getByIds", {
  //           ids: data.retailers
  //         });
  //         if (result.data) {
  //           setRetailer(result.data.map((i: { _id: string, name: string }) =>
  //             ({ value: i._id, label: i.name })));
  //         }
  //       } catch {
  //         message.error("Something went wrong in fetching the retailers names from ids")
  //       }
  //     }
  //     setIsLoading(false);
  //   })();
  // }, []);
  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Form.Item
        {...restfield}
        name={[name, "regions"]}
        label="Regions"
        initialValue={[]}
      >
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option?.props.label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option?.props.value
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={(e) => fetchRegion(e)}
          options={region}
          getPopupContainer={(trigger) =>
            trigger.parentNode
          }
        ></Select>
      </Form.Item>
      <Form.Item
        label="Channels"
        {...restfield}
        name={[name, "channels"]}
        initialValue={[]}
      >
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.props.label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option?.props.value
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={(e) => fetchChannel(e)}
          options={channel}
          getPopupContainer={(trigger) =>
            trigger.parentNode
          }
        ></Select>
      </Form.Item>
      <Form.Item
        label="Outlet Classes"
        {...restfield}
        name={[name, "outlet_classes"]}
        initialValue={[]}
      >
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.props.label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option?.props.value
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={(e) => fetchOutletclass(e)}
          options={outletclass}
          getPopupContainer={(trigger) =>
            trigger.parentNode
          }
        ></Select>
      </Form.Item>
      <Form.Item
        {...restfield}
        name={[name, "retailers"]}
        label="Retailers"
        initialValue={[]}
      >
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.props.label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option?.props.value
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={(e) => fetchRetailer(e)}
          options={retailer}
          getPopupContainer={(trigger) =>
            trigger.parentNode
          }
        ></Select>
      </Form.Item>
      <Form.Item name={[name, "identifier_map"]}>
        <Form.List name={[name, "identifier_map"]}>
          {(eyeIdentifierFields, { add, remove }) =>
            <Collapse style={{ fontWeight: "normal" }}>
              <Panel header="Identifier" key="identifier_map-1">
                <Button
                  type="primary"
                  onClick={() => {
                    add();
                    setCurrent(~~(eyeIdentifierFields.length/pageSize + 1))
                  }}
                  style={{ marginBottom: 10 }}
                >
                  Add New
                </Button>
                <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    current,
                    pageSize,
                    showQuickJumper: true,
                    onShowSizeChange: (cur, ps) => setPageSize(ps),
                    onChange: (cur, size) => {
                      setCurrent(cur);
                      setPageSize(size)
                    }
                  }}
                  dataSource={eyeIdentifierFields}
                  renderItem={(eyeIdentifierField: any) => (

                    <EyeLevelItemIdentifier
                      form={form}
                      key={`identifier_map-${eyeIdentifierField.key}`}
                      field={eyeIdentifierField}
                      formListName={[formListName, name, "identifier_map"]}
                      remove={() => remove(eyeIdentifierField.name)}
                    />
                  )} />
              </Panel>
            </Collapse>
          }
        </Form.List>
      </Form.Item>

    </Spin>

  )
}
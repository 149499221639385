export interface SubCategory2 {
    _id: string,
    subcategory2: string,
}

export const subcategory2_default = {
    _id: "",
    subcategory2: "",
}

export interface SubCategory2DetailFormData {
    _id: string;
    subcategory2: string;
}

export interface SubCategory2UpdateProps {
    subcategory2: string;
    _id: string;
}

export const updateParams = (params: SubCategory2UpdateProps) => {
    return {
        _id: params._id,
        subcategory2: params.subcategory2,
    };
}
import { VerticalBlocktSettingDetailFormData } from "./types";

export function structureVerticalBlockSetting(data: VerticalBlocktSettingDetailFormData) {
  const { vertical_block } = data;
  const unewproduct = vertical_block.map((i) => {
    const immutdata = JSON.parse(JSON.stringify(i));
    const { identifier_id, identifier_type, points, type, target } = immutdata;
    if (identifier_id.length > 0 || identifier_type.length > 0) {
      const map = identifier_type.map((item: string, i: number) => ({
        identifier_id_map: (identifier_id[i] || []).reduce((items: any, ids: any, j: number) => {
          if (ids && ids.length > 0) {
            const mymap = ids.map((id: string, index: number) => ({
              id: id,
              target: target[i][j][index]
            }));
            items.push(mymap);
          }
          return items;
        }, []),
        identifier_type: item || '',
        points: points[i] || null,
        type: type[i] || ''
      }));
      delete immutdata.identifier_id;
      delete immutdata.identifier_type;
      delete immutdata.points;
      delete immutdata.type;
      delete immutdata.target;
      return Object.assign({}, immutdata, { identifier_map: map });
    } else {
      return i
    }
  });
  return Object.assign({}, data, { vertical_block: unewproduct })
}

export function unstructureVerticalBlockSetting(data: any) {
  const { vertical_block } = data;
  const unewproduct = vertical_block.map((i: any) => {
    const immutdata = JSON.parse(JSON.stringify(i))
    const { identifier_map } = immutdata
    if (identifier_map && identifier_map.length > 0) {
      const { identifier_id, identifier_type, points, type, target } =
        identifier_map.reduce(({ identifier_id, identifier_type, points, type, target }: any, i: any) => {
          if (!i) {
            return { identifier_id, identifier_type, points, type, target };
          }
          const { identifier_id_map } = i
          if (identifier_id_map && identifier_id_map.length > 0) {
            const { ids, targets } = identifier_id_map.reduce(({ ids, targets }: { ids: string[], targets: string[] },
              i: Array<{ id: string, target: string }>) => {
              if (i) {
                const { idsArr, targetArr } = i.reduce((items: any, arrItem: { id: string, target: string }) => {
                  if (arrItem) {
                    items.idsArr.push(arrItem.id);
                    items.targetArr.push(arrItem.target);
                  }
                  return items;
                }, { idsArr: [], targetArr: [] });
                if (idsArr.length > 0) ids.push(idsArr);
                if (targetArr.length > 0) targets.push(targetArr)
              }

              return { ids, targets };
            }, { ids: [], targets: [] });
            i.identifier_id_map && identifier_id.push(ids);
            i.identifier_id_map && target.push(targets);
          }
          i.identifier_type && identifier_type.push(i.identifier_type);
          i.points && points.push(i.points);
          i.type && type.push(i.type)
          return { identifier_id, identifier_type, points, type, target };
        }, { identifier_id: [], identifier_type: [], points: [], type: [], target: [] });
      delete immutdata.identifier_map;
      return Object.assign({}, immutdata, { identifier_id, identifier_type, points, type, target });
    } else {
      return i
    }
  });
  return Object.assign({}, data, { vertical_block: unewproduct })
}
import { AutoComplete } from "antd";
import React, { useState } from "react";
import { Brand } from "../../pages/BrandsPage/types";
import { search } from "../../services/ProductService/ProductAutoCompleteService";
import { CollapsibleBrandProps } from "../Collapsible/types";
import { enumToField, enumToValues, TYPE } from "./type";

interface IAutoCompleteInput {
  // values: Product;
  collapsible?: boolean;
  type: TYPE;
  collapsibleBrandProps: CollapsibleBrandProps;
  setValue: React.Dispatch<React.SetStateAction<Brand>>;
}

const AutoCompleteInputBrand: React.FC<IAutoCompleteInput> = (props) => {
  const [state, setState] = useState({
    list: [],
    loading: false,
    values: props.collapsibleBrandProps.brands,
    collValues: props.collapsibleBrandProps,
  });

  const search_list = async (val: string) => {
    //if (val === "" || val === null) val = "null";
    setState({ ...state, loading: true });
    const list = [TYPE.Manufacturer, TYPE.Category_Manufacturer].includes(
      props.type
    )
      ? await search(
          val,
          "",
          enumToValues.get(props.type) || "",
          enumToField.get(props.type) || "name"
        )
      : [TYPE.Brand].includes(props.type)
      ? await search(
          val,
          "",
          enumToValues.get(props.type) || "",
          enumToField.get(props.type) || "brand"
        )
      : await search(
          val,
          "category",
          enumToValues.get(props.type) || "",
          enumToField.get(props.type) || "category"
        );
    //   console.log(list);
    setState({ ...state, list, loading: false });
  };

  const onChangeHandler = async (e: string) => {
    if (e !== "") search_list(e);
    /*else {
      switch (props.type) {
        case TYPE.Manufacturer:
          {
            setState({
              ...state,
              values: {
                ...state.values,
                manufacturer: "",
                manufacturer_name: "",
              },
            });
            props.setValue({
              ...state.values,
              manufacturer: "",
              manufacturer_name: "",
            });
          }
          break;
        case TYPE.Brand:
          {
            setState({
              ...state,
              values: {
                ...state.values,
                parent_brand: "",
                parent_brand_name: "",
              },
            });
            props.setValue({
              ...state.values,
              parent_brand: "",
              parent_brand_name: "",
            });
          }
          break;
        case TYPE.Category:
          {
            setState({
              ...state,
              values: {
                ...state.values,
                category: "",
                category_name: "",
              },
            });
            props.setValue({
              ...state.values,
              category: "",
              category_name: "",
            });
          }
          break;
        case TYPE.Category_Manufacturer:
          {
            setState({
              ...state,
              collValues: {
                ...state.collValues,
                categories: {
                  ...state.collValues?.categories,
                  manufacturer: "",
                  manufacturer_name: "",
                },
              },
            });
            state.values.categories[state.collValues.index || 0] = {
              ...state.values.categories[state.collValues.index || 0],
              manufacturer: "",
            };
            props.setValue({
              ...state.values,
              categories: state.values.categories,
            });
          }
          break;

        case TYPE.Category_Category:
          {
            setState({
              ...state,
              collValues: {
                ...state.collValues,
                categories: {
                  ...state.collValues?.categories,
                  category: "",
                  category_name: "",
                },
              },
            });
            state.values.categories[state.collValues.index || 0] = {
              ...state.values.categories[state.collValues.index || 0],
              category: "",
            };
            props.setValue({
              ...state.values,
              categories: state.values.categories,
            });
          }
          break;
      }
    }*/
    // if(props.isManufacturer)
    // 	setState({ ...state, values: {...state.values, manufacturer_name: e} });
    // else setState({ ...state, values: {...state.values, brand_name: e} });
  };
  const renderValues = () => {
    let valuess = [{ value: "", label: "", id: "" }];
    if (state.list && state.list.length > 0) {
      state.list.forEach((e) => {
        if (
          props.type === TYPE.Manufacturer ||
          props.type === TYPE.Category_Manufacturer
        )
          valuess.push({
            label: e[enumToField.get(props.type) || "name"],
            value: e[enumToField.get(props.type) || "name"],
            id: e["_id"],
          });
        else if (props.type === TYPE.Brand)
          valuess.push({
            label: e[enumToField.get(props.type) || "brand"],
            value: e[enumToField.get(props.type) || "brand"],
            id: e["_id"],
          });
        else
          valuess.push({
            label: e[enumToField.get(props.type) || "category"],
            value: e[enumToField.get(props.type) || "category"],
            id: e["_id"],
          });
      });
      valuess.splice(0, 1);
    }
    return valuess;
  };

  return (
    <>
      <AutoComplete
        //allowClear
        defaultValue={
          props.type === TYPE.Manufacturer
            ? props.collapsible || false
              ? state.values.manufacturer_name
              : state.values.manufacturer_name
            : props.type === TYPE.Brand
            ? state.values.parent_brand_name
            : //: props.type === TYPE.Category
              state.values.category_name
          //: props.type === TYPE.Category_Manufacturer
          //? state.collValues?.categories.manufacturer_name
          //: state.collValues?.categories.category_name
        }
        onSearch={(e) => {
          onChangeHandler(e);
        }}
        options={renderValues()}
        onSelect={(_: any, option: { [x: string]: { toString: () => string; }; }) => {
          console.log(state.values);
          switch (props.type) {
            case TYPE.Manufacturer:
              {
                setState({
                  ...state,
                  values: {
                    ...state.values,
                    manufacturer: option["id"]?.toString(),
                    manufacturer_name: option["value"]?.toString(),
                    category: props.collapsibleBrandProps.brands.category,
                    category_name:
                      props.collapsibleBrandProps.brands.category_name,
                    parent_brand:
                      props.collapsibleBrandProps.brands.parent_brand,
                    parent_brand_name:
                      props.collapsibleBrandProps.brands.parent_brand_name,
                  },
                });
                state.values.manufacturer = option["id"].toString();
                state.values.manufacturer_name = option["value"].toString();
                props.setValue({
                  ...state.values,
                  manufacturer: state.values.manufacturer,
                  manufacturer_name: state.values.manufacturer_name,
                  category: props.collapsibleBrandProps.brands.category,
                  category_name:
                    props.collapsibleBrandProps.brands.category_name,
                  parent_brand: props.collapsibleBrandProps.brands.parent_brand,
                  parent_brand_name:
                    props.collapsibleBrandProps.brands.parent_brand_name,
                });
              }
              break;
            case TYPE.Brand:
              {
                setState({
                  ...state,
                  values: {
                    ...state.values,
                    parent_brand: option["id"]?.toString(),
                    parent_brand_name: option["value"]?.toString(),
                    category: props.collapsibleBrandProps.brands.category,
                    category_name:
                      props.collapsibleBrandProps.brands.category_name,
                    manufacturer:
                      props.collapsibleBrandProps.brands.manufacturer,
                    manufacturer_name:
                      props.collapsibleBrandProps.brands.manufacturer_name,
                  },
                });
                state.values.parent_brand = option["id"]?.toString();
                state.values.parent_brand_name = option["value"]?.toString();
                props.setValue({
                  ...state.values,
                  parent_brand: state.values.parent_brand,
                  parent_brand_name: state.values.parent_brand_name,
                  category: props.collapsibleBrandProps.brands.category,
                  category_name:
                    props.collapsibleBrandProps.brands.category_name,
                  manufacturer: props.collapsibleBrandProps.brands.manufacturer,
                  manufacturer_name:
                    props.collapsibleBrandProps.brands.manufacturer_name,
                });
              }
              break;
            case TYPE.Category:
              {
                setState({
                  ...state,
                  values: {
                    ...state.values,
                    category: option["id"]?.toString(),
                    category_name: option["value"]?.toString(),
                    manufacturer:
                      props.collapsibleBrandProps.brands.manufacturer,
                    manufacturer_name:
                      props.collapsibleBrandProps.brands.manufacturer_name,
                    parent_brand:
                      props.collapsibleBrandProps.brands.parent_brand,
                    parent_brand_name:
                      props.collapsibleBrandProps.brands.parent_brand_name,
                  },
                });
                state.values.category = option["id"]?.toString();
                state.values.category_name = option["value"]?.toString();
                props.setValue({
                  ...state.values,
                  category: state.values.category,
                  category_name: state.values.category_name,
                  manufacturer: props.collapsibleBrandProps.brands.manufacturer,
                  manufacturer_name:
                    props.collapsibleBrandProps.brands.manufacturer_name,
                  parent_brand: props.collapsibleBrandProps.brands.parent_brand,
                  parent_brand_name:
                    props.collapsibleBrandProps.brands.parent_brand_name,
                });
              }
              break;
            /*case TYPE.Category_Manufacturer:
              {
                setState({
                  ...state,
                  collValues: {
                    ...state.collValues,
                    categories: {
                      ...state.collValues?.categories,
                      manufacturer: option["id"]?.toString(),
                      manufacturer_name: option["value"]?.toString(),
                    },
                  },
                });
                state.values.categories[state.collValues.index || 0] = {
                  ...state.values.categories[state.collValues.index || 0],
                  manufacturer: option["id"]?.toString(),
                };
                props.setValue({
                  ...state.values,
                  categories: state.values.categories,
                });
              }
              break;
            case TYPE.Category_Category:
              {
                setState({
                  ...state,
                  collValues: {
                    ...state.collValues,
                    categories: {
                      ...state.collValues?.categories,
                      category: option["id"]?.toString(),
                      category_name: option["value"]?.toString(),
                    },
                  },
                });
                state.values.categories[state.collValues.index || 0] = {
                  ...state.values.categories[state.collValues.index || 0],
                  category: option["id"].toString(),
                };
                props.setValue({
                  ...state.values,
                  categories: state.values.categories,
                });
              }
              break;*/
          }
        }}
      />
      {/* </Form.Item> */}
    </>
  );
};

export default AutoCompleteInputBrand;

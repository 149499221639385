export interface Channel {
    _id: string,
    channel: string,
}

export const channel_default = {
    _id: "",
    channel: "",
}

export interface ChannelDetailFormData {
    _id: string;
    channel: string;
}

export interface ChannelUpdateProps {
    channel: string;
    _id: string;
}

export const updateParams = (params: ChannelUpdateProps) => {
    return {
        _id: params._id,
        channel: params.channel,
    };
}
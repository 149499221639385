export interface Brand {
    _id: string,
    brand: string,
    parent_brand: string | null,
    parent_brand_name: string,
    manufacturer: string,
    manufacturer_name: string,
    category: string,
    category_name: string,
    categories: any[]
}

export const brand_default = {
    _id: "",
    brand: "",
    parent_brand: "",
    parent_brand_name: "",
    manufacturer: "",
    manufacturer_name: "",
    category: "",
    category_name: "",
    categories: [{ _id: "5f0e5790d6c8cf2b8efc4b9b", manufacturer: "", category: ""}]
}

export interface BrandDetailFormData {
    _id: string;
    brand: string;
    manufacturer: string;
    manufacturer_name: string;
    category: string;
    category_name: string;
    parent_brand: string | null;
    parent_brand_name: string;
    categories: any[];
}

export interface BrandUpdateProps {
    brand: string;
    manufacturer: string;
    category: string;
    parent_brand: string | null;
    categories: any[];
    _id: string;
}

export const updateParams = (params: BrandUpdateProps) => {
    return {
        _id: params._id,
        brand: params.brand,
        parent_brand: params.parent_brand,
        manufacturer: params.manufacturer,
        category: params.category,
        categories: params.categories,
    };
}
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Collapse, Divider, Form, InputNumber, List, Row, Select } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { Option } from "antd/lib/mentions";
import { useState } from "react";
import AdjencyIndentifierItems from "./AdjencyIndentifierItems";

interface Props {
  formListName: NamePath[],
  form: FormInstance,
  field: any,
  remove: () => void
}

interface Option {
  value: string,
  label: string
};

export default function AdjencySettingIdentifier({
  formListName,
  remove,
  form,
  field,
}: Props) {
  const { name, ...restfield } = field;
  const [pageSize, setPageSize] = useState(3)
  const [current, setCurrent] = useState<number>(1);

  const deleteItem = (abc: () => void) => (
    <DeleteOutlined
      style={{ color: "#DC3445", fontSize: 16 }}
      onClick={() => {
        abc()
      }}
    />
  );

  return (
    <>
      <Row>
        <Col span={6}>
          <Form.Item
            {...restfield}
            name={[name, "identifier_type"]}
            label="Identifier Type"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              style={{
                fontWeight: "normal",
              }}
              showArrow={true}
              optionFilterProp="children"
              getPopupContainer={(
                trigger
              ) => trigger.parentNode}
              onChange={() => {
                form.setFieldValue([...formListName, name, "identifier_id"], []);
              }}
            >
              <Option
                value={"product"}
              >
                Product
              </Option>
              <Option
                value={"sub_brand"}
              >
                Sub brand
              </Option>

            </Select>
          </Form.Item>
        </Col>
        <Col span={1}></Col>
        <Col span={15}>
          <Form.Item
            {...restfield}
            name={[name, "identifier_id"]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Form.List name={[name, "identifier_id"]}>
              {(fields, { add, remove }) =>
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', marginBottom: '8px' }}>
                    Identifier ID
                    <PlusCircleOutlined
                      style={{
                        width: "5%",
                        textAlign: "right",
                        color: "#52c41a",
                        fontSize: 16,
                      }}
                      onClick={async () => {
                        add();
                        setCurrent(~~(fields.length/pageSize + 1))
                      }}
                    />

                  </div>

                  <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                      current,
                      pageSize,
                      showQuickJumper: true,
                      onShowSizeChange: (cur, ps) => setPageSize(ps),
                      onChange: (cur, size) => {
                        setCurrent(cur);
                        setPageSize(size)
                      }
                    }}
                    dataSource={fields}
                    renderItem={({ key, name: childName, ...restField }: any) => (
                      <Collapse key={key}>
                        <CollapsePanel
                          header={
                            <span>
                              Item
                              <span
                                style={{
                                  color:
                                    "#1891FF",
                                  fontWeight:
                                    "bold",
                                  marginLeft: 5,
                                }}
                              >
                                OR
                              </span>
                            </span>
                          }
                          key={name + "identifier_id"}
                          extra={deleteItem(() => remove(childName))}
                        >
                          <AdjencyIndentifierItems
                            formListName={[...formListName, name]}
                            form={form}
                            key={`identifier_id-${childName}`}
                            childName={childName}
                            restField={restField}
                          />
                        </CollapsePanel>
                      </Collapse>
                    )} />
                </>

              }
            </Form.List>
          </Form.Item>
        </Col>

        <Col
          span={2}
          style={{
            textAlign: "center",
          }}
        >
          <DeleteOutlined
            style={{
              width: "5%",
              textAlign: "right",
              color: "#DC3445",
              fontSize: 16,
              marginTop: 10,
            }}
            onClick={() => remove()}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={10}>
          <Form.Item
            label="Type"
            {...restfield}
            name={[name, "type"]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              style={{
                fontWeight: "normal",
              }}
              showArrow={true}
              optionFilterProp="children"
              getPopupContainer={(
                trigger
              ) => trigger.parentNode}
            >
              <Option value={"base"}>
                base
              </Option>
              <Option value={"bonus"}>
                bonus
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={1}></Col>
        <Col xs={5}>
          <Form.Item
            {...restfield}
            name={[name, "points"]}
            label="Points"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Divider
        style={{
          backgroundColor: "#1891FF",
        }}
      ></Divider>
    </>
  )
}
import {
  Form,
  Input,
  Select,
  message,
  Spin,
  Drawer,
  Button,
  Modal,
} from "antd/lib/";
import Option from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { UserDetailFormData, updateParams } from "./types";
import { userService } from "../../services/UserService";
import apiClient from "../../services/authorizedApiClient";

interface IStoreProps {
  values: UserDetailFormData;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  onSubmit?: (data: UserDetailFormData) => void;
  stores: any;
  countries: any;
  regions: any;
  onClose: () => void;
  manufacturers: any;
}

const UserForm: React.FC<IStoreProps> = (props) => {
  const [form] = useForm();
  const [accessLevelList, setAccessLevelList] = useState([] as any);
  const [changed, setChanged] = useState(false);
  const [state, setState] = useState({
    loading: false,
    visible: false,
    isCreate: false,
  });
  const [manufactuter, setManufacturer] = useState([] as any);

  const [client, setClient] = useState([] as any);

  const [country, setCountry] = useState([] as any);

  var ObjectID = require("bson-objectid");

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields([
      "_id",
      "name",
      "email",
      "designation",
      "qa_manufacturers",
    ]);
    props.setVisible(false);
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields([
        "_id",
        "name",
        "email",
        "designation",
        "qa_manufacturers",
      ]);
      props.setVisible(false);
    }
  };

  const onSubmit = async () => {
    try {
      const formValues = (await form.getFieldsValue()) as UserDetailFormData;
      let users;
      const stores_accountable = formValues.stores_accountable
        ? formValues.stores_accountable
        : props.values.stores_accountable;
      if (props.isCreate === true) {
        const _id = ObjectID();
        users = updateParams({
          ...props.values,
          ...formValues,
          _id: _id,
          stores_accountable: stores_accountable,
        });
      } else
        users = updateParams({
          ...props.values,
          ...formValues,
          _id: props.values._id,
          stores_accountable: stores_accountable,
        });
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false) res = await userService.updateUser(users);
        else res = await userService.addUser(users);
        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields([
            "_id",
            "name",
            "email",
            "designation",
            "qa_manufacturers",
          ]);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  async function validateProduct() {
    try {
      await form.validateFields();
      return true;
    } catch (err: any) {
      return false;
    }
  }

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  async function fetchClient(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrClients = [];
      const result = await apiClient().get(
        "/manufacturers/search?q=name:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.manufacturers.length; i++) {
          arrClients.push({
            value: result.data.manufacturers[i]._id,
            label: result.data.manufacturers[i].name,
          });
        }
      }
      await setClient(arrClients);
      setState({ ...state, loading: false });
    }
  }

  async function fetchCountry(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrCountries = [];
      const result = await apiClient().get(
        "/countries/search?q=country:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.countries.length; i++) {
          arrCountries.push({
            value: result.data.countries[i]._id,
            label: result.data.countries[i].country,
          });
        }
      }
      await setCountry(arrCountries);
      setState({ ...state, loading: false });
    }
  }

  async function fetchAccessLevel(value: string) {
    const { dashboard_access_level } = await form.getFieldsValue();
    const { type: accessLevelType } = dashboard_access_level;
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrAccessLevel = [];
      if (accessLevelType === "countries") {
        const result = await apiClient().get(
          "/countries/search?q=country:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.countries.length; i++) {
            arrAccessLevel.push({
              value: result.data.countries[i]._id,
              label: result.data.countries[i].country,
            });
          }
        }
      } else if (accessLevelType === "stores") {
        const result = await apiClient().get("/stores/search?q=name:" + value);
        if (result) {
          for (var i = 0; i < result.data.stores.length; i++) {
            arrAccessLevel.push({
              value: result.data.stores[i]._id,
              label: result.data.stores[i].name,
            });
          }
        }
      } else if (accessLevelType === "regions") {
        const result = await apiClient().get(
          "/regions/search?q=region:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.regions.length; i++) {
            arrAccessLevel.push({
              value: result.data.regions[i]._id,
              label: result.data.regions[i].region,
            });
          }
        }
      }
      setState({ ...state, loading: false });
      await setAccessLevelList(arrAccessLevel);
    }
  }

  async function fetchManufacturer(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrManufacturers = [];
      const result = await apiClient().get(
        "/manufacturers/search?q=name:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.manufacturers.length; i++) {
          arrManufacturers.push({
            value: result.data.manufacturers[i]._id,
            label: result.data.manufacturers[i].name,
          });
        }
      }
      await setManufacturer(arrManufacturers);
      setState({ ...state, loading: false });
    }
  }

  useEffect(() => {
    let arrClient = [];
    setClient([]);
    arrClient.push({
      value: props.values.client,
      label: props.values.client_name,
    });
    setClient(arrClient);

    let arrCountry = [];
    setCountry([]);
    arrCountry.push({
      value: props.values.country,
      label: props.values.country_name,
    });
    setCountry(arrCountry);

    async function fetchUserDetail() {
      setState({ ...state, loading: true });
      try {
        let arrAccessLevelList = [];
        setAccessLevelList([]);
        setManufacturer([]);

        if (props.isCreate === true) {
          props.values.qa_manufacturers = [];
        }

        if (!props.values.qa_manufacturers) props.values.qa_manufacturers = [];
        if (!props.values.dashboard_access_level)
          props.values.dashboard_access_level = { type: "", list: [""] };

        if (props.values.qa_manufacturers) {
          try {
            const result = await apiClient().post("manufacturers/getByIds", {
              ids: props.values.qa_manufacturers
            });
            setManufacturer(result.data.map((i: { _id: string, name: string }) =>
              ({ value: i._id, label: i.name })));
          } catch {
            message.error("Something went wrong in fetching the manufacture names from ids")
          }

        }
        if (
          props.values.dashboard_access_level &&
          props.values.dashboard_access_level.type === "stores"
        ) {
          let storesIds = []
          if (props.values.dashboard_access_level.list)
            for (
              let j = 0;
              j < props.values.dashboard_access_level.list.length;
              j++
            ) {
              storesIds.push(props.values.dashboard_access_level.list[j]);
            }
          if (storesIds.length > 0) {
            try {
              const result = await apiClient().post("/stores/getByIds", {
                ids: storesIds
              });
              if (result.data) {
                setAccessLevelList(result.data.map((i: { _id: string, name: string }) =>
                  ({ value: i._id, label: i.name })));
              }
            } catch {
              message.error("Something went wrong in fetching the store names from ids")
            }
          }
        } else if (
          props.values.dashboard_access_level &&
          props.values.dashboard_access_level.type === "regions"
        ) {
          let regionsIds = []
          if (props.values.dashboard_access_level.list)
            for (
              let j = 0;
              j < props.values.dashboard_access_level.list.length;
              j++
            ) {
              regionsIds.push(props.values.dashboard_access_level.list[j]);
            }
          if (regionsIds.length > 0) {
            try {
              const result = await apiClient().post("/regions/getByIds", {
                ids: regionsIds
              });
              if (result.data) {
                setAccessLevelList(result.data.map((i: { _id: string, region: string }) =>
                  ({ value: i._id, label: i.region })));
              }
            } catch {
              message.error("Something went wrong in fetching the region names from ids")
            }
          }

        } else if (
          props.values.dashboard_access_level &&
          props.values.dashboard_access_level.type === "countries" &&
          props.values.dashboard_access_level.list
        ) {
          try {
            const result = await apiClient().post("/countries/getByIds", {
              ids: props.values.dashboard_access_level.list
            });
            if (result.data) {
              setAccessLevelList(result.data.map((i: {
                _id: string,
                country: string
              }) =>
                ({ value: i._id, label: i.country })));
            }
          } catch {
            message.error("Something went wrong in fetching the country names from ids")
          }
        }
      } catch (err: any) {
        message.error("Something went wrong in fetching the details from their ids")
        // do nothing
      }
      setState({ ...state, loading: false });
    }
    fetchUserDetail();
    form.setFieldsValue({
      ...props.values,
    })
  }, [props.values._id]);
  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={onClose}
              style={{
                marginRight: 8,
              }}
            >
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {props.visible && (
          <Spin tip="Loading..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              style={{ fontWeight: "bold" }}
              initialValues={props.values}
              onChange={() => {
                setChanged(true);
              }}
            >

              <Form.Item label="ID: " name="_id" style={{ display: "none" }}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Name"
                rules={[{ required: true, message: "" }]}
                name="name"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                rules={[{ required: true, message: "" }]}
                name="email_id"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Designation"
                rules={[{ required: true, message: "" }]}
                name="designation"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Client"
                rules={[{ required: true, message: "" }]}
                name="client"
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchClient(e)}
                  options={client}
                ></Select>
              </Form.Item>
              <Form.Item
                label="Country"
                rules={[{ required: true, message: "" }]}
                name="country"
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchCountry(e)}
                  options={country}
                ></Select>
              </Form.Item>
              <Form.Item label="QA Manufacturers" name="qa_manufacturers" initialValue={[]}>
                <Select
                  mode="multiple"
                  style={{ fontWeight: "normal" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchManufacturer(e)}
                  showSearch
                  showArrow={true}
                  options={manufactuter}
                ></Select>
              </Form.Item>
              <Form.Item
                label="Access"
                rules={[{ required: true, message: "" }]}
                name={["dashboard_access_level", "type"]}
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  allowClear={false}
                  showArrow={true}
                  defaultValue="countries"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onSelect={(val: any) => {
                    form.setFieldsValue({
                      dashboard_access_level: {
                        type: val,
                        list: []
                      }
                    });
                    setAccessLevelList([]);
                  }}
                >
                  <Option value="countries">Countries</Option>
                  <Option value="stores">Stores</Option>
                  <Option value="regions">Regions</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Access Level List"
                name={["dashboard_access_level", "list"]}
                initialValue={[]}
              >
                <Select
                  onSearch={(e) => fetchAccessLevel(e)}
                  mode="multiple"
                  style={{ width: "100%", fontWeight: "normal" }}
                  placeholder=""
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  options={accessLevelList}
                ></Select>
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Drawer>
    </>
  );
};

export default UserForm;

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import SideNavLayout from "../layouts/SideNavLayout";
import DashboardPage from "../pages/DashboardPage";
import NavBar from "../components/NavBar";
import ProductsPage from "../pages/ProductsPage";
import UsersPage from "../pages/UsersPage";
import ManufacturerPage from "../pages/ManufacturersPage";
import BrandPage from "../pages/BrandsPage";
import SubBrandPage from "../pages/SubBrandsPage";
import RetailersPage from "../pages/RetailersPage";
import StorePage from "../pages/StoresPage";
import OsaPage from "../pages/OsaPage";
import CategoryPage from "../pages/CategoriesPage";
import ChannelsPage from "../pages/ChannelsPage";
import LanguagesPage from "../pages/LanguagesPage";
import CountryPage from "../pages/CountryPage";
import RegionsPage from "../pages/RegionsPage";
import SubCategory1Page from "../pages/SubCategory1Page";
import SubCategory2Page from "../pages/SubCategory2Page";
import SubCategory3Page from "../pages/SubCategory3Page";
import RspSettingsPage from "../pages/RspSettingsPage";
import OutletClassesPage from "../pages/OutletClassesPage";
import AdjacencySettingsPage from "../pages/AdjacencySettingsPage";
import MarketShareSettingsPage from "../pages/MarketShareSettingsPage";
import MinFacingSettingsPage from "../pages/MinFacingSettingsPage";
import NewProductSettingsPage from "../pages/NewProductSettingsPage";
import SequenceSettingsPage from "../pages/SequenceSettingsPage";
import SpaceShareSettingsPage from "../pages/SpaceShareSettingsPage";
import PairingSettingsPage from "../pages/PairingSettingsPage";
import EyeLevelSettingsPage from "../pages/EyeLevelSettingsPage";
import SameShelfSettingsPage from "../pages/SameShelfSettingsPage";
import DeletePage from "../pages/DeletePage";
import LogoutPage from "../pages/LogoutPage";
import ShelfBlockSettingsPage from "../pages/ShelfBlockSettingsPage";
import VerticalBlockSettingsPage from "../pages/VerticalBlockSettingsPage";

const ApplicationRoutes: React.FC = function () {
  return (
    <SideNavLayout
      navigationComponent={<NavBar />}
      navigationDrawer={<NavigationDrawer />}
    >
      <Switch>
        <Route path="/" exact component={DashboardPage} />
        <Route
          path="/adjacency_settings"
          exact
          component={AdjacencySettingsPage}
        />
        <Route path="/brand" exact component={BrandPage} />
        <Route path="/categories" exact component={CategoryPage} />
        <Route path="/channels" exact component={ChannelsPage} />
        <Route path="/country" exact component={CountryPage} />
        <Route
          path="/eye_level_settings"
          exact
          component={EyeLevelSettingsPage}
        />
        <Route path="/languages" exact component={LanguagesPage} />
        <Route path="/manufacturer" exact component={ManufacturerPage} />
        <Route
          path="/market_share_settings"
          exact
          component={MarketShareSettingsPage}
        />
        <Route
          path="/min_facing_settings"
          exact
          component={MinFacingSettingsPage}
        />
        <Route
          path="/new_product_settings"
          exact
          component={NewProductSettingsPage}
        />
        <Route path="/osa" exact component={OsaPage} />
        <Route path="/outlet_classes" exact component={OutletClassesPage} />
        <Route path="/pairing_settings" exact component={PairingSettingsPage} />
        <Route path="/products" exact component={ProductsPage} />
        <Route path="/regions" exact component={RegionsPage} />
        <Route path="/retailers" exact component={RetailersPage} />
        <Route path="/rsp_settings" exact component={RspSettingsPage} />
        <Route
          path="/same_shelf_settings"
          exact
          component={SameShelfSettingsPage}
        />
        <Route
          path="/sequence_settings"
          exact
          component={SequenceSettingsPage}
        />
        <Route
          path="/shelf_block_settings"
          exact
          component={ShelfBlockSettingsPage}
        />
        <Route
          path="/space_share_settings"
          exact
          component={SpaceShareSettingsPage}
        />
        <Route
          path="/vertival_block_settings"
          exact
          component={VerticalBlockSettingsPage}
        />
        <Route path="/stores" exact component={StorePage} />
        <Route path="/subbrand" exact component={SubBrandPage} />
        <Route path="/subcategory1" exact component={SubCategory1Page} />
        <Route path="/subcategory2" exact component={SubCategory2Page} />
        <Route path="/subcategory3" exact component={SubCategory3Page} />
        <Route path="/users" exact component={UsersPage} />
        <Route path="/delete" exact component={DeletePage} />
        <Route path="/logout" exact component={LogoutPage} />
        <Redirect to="/" />
      </Switch>
    </SideNavLayout>
  );
};

export default ApplicationRoutes;

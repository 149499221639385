export interface Retailer {
    _id: string,
    name: string,
}

export const retailer_default = {
    _id: "",
    name: "",
}

export interface RetailerDetailFormData {
    _id: string;
    name: string;
}

export interface RetailerUpdateProps {
    name: string;
    _id: string;
}

export const updateParams = (params: RetailerUpdateProps) => {
    return {
        _id: params._id,
        name: params.name,
    };
}
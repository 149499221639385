
const searchByCountry = async function (data: any, service: any, type: string) {
  const results = {
    data: [] as any,
    hasMore: true,
    cursor: "",
    total: 0,
  };

  try {
    if (results.hasMore) {
      const result = await service.searchByCountry(data, type);
      console.log(result);
      if (result.status !== 200) {
        throw new Error("Failed to fetch!");
      } else {
        results.data = result.data.products;
        results.cursor = result.data.cursor;
        results.total = result.data.total;
      }
    }
  } catch (err: any) {
    const error = err.toString();
    console.log(error)
  }
  return results;
};

export default searchByCountry;

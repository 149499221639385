import { RspSetting } from "./types";

export function structureRsp(data: RspSetting): any {
  const { rsp } = data;
  const rsp_data = rsp.map((i) => {
    const immutdata = JSON.parse(JSON.stringify(i));
    const { products, values } = immutdata
    if (products) {
      const product_map = products.reduce((pmap: { product: string; value: string; }[], p: string, i: string | number) => {
        pmap.push(({ product: p, value: values[i] }));
        return pmap;
      }, []);
      delete immutdata.products;
      delete immutdata.values;
      return Object.assign({}, immutdata, { product_map });
    } else {
      return immutdata
    }
  });
  return Object.assign({}, data, { rsp: rsp_data })
}

export function unStructureRsp(data: any): any {
  const { rsp } = data;
  const rsp_data = rsp.map((i: any) => {
    const immutdata = JSON.parse(JSON.stringify(i));
    const { product_map } = immutdata;
    const { products, values } = product_map.reduce((
      { products, values }: { products: string[]; values: string[]; },
      p: { product: string, value: string }) => {
      if (p) {
        products.push(p.product);
        values.push(p.value);
      }
      return { products, values };
    }, { products: [], values: [] });
    delete immutdata.product_mss;
    return Object.assign({}, immutdata, { products, values });
  });
  return Object.assign({}, data, { rsp: rsp_data })
}

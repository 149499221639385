import { useState, useEffect } from "react";
import { subCategoriesService2 } from "../../services/SubCategoriesService2";

function useSubCategories2(page: number | undefined): [any, boolean] {
  const [result, setResult] = useState({
    categories: [],
    hasMore: true,
    cursor: "",
    total: 0,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        if (result.hasMore) {
          setLoading(true);
          const res = await subCategoriesService2.getAll(page ? page : 1);
          if (res.status !== 200) {
            throw new Error("Failed to fetch!");
          } else {
            setResult(res.data);
          }
        }
      } catch (err: any) {
        const error = err.toString();
        //message.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return [result, loading];
}

export default useSubCategories2;

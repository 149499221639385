
const searchRegex = async function (data: any, service: any, type: string) {
  const results = {
    data: [],
    hasMore: true,
    cursor: "",
    total: 0,
  };

  try {
    if (results.hasMore) {
      const result = await service.search(data, type);
      if (result.status !== 200) {
        throw new Error("Failed to fetch!");
      } else {
        results.data = result.data;
      }
    }
  } catch (err: any) {
    const error = err.toString();
    //message.error(error);
    console.log(error)
  }
  return results.data;
};

export default searchRegex;

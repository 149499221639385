import { DeleteOutlined } from "@ant-design/icons";
import { Col, Form, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import { useState } from "react";
import apiClient from "../../services/authorizedApiClient";

interface Props {
  field: any,
  remove: () => void
}

interface Option {
  value: string,
  label: string
};

export default function OSAProduct({
  field,
  remove,
}: Props) {
  const { name, ...restfield } = field;
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<{
    value: any;
    label: any;
  }[]>([]);

  async function fetchProduct(value: string) {
    if (value !== "") {
      setIsLoading(true)
      const arrData = [];
      const result = await apiClient().get(
        "/products/search/milkywayids?id=" + value
      );
      if (result) {
        for (var i = 0; i < result.data.length; i++) {
          arrData.push({
            value: result.data[i].milkywayID,
            label: result.data[i].milkywayID,
          });
        }
      }
      setProduct(arrData);
      setIsLoading(false)
    }
  }

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Row>
        <Col span={16}>
          <Form.Item
            {...restfield}
            name={[name, "product"]}
            dependencies={[[name, "osa_type"]]}
          >
            <Select
              placeholder="Please select"
              showSearch={true}
              onSearch={(e) =>
                fetchProduct(e)
              }
              options={product}
              getPopupContainer={(
                trigger
              ) => trigger.parentNode}
            />
          </Form.Item>
        </Col>
        <Col span={1}></Col>
        <Col span={5}>
          <Form.Item
            {...restfield}
            name={[name, "osa_type"]}
            dependencies={[[name, "product"]]}
          >
            <Select
              placeholder="Please select"
              showSearch={true}
              getPopupContainer={(
                trigger
              ) => trigger.parentNode}
            >
              <Option value={"C"}>
                C
              </Option>
              <Option value={"NA"}>
                NA
              </Option>
              <Option value={"S"}>
                S
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={2}
          style={{
            textAlign: "center",
          }}
        >
          <DeleteOutlined
            style={{
              width: "5%",
              textAlign: "right",
              color: "#DC3445",
              fontSize: 16,
              marginTop: 7,
              marginLeft: 20,
            }}
            onClick={() => remove()}
          />
        </Col>
      </Row>
    </Spin>
  )
}
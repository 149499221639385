import apiClient from "../authorizedApiClient";
import { UserUpdateProps } from "../../pages/UsersPage/types";
import { Modal } from "antd/lib/";

class UserService {
  async getAll(page: string | number): Promise<any> {
    try {
      const result = await apiClient()
        .get("/users/all?page=" + page)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content: "Preview: " + JSON.stringify(error.response.data),
              });
            //else window.location.href = "/users";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      console.log(err);

      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async searchAll(data: any, type: string): Promise<any> {
    try {
      if (
        data[0].page === null ||
        data[0].page === undefined ||
        data[0].page === ""
      )
        data[0].page = 1;
      const result = await apiClient()
        .get(
          "/users/search?q=" +
            data[0].key +
            ":" +
            data[0].value +
            "&page=" +
            data[0].page
        )
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/users";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async searchAll1(data: any, type: string): Promise<any> {
    try {
      const result = await apiClient()
        .post(`/users/all/search/${type}`, data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/users";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      //message.error(error);
    }
  }

  async updateUser(data: UserUpdateProps): Promise<any> {
    try {
      const result = await apiClient()
        .post("/users/update", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/users";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      //message.error(error);
    }
  }

  async addUser(data: any): Promise<any> {
    try {
      const result = await apiClient()
        .post("/users/add", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/users";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to add!"
        : err.toString();
      //message.error(error);
    }
  }
}

export default UserService;

import {
  Form,
  Input,
  Select,
  Drawer,
  Button,
  Modal,
  message,
  Spin,
} from "antd/lib/";
import React, { useEffect, useState } from "react";
import { Brand, BrandDetailFormData, brand_default } from "./types";
import { useForm } from "antd/lib/form/Form";
import { brandService } from "../../services/BrandService";
import { updateParams } from "./types";
import { TYPE } from "../../components/AutoCompleter/type";
import AutoCompleteInputBrand from "../../components/AutoCompleter/AutoCompleteInputBrand";
import apiClient from "../../services/authorizedApiClient";

interface IBrandProps {
  values: BrandDetailFormData;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  onSubmit?: (data: BrandDetailFormData) => void;
  onClose: () => void;
}

const BrandForm: React.FC<IBrandProps> = (props) => {
  const [state, setState] = useState({
    loading: false,
    visible: false,
  });
  const [changed, setChanged] = useState(false);
  const [values, setValues] = useState(props.values);
  const [brands, setBrands] = useState([] as any);
  const [manufacturers, setManufacturers] = useState([] as any);

  var ObjectID = require("bson-objectid");

  const [form] = useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields([
        "_id",
        "brand",
        "parent_brand",
        "manufacturer",
        "category",
        "categories",
      ]);
      props.setVisible(false);
    }
  };

  async function validateProduct() {
    let validate = true;
    const formValues = (await form.getFieldsValue()) as BrandDetailFormData;

    let brand = formValues.brand;
    if (brand === "" || brand === undefined) validate = false;

    let manufacturer = formValues.manufacturer
      ? formValues.manufacturer
      : values.manufacturer;
    if (manufacturer === "" || manufacturer === undefined) validate = false;

    return validate;
  }

  const onSubmit = async () => {
    try {
      const formValues = (await form.getFieldsValue()) as Brand;

      let brands;

      let parent_brand = values.parent_brand
        ? values.parent_brand
        : props.values.parent_brand;
      if (parent_brand === "") parent_brand = null;

      if (props.isCreate === true) {
        const _id = ObjectID();
        brands = updateParams({
          ...values,
          ...formValues,
          _id: _id,
          parent_brand: parent_brand,
          manufacturer: values.manufacturer
            ? values.manufacturer
            : props.values.manufacturer,
        });
      } else
        brands = updateParams({
          ...values,
          ...formValues,
          _id: props.values._id,
          parent_brand: parent_brand,
          manufacturer: values.manufacturer
            ? values.manufacturer
            : props.values.manufacturer,
        });

      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await brandService.updateBrand(brands);
        else res = await brandService.addBrand(brands);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields([
            "parent_brand",
            "manufacturer",
            "category",
            "categories",
          ]);
          setValues(brand_default);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields([
      "_id",
      "brand",
      "parent_brand",
      "manufacturer",
      "category",
    ]);
    props.setVisible(false);
  };

  useEffect(() => {
    form.resetFields([
      "_id",
      "brand",
      "parent_brand",
      "manufacturer",
      "category",
      "categories",
    ]);
    if (
      props.isCreate === true &&
      props.values.categories &&
      props.values.categories.length > 0
    ) {
      props.values.categories = [{ manufacturer: "", categopry: "" }];
    }

    const arrData = [];
    arrData.push({
      value: props.values.manufacturer,
      label: props.values.manufacturer_name
        ? props.values.manufacturer_name
        : props.values.manufacturer,
    });
    setManufacturers(arrData);
    const arrDataBrand = [];
    arrDataBrand.push({
      value: props.values.parent_brand,
      label: props.values.parent_brand_name
        ? props.values.parent_brand_name
        : props.values.parent_brand,
    });
    setBrands(arrDataBrand);
  }, [props.values]);

  function handleManufacturer(value: any) {
    props.values.manufacturer = value;
  }

  async function fetchManufacturer(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrData = [];
      const result = await apiClient().get(
        "/manufacturers/search?q=name:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.manufacturers.length; i++) {
          arrData.push({
            value: result.data.manufacturers[i]._id,
            label: result.data.manufacturers[i].name,
          });
        }
      }
      await setManufacturers(arrData);
      setState({ ...state, loading: false });
    }
  }
  function handleBrand(value: any) {
    props.values.parent_brand = value;
  }

  async function fetchBrand(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrData = [];
      const result = await apiClient().get("/brands/search?q=brand:" + value);
      if (result) {
        for (var i = 0; i < result.data.brands.length; i++) {
          arrData.push({
            value: result.data.brands[i]._id,
            label: result.data.brands[i].brand,
          });
        }
      }
      await setBrands(arrData);
      setState({ ...state, loading: false });
    }
  }
  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {props.visible && (
          <Spin tip="Loading..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              initialValues={props.values}
              style={{ fontWeight: "bold" }}
              onChange={() => {
                setChanged(true);
              }}
            >
              <Form.Item label="ID" style={{ display: "none" }} name={"_id"}>
                <Input value={props.values._id} disabled />
              </Form.Item>
              <Form.Item
                label="Brand"
                rules={[{ required: true, message: "" }]}
                name={"brand"}
              >
                <Input defaultValue={props.values.brand} />
              </Form.Item>
              <Form.Item
                label={<span>Parent Brand</span>}
                name={"parent_brand"}
              >
                <Select
                  style={{ width: "100%", fontWeight: "normal" }}
                  placeholder="Please select"
                  defaultValue={
                    props.values.parent_brand ? props.values.parent_brand : ""
                  }
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchBrand(e)}
                  onChange={(e) => handleBrand(e)}
                  options={brands}
                ></Select>
              </Form.Item>
              <Form.Item
                label={"Category"}
                name={"category"}
                style={{ display: "none" }}
              >
                <AutoCompleteInputBrand
                  type={TYPE.Category}
                  setValue={setValues}
                  collapsibleBrandProps={{
                    brands: props.values,
                    setBrand: setValues,
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Manufacturer"
                rules={[{ required: true, message: "" }]}
                name={"manufacturer"}
              >
                <Select
                  style={{ width: "100%", fontWeight: "normal" }}
                  placeholder="Please select"
                  defaultValue={props.values.manufacturer}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchManufacturer(e)}
                  onChange={(e) => handleManufacturer(e)}
                  options={manufacturers}
                ></Select>
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Drawer>
    </>
  );
};

export default BrandForm;


import Store from '../Store';
import User from '../../models/User';

class AuthenticationStore extends Store {
  private access_token: string | null;
  private user: User | null;

  private TOKEN_KEY = 'access_token';
  private USER_KEY = 'user';

  constructor() {
    super(localStorage, 'authstore::');

    this.access_token = this.get(this.TOKEN_KEY);
    const userData = this.get(this.USER_KEY);
    this.user = userData ? new User(this.get(this.USER_KEY)) : null;
  }

  get accessToken() {
    return this.access_token = this.get(this.TOKEN_KEY);
  }

  get currentUser() {
    return this.user;
  }

  saveToken(token: string) {
    if(token){
      this.set(this.TOKEN_KEY, token);
      this.access_token = token;

      this.emit('change');
    }
  }

  saveUser(user: User) {
    if(user){
      this.set(this.USER_KEY, user);
      this.user = user;

      this.emit('change');
    }
  }

  clearAll() {
    this.remove(this.TOKEN_KEY);
    this.remove(this.USER_KEY);

    this.access_token = this.user = null;
    this.emit('change');
  }
}

export default AuthenticationStore;

import { message } from "antd";
import apiClient from "../authorizedApiClient";
import { CategoryUpdateProps } from "../../pages/CategoriesPage/types";
import { Modal } from "antd/lib/";

class CategoriesService {
  async getAll(page: string | number): Promise<any> {
    try {
      const result = await apiClient()
        .get("category/all?page=" + page)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content: "Preview: " + JSON.stringify(error.response.data),
              });
            //else window.location.href = "/categories";
          } else if (error.request) {
          } else {
          }
        });
      //if (result.status === 200) {
      return result;
      //}
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }
  async getAllSubCategory1(page: string | number): Promise<any> {
    try {
      const result = await apiClient().get(
        "category/subcategory1/all?page=" + page
      );
      if (result.status === 200) {
        return result;
      }
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }
  async getAllSubCategory2(page: string | number): Promise<any> {
    try {
      const result = await apiClient().get(
        "category/subcategory2/all?page=" + page
      );
      if (result.status === 200) {
        return result;
      }
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }
  async getAllSubCategory3(page: string | number): Promise<any> {
    try {
      const result = await apiClient().get(
        "category/subcategory3/all?page=" + page
      );
      if (result.status === 200) {
        return result;
      }
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }
  async updateCategory(data: CategoryUpdateProps): Promise<any> {
    try {
      const result = await apiClient()
        .post("/category/update", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/categories";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      message.error(error);
    }
  }

  async addCategory(data: CategoryUpdateProps): Promise<any> {
    try {
      const result = await apiClient()
        .post("/category/add", data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/categories";
          } else if (error.request) {
          } else {
          }
        });

      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to update!"
        : err.toString();
      message.error(error);
    }
  }
  async searchAll(data: any, type: string): Promise<any> {
    try {
      if (
        data[0].page === null ||
        data[0].page === undefined ||
        data[0].page === ""
      )
        data[0].page = 1;
      const result = await apiClient()
        .get(
          "/category/search?q=" +
            data[0].key +
            ":" +
            data[0].value +
            "&page=" +
            data[0].page
        )
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/categories";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }
  async searchAll1(data: any, type: string): Promise<any> {
    try {
      console.log(data);
      const result = await apiClient()
        .post(`/category/all/search/${type}`, data)
        .catch(function (error) {
          if (error.response) {
            let result = JSON.stringify(error.response.data).includes("jwt");
            if (result === false)
              Modal.error({
                title: "Error details",
                content:
                  "Payload: " +
                  JSON.stringify(data) +
                  " Preview: " +
                  JSON.stringify(error.response.data),
              });
            //else window.location.href = "/categories";
          } else if (error.request) {
          } else {
          }
        });
      return result;
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Failed to fetch!"
        : err.toString();
      message.error(error);
    }
  }
  async getCategory(id: string): Promise<string> {
    try {
      const result = await apiClient().get(`category/getCategoryName/${id}`);
      if (result.status === 200 && result.data !== "") {
        return result.data;
      }
      return "Can't get name at this time";
    } catch (err: any) {
      return "Can't get name at this time";
    }
  }
  async getSubCategory(id: string, index: number): Promise<string> {
    try {
      if (id) {
        const result = await apiClient().get(
          `category/getSubCategoryName/${index}/${id}`
        );
        if (result.status === 200 && result.data !== "") {
          return result.data;
        }
      }
      return "";
      //return "Can't get name at this time";
    } catch (err: any) {
      return "Can't get name at this time";
    }
  }
  async categorySugg(name: string): Promise<string | undefined> {
    try {
      const result = await apiClient().get(`category/search/${name}`);
      if (result.status === 200 && result.data !== "") {
        return result.data;
      }
      // return "Can't get name at this time";
      message.error("Can't get suggestions now");
    } catch (err: any) {
      // return "Can't get name at this time";
      message.error("Can't get suggestions now");
    }
  }
  async subCategorySugg(
    name: string,
    index: number
  ): Promise<string | undefined> {
    try {
      const result = await apiClient().get(`category/search/${index}/${name}`);
      if (result.status === 200 && result.data !== "") {
        return result.data;
      }
      // return "Can't get name at this time";
      message.error("Can't get suggestions now");
    } catch (err: any) {
      // return "Can't get name at this time";
      message.error("Can't get suggestions now");
    }
  }
}

export default CategoriesService;

import User from "../../models/User";
import { authenticationStore } from "../AuthenticationStore";
import { message } from "antd";
import apiClient from "../apiClient";
class AuthenticationService {
  async login(email: string, password: string) {
    try {
      const res = await apiClient.post("/auth/signin", { email, password });
      authenticationStore.saveToken(res.data.token);
      authenticationStore.saveUser(new User(res.data.user));
      localStorage.setItem("logged_email", email);
    } catch (err: any) {
      const error = err.toString().indexOf("401")
        ? "Invalid email or Password!"
        : err.toString();
      message.error(error);
    }
  }

  logout() {
    authenticationStore.clearAll();
  }
}

export default AuthenticationService;

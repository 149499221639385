import {
  Form,
  Input,
  Select,
  Drawer,
  Button,
  Modal,
  message,
  Spin,
} from "antd/lib/";
import React, { useEffect, useState } from "react";
import { Category, CategoryDetailFormData, category_default } from "./types";
import { useForm } from "antd/lib/form/Form";
import { categoriesService } from "../../services/CategoriesService";
import { updateParams } from "./types";
import apiClient from "../../services/authorizedApiClient";
import { Product } from "../ProductsPage/types";

interface ICategoryProps {
  values: CategoryDetailFormData;
  isChanged: boolean;
  visible: boolean;
  onFormDataChange: (val: CategoryDetailFormData) => void,
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  onSubmit?: (data: CategoryDetailFormData) => void;
  onClose: () => void;
}

const CategoriesForm: React.FC<ICategoryProps> = (props) => {
  const [form] = useForm();
  const [state, setState] = useState({
    loading: false,
    visible: false,
    isCreate: false,
  });
  const [changed, setChanged] = useState(false);
  const [values, setValues] = useState(props.values);

  const defaultUnidentified = values.default_unidentified;
  const intialMid = defaultUnidentified ? [{ value: defaultUnidentified, label: defaultUnidentified }] : [];
  const [mids, setMIds] = useState(intialMid);

  var ObjectID = require("bson-objectid");

  useEffect(() => {
    form.setFieldsValue(
      Object.assign({}, props.values,
        { default_unidentified: props.values.default_unidentified || '' })
    );
  }, [props.values]);

  async function fetchMids(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      try {
        const result = await apiClient().get(
          "/products/search/milkywayids?id=" + value
        );
        const arrMids = result.data
          .reduce((items: Array<{ value: string, label: string }>, i: Product) => {
            return items.concat({
              value: i.milkywayID,
              label: i.milkywayID
            });
          }, []);
        await setMIds(arrMids);
      } catch(err) {
        message.error("Something went wrong while searching");
        setMIds(intialMid);
      }
      setState({ ...state, loading: false });
    }
  }

  const handleMidChange = async (val: string) => {
    const formValues = (await form.getFieldsValue()) as CategoryDetailFormData;
    props.onFormDataChange(
      Object.assign({},
        props.values,
        formValues
      ));
  }
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    if (changed) warning();
    else {
      form.resetFields(["_id", "category", "default_unidentified"]);
      props.setVisible(false);
    }
  };

  async function validateProduct() {
    let validate = true;
    const formValues = (await form.getFieldsValue()) as CategoryDetailFormData;

    const { category, default_unidentified } = formValues;
    if (category === "" || category === undefined || !default_unidentified)
      validate = false;
    return validate;
  }

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  const onSubmit = async () => {
    try {
      const formValues = (await form.getFieldsValue()) as Category;
      let category;

      if (props.isCreate === true) {
        const _id = ObjectID();
        category = updateParams({
          ...values,
          ...formValues,
          _id: _id,
        });
      } else
        category = updateParams({
          ...values,
          ...formValues,
          _id: props.values._id,
        });
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await categoriesService.updateCategory(category);
        else res = await categoriesService.addCategory(category);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          // setResult(res.data);
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields(["category", "default_unidentified"]);
          setValues(category_default);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "category", "default_unidentified"]);
    props.setVisible(false);
  };

  const onSearch = async (searchWord: string) => {
    state.loading = true;
    if (!searchWord) {
      message.error("Please enter a valid search string!");
      return;
    }
  };
  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        
          <Spin tip="Loading..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              initialValues={values}
              style={{ fontWeight: "bold" }}
              onChange={() => {
                setChanged(true);
              }}
            >
              <Form.Item
                label="ID"
                style={{ display: "none" }}
                name={"_id"}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "" }]}
                label="Category"
                name={"category"}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Default Unidentified"
                name={"default_unidentified"}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  style={{ width: "100%", fontWeight: "normal" }}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e: string) => fetchMids(e)}
                  onChange={(e: string) => handleMidChange(e)}
                  options={mids}
                  getPopupContainer={(trigger) => trigger.parentNode}
                ></Select>
              </Form.Item>
            </Form>
          </Spin>
      </Drawer>
    </>
  );
};

export default CategoriesForm;

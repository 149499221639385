import { message } from "antd";
import axios from "axios";
import { API_HOST, getToken } from "../authorizedApiClient";

const resources = new Map<string, []>();

const makeRequestCreator = () => {
  let cancel: any;

  return async (
    query: string,
    url_adds: string,
    url_mid: string,
    field: string
  ) => {
    const token = await getToken();
    if (cancel) {
      // Cancel the previous request before making a new request
      cancel.cancel();
    }
    // Create a new CancelToken
    cancel = axios.CancelToken.source();
    try {
      if (resources.get(query)) {
        // Return result if it exists
        return resources.get(query);
      }
      if (query === "") return;
      //const res = await axios(`${API_HOST}/${url_adds}/${url_mid}/search?fields=${field}&q=${field}:${query}`,
      if (url_mid === "products")
        var res = await axios(
          `${API_HOST}/${url_mid}/search/milkywayids?id=${query}`,
          {
            cancelToken: cancel.token,
            headers: {
              authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
      else
        var res = await axios(
          `${API_HOST}/${url_mid}/search?q=${field}:${query}`,
          {
            cancelToken: cancel.token,
            headers: {
              authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
      var result;
      if (field === "country") result = res.data.countries;
      else if (field === "category") result = res.data.categories;
      else if (field === "brand") result = res.data.brands;
      else if (field === "sub_brand") result = res.data.sub_brands;
      else if (field === "name" && url_mid === "manufacturers")
        result = res.data.manufacturers;
      else if (
        field === "subcategory1" ||
        field === "subcategory2" ||
        field === "subcategory3"
      )
        result = res.data.subcategories;
      else result = res.data;
      // Store response
      //   resources.set(query,result);
      /*if (url_mid === "category/subcategory1")
        return result["subcategory1"];
      else
        if (url_mid === "category/subcategory2")
          return result["subcategory2"];
        else
          if (url_mid === "category/subcategory3")
            return result["subcategory3"];
          else
            return result[url_mid];*/

      return result;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle if request was cancelled
      } else {
        // Handle usual errors
        message.error("Something went wrong");
      }
    }
  };
};
// export default jhs;
export const search = makeRequestCreator();

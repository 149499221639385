export interface MinFacingSetting {
    _id: string,
    date: string,
    manufacturer: string,
    manufacturer_name: string,
    country:string,
    country_name:string,
    category:string,
    category_name:string,
    min_facing:any[]
}

export const min_facing_setting_default = {
    _id: "",
    date: "",
    manufacturer: "",
    manufacturer_name: "",
    country:"",
    country_name: "",
    category:"",
    category_name: "",
    min_facing:Array({regions:[],channels:[],outlet_classes:[],retailers:[],identifier_type:[],identifier_id:[],points:[],type:[],target:[]})
}

export interface MinFacingSettingDetailFormData {
    _id: string;
    date: string;
    manufacturer: string;
    manufacturer_name: string,
    country:string;
    country_name:string;
    category:string;
    category_name:string;
    min_facing:Array<{regions:string[],channels:string[],outlet_classes:string[],retailers:string[],identifier_type:string[],identifier_id:string[],points:number[],type:string[],target:string[]}>
}

export interface MinFacingSettingUpdateProps {
    date: string;
    _id: string;
    manufacturer: string;
    country:string;
    category:string;
    min_facing:any[];
}

export const updateParams = (params: MinFacingSettingUpdateProps) => {
    return {
        _id: params._id,
        date: params.date,
        manufacturer: params.manufacturer,
        country:params.country,
        category:params.category,
        min_facing:params.min_facing
    };
}
export interface Product {
  _id: string;
  name: string;
  milkywayID: string;
  type: string;
  milkywayid_parent: string;
  barcode: any;
  manufacturer_name?: string;
  manufacturer: string;
  brand: string;
  brand_name?: string;
  sub_brand: any;
  sub_brand_name?: string;
  description: any;
  pack_size: any;
  image_urls: string[];
  base_picture_url: string[];
  categories: any[];
  flavour: any;
  created_at: any;
  created_time?: any;
  cv_size_factor?: number;
  tracked: string;
  country_name?: string;
  country: string;
  verification_labels: any[];
  updated_by: any;
}

export const product_default = {
  _id: "",
  name: "",
  milkywayID: "",
  type: "",
  milkywayid_parent: "",
  barcode: "",
  manufacturer_name: "",
  manufacturer: "",
  brand: "",
  brand_name: "",
  sub_brand: "",
  sub_brand_name: "",
  description: "",
  pack_size: "",
  image_urls: [],
  base_picture_url: [],
  categories: [],
  flavour: "",
  created_at: "",
  created_time: "",
  tracked: "",
  country_name: "",
  country: "",
  verification_labels: [],
  updated_by: ""
};

export const product_default_categories = [
  {
    _id: "5f0e5790d6c8cf2b8efc4b9b",
    manufacturer: "",
    category: "",
    subcategory1: null,
    subcategory2: null,
    subcategory3: null,
    isDelete: false,
    addtolabels: false,
  },
]
export interface ProductUpdateProps {
  _id: string;
  name: string;
  milkywayID: string;
  type: string;
  milkywayid_parent: string;
  barcode: string;
  manufacturer: string;
  brand: string;
  sub_brand: string;
  description: string;
  pack_size: string;
  image_urls: string[];
  base_picture_url: string[];
  categories: any[];
  flavour: string;
  created_at: Date;
  cv_size_factor?: number;
  tracked: string;
  country: string;
  updated_by: any;
}

export const updateParams = (params: ProductUpdateProps) => {
  return {
    _id: params._id,
    name: params.name,
    milkywayID: params.milkywayID,
    type: params.type,
    milkywayid_parent: params.milkywayid_parent,
    barcode: params.barcode,
    manufacturer: params.manufacturer,
    brand: params.brand,
    sub_brand: params.sub_brand,
    description: params.description,
    pack_size: params.pack_size,
    image_urls: params.image_urls,
    base_picture_url: params.base_picture_url,
    categories: params.categories,
    flavour: params.flavour,
    created_at: params.created_at,
    ...params.cv_size_factor && { cv_size_factor: params.cv_size_factor },
    tracked: params.tracked,
    country: params.country,
    updated_by: params.updated_by
  };
};

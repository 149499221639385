
export interface User {
  _id: string;
  name: string;
  email_id: string;
  designation: string;
  type: string;
  client_name: string;
  audit_frequency: string;
  country_name: string;
  stores_assigned: string[];
  stores_accountable: string[];
  access_level_id: string;
  access_level_type: string;
  access_level_list: string[];
  qa_manufacturers: string[];
}

export const user_default = {
  _id: "",
  name: "",
  email_id: "",
  designation: "",
  type: "",
  client: "",
  client_name: "",
  audit_frequency: "",
  country: "",
  country_name: "",
  stores_assigned: [],
  stores_accountable: [],
  access_level_id: "",
  access_level_type: "",
  access_level_list: [],

  dashboard_access_level: { type: "", list: [] },

  qa_manufacturers: [],
};

export interface UserDetailFormData {
  _id: string;
  name: string;
  email_id: string;
  designation: string;
  type: string;
  client: string;
  client_name: string;
  audit_frequency: string;
  country: string;
  country_name: string;
  stores_assigned: string[];
  stores_accountable: string[];
  access_level_id: string;
  access_level_type: string;
  access_level_list: string[];

  dashboard_access_level: { type: string; list: string[] };

  qa_manufacturers: string[];
}

export interface UserUpdateProps {
  _id: string;
  stores_accountable: string[];

  dashboard_access_level: { type: string; list: string[] };

  name: string;
  email_id: string;
  designation: string;
  client: string;
  country: string;
  qa_manufacturers: string[];
}

export const updateParams = (params: UserUpdateProps) => {
  return {
    _id: params._id,
    stores_accountable: params.stores_accountable,
    dashboard_access_level: params.dashboard_access_level,
    name: params.name,
    email_id: params.email_id,
    designation: params.designation,
    client: params.client,
    country: params.country,
    qa_manufacturers: params.qa_manufacturers ? params.qa_manufacturers : [],
  };
};

import { message } from "antd";

const search = async function (data: any, service: any, type: string) {
  const results = {
    data: [],
    hasMore: true,
    cursor: "",
    total: 0,
  };

  try {
    console.log(type);
    console.log(data);
    if (results.hasMore) {
      const result = await service.searchAll(data, type);
      if (result.status !== 200) {
        throw new Error("Failed to fetch!");
      } else {
        if (type == "region") results.data = result.data.regions;
        else if (type == "subcategory1")
          results.data = result.data.subcategories;
        else if (type == "subcategory2")
          results.data = result.data.subcategories;
        else if (type == "subcategory3")
          results.data = result.data.subcategories;
        else if (type == "outlet_class")
          results.data = result.data.outlet_classes;
        else if (type == "country") results.data = result.data.countries;
        else if (type == "brand") results.data = result.data.brands;
        else if (type == "category") results.data = result.data.categories;
        else if (type == "channel") results.data = result.data.channels;
        else if (type == "language") results.data = result.data.languages;
        else if (type == "sub_brand") results.data = result.data.sub_brands;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "adjacency_settings"
        )
          results.data = result.data.adjacency_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "eye_level_settings"
        )
          results.data = result.data.eye_level_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "market_share_settings"
        )
          results.data = result.data.market_share_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "min_facing_settings"
        )
          results.data = result.data.min_facing_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "new_product_settings"
        )
          results.data = result.data.new_product_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "osa_settings"
        )
          results.data = result.data.osa_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "pairing_settings"
        )
          results.data = result.data.pairing_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "rsp_settings"
        )
          results.data = result.data.rsp_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "same_shelf_settings"
        )
          results.data = result.data.same_shelf_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "sequence_settings"
        )
          results.data = result.data.sequence_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "space_share_settings"
        )
          results.data = result.data.space_share_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "shelf_block_settings"
        )
          results.data = result.data.shelf_block_settings;
        else if (
          type == "manufacturer_name" &&
          data[0].collection === "vertical_block_settings"
        )
          results.data = result.data.vertical_block_settings;
        else if (type == "name" && data[0].collection === "retailers")
          results.data = result.data.retailers;
        else if (type == "outlet_class")
          results.data = result.data.outlet_classes;
        else if (type == "name" && data[0].collection === "manufacturers")
          results.data = result.data.manufacturers;
        else if (type == "name" && data[0].collection === "stores")
          results.data = result.data.stores;
        else if (type == "name" && data[0].collection === "users")
          results.data = result.data.users;
        else if (type == "name" && data[0].collection === "products")
          results.data = result.data.products;
        else results.data = result.data;
        results.cursor = result.data.cursor;
        results.total = result.data.total;
      }
    }
  } catch (err: any) {
    const error = err.toString();
    message.error(error);
  }
  return results;
};

export default search;

import Router from "./routes/Router";
import AuthenticationProvider from "./providers/AuthenticationProvider";
function App() {
  return (
    <AuthenticationProvider>
      <Router />
    </AuthenticationProvider>
  );
}

export default App;

import { Form, message, Spin, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { categoriesService } from "../../services/CategoriesService";
import { manufacturerService } from "../../services/ManufacturerService";
import { productService } from "../../services/ProductService";
import AutoCompleteInput from "../AutoCompleter/AutoCompleteInput";
import { TYPE } from "../AutoCompleter/type";
import { CollapsibleProps } from "./types";

const header = {
  cursor: "pointer",
  border: "1px solid #d9d9d9",
  padding: "8px",
  height: "40px",
  fontWight: "normal",
  backgroundColor: "#f2f2f2",
  color: "#030500",
  marginTop: "5px",
  marginBottom: "5px",
};

const Collapsabile: React.FC<CollapsibleProps> = (props) => {
  const [state, setState] = useState({
    open: false,
    loading: true,
    items: {
      manufacturer: props.categories.manufacturer,
      manufacturer_name: "",
      category: props.categories.category,
      category_name: "",
      subcategory1: props.categories.subcategory1,
      subcategory1_name: "",
      subcategory2: props.categories.subcategory2,
      subcategory2_name: "",
      subcategory3: props.categories.subcategory3,
      subcategory3_name: "",
      addtolabels: false,
    },
  });
  const [checkLabels, setCheckLables] = useState(props.categories.addtolabels);
  
  function onChange(e: any) {
    props.categories.addtolabels = e.target.checked;
    setCheckLables(e.target.checked);
  }

  useEffect(() => {
    (async function () {
      // try {
      const manufacturer_name =
        state.items.manufacturer === ""
          ? Promise.resolve("")
          : manufacturerService.getManufacturerName(state.items.manufacturer);
      const category_name =
        state.items.category === "" || state.items.category === undefined
          ? Promise.resolve("")
          : categoriesService.getCategory(state.items.category);
      const subcategory1_name =
        state.items.subcategory1 === "" ||
        state.items.subcategory1 === undefined
          ? Promise.resolve("")
          : categoriesService.getSubCategory(state.items.subcategory1, 1);
      const subcategory2_name =
        state.items.subcategory2 === "" ||
        state.items.subcategory2 === undefined
          ? Promise.resolve("")
          : categoriesService.getSubCategory(state.items.subcategory2, 2);
      const subcategory3_name =
        state.items.subcategory3 === "" ||
        state.items.subcategory3 === undefined
          ? Promise.resolve("")
          : categoriesService.getSubCategory(state.items.subcategory3, 3);
      await Promise.all([
        manufacturer_name,
        category_name,
        subcategory1_name,
        subcategory2_name,
        subcategory3_name,
      ])
        .then((responses) => {
          const items = {
            ...state.items,
            manufacturer_name: responses[0],
            category_name: responses[1],
            subcategory1_name: responses[2],
            subcategory2_name: responses[3],
            subcategory3_name: responses[4],
          };
          setState({
            ...state,
            loading: false,
            items,
          });
        })
        .catch((err) => {
          const error = err.toString();
          message.error(error);
          setState({
            ...state,
            loading: false,
          });
        });
      // } catch (err: any) {
      //     const error = err.toString();
      //     message.error(error);
      // }
      if (
        props.categories.manufacturer &&
        props.categories.manufacturer !== "" &&
        props.categories.category &&
        props.categories.category !== ""
      ) {
        const searchData = {
          manufacturer: props.categories.manufacturer,
          category: props.categories.category,
          country: props.products.country,
          milkywayID: props.products.milkywayID,
        };
        const searchedData = await productService.checkLabelExists(searchData);
        if (searchedData && searchedData.data)
          setCheckLables(searchedData.data.exists);
        else setCheckLables(false);
      }
    })();
    // loadData();
  }, []);

  return state.loading ? (
    <Spin />
  ) : (
    !state.loading && (
      <div>
        {/* <CollapsibleItem
                title={"Manufacturer"}
                autoCompleteInput={
                    
                }
            /> */}
        {/* <Form> */}
        <Form.Item
          label={
            <span>
              <span style={{ color: "#ff4d4f" }}>*</span>&nbsp;Client
            </span>
          }
        >
          <AutoCompleteInput
            collapsible={true}
            type={TYPE.Category_Manufacturer}
            setValue={props.setProduct}
            collapsibleProps={{
              categories: { ...state.items },
              products: props.products,
              setProduct: props.setProduct,
              index: props.index,
            }}
          />
        </Form.Item>
        <Form.Item
          label={
            <span>
              <span style={{ color: "#ff4d4f" }}>*</span>&nbsp;Category
            </span>
          }
        >
          <AutoCompleteInput
            type={TYPE.Category}
            setValue={props.setProduct}
            collapsibleProps={{
              categories: { ...state.items },
              products: props.products,
              setProduct: props.setProduct,
              index: props.index,
            }}
          />
        </Form.Item>
        <Form.Item label="SubCategory1">
          <AutoCompleteInput
            type={TYPE.SubCategory1}
            setValue={props.setProduct}
            collapsibleProps={{
              categories: { ...state.items },
              products: props.products,
              setProduct: props.setProduct,
              index: props.index,
            }}
          />
        </Form.Item>
        <Form.Item label="SubCategory2">
          <AutoCompleteInput
            type={TYPE.SubCategory2}
            setValue={props.setProduct}
            collapsibleProps={{
              categories: { ...state.items },
              products: props.products,
              setProduct: props.setProduct,
              index: props.index,
            }}
          />
        </Form.Item>
        <Form.Item label="SubCategory3">
          <AutoCompleteInput
            type={TYPE.SubCategory3}
            setValue={props.setProduct}
            collapsibleProps={{
              categories: { ...state.items },
              products: props.products,
              setProduct: props.setProduct,
              index: props.index,
            }}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={onChange} checked={checkLabels}>
            Add to CV labels
          </Checkbox>
        </Form.Item>
        {/* </Form> */}
      </div>
    )
  );
};

export default Collapsabile;

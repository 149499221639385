import React from "react";
import { Product } from "../../pages/ProductsPage/types";
import { Brand } from "../../pages/BrandsPage/types";
import { SubBrand } from "../../pages/SubBrandsPage/types";
import { Manufacturer } from "../../pages/ManufacturersPage/types";
import { KpiOsa } from "../../pages/OsaPage/types";
import { RspSetting } from "../../pages/RspSettingsPage/types";

export interface CollapsibleProps {
	categories: {
		manufacturer: any,
		manufacturer_name: string,
		category: any,
		category_name: string,
		subcategory1: any,
		subcategory1_name: string,
		subcategory2: any,
		subcategory2_name: string,
		subcategory3: any,
		subcategory3_name: string,
		addtolabels: boolean
	};
	products: Product;
	index?: number;
	setProduct: React.Dispatch<React.SetStateAction<Product>>;
}

export interface CollapsibleBrandProps {
	/*categories: {
		manufacturer: string,
		manufacturer_name: string,
		category: string,
		category_name: string,
	};*/
	brands: Brand;
	index?: number;
	setBrand: React.Dispatch<React.SetStateAction<Brand>>;
}

export interface CollapsibleSubBrandProps {
	subbrands: SubBrand;
	index?: number;
	setSubBrand: React.Dispatch<React.SetStateAction<SubBrand>>;
}

export interface CollapsibleManufacturerProps {
	verification_labels: {
		country: string,
		country_name: string,
		category: string,
		category_name: string,
		labels:[]
	};
	manufacturers: Manufacturer;
	index?: number;
	setManufacturer: React.Dispatch<React.SetStateAction<Manufacturer>>;
	label_list:[];
}

export interface CollapsibleRspProps {
	rsp: {
		products: [],
		values: [],
		regions: [],
		channels: [],
		outlet_classes: [],
		retailers:[]
	};
	rspSettings: RspSetting;
	index?: number;
	setRspSetting: React.Dispatch<React.SetStateAction<RspSetting>>;
}


export interface CollapsibleKpiOsaProps {
	osa: {
		country: string,
		country_name: string,
		region: string,
		region_name: string,
		channel: string,
		channel_name: string,
		outlet_class: string,
		outlet_class_name: string,
		product_list:[]
	};
	kpiosa: KpiOsa;
	index?: number;
	setKpiOsa: React.Dispatch<React.SetStateAction<KpiOsa>>;
}


export const default_categories = {
	category: "",
	category_name: "",
	manufacturer: "",
	manufacturer_name: "",
	subcategory1: "",
	subcategory1_name: "",
	subcategory2: "",
	subcategory2_name: "",
	subcategory3: "",
	subcategory3_name: "",
	addtolabels:false
}

export const default_brands = {
	category: "",
	category_name: "",
	manufacturer: "",
	manufacturer_name: "",
	parent_brand: "",
	parent_brand_name: "",
}

export const default_sub_brands_ = {
	manufacturer: "",
	manufacturer_name: "",
	brand: "",
	brand_name: "",
}

export const default_manufactuter = {

}


import {
  Form,
  Input,
  Drawer,
  Button,
  Modal,
  Collapse,
  message,
  Spin,
} from "antd/lib/";
import React, { useState } from "react";
import { Channel, ChannelDetailFormData, channel_default } from "./types";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { channelService } from "../../services/ChannelService";
import { updateParams } from "./types";
const { Panel } = Collapse;

interface IChannelProps {
  values: ChannelDetailFormData;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  form: FormInstance<any>;
  onSubmit?: (data: ChannelDetailFormData) => void;
  onClose: () => void;
}

const ChannelsForm: React.FC<IChannelProps> = (props) => {
  const [state, setState] = useState({
    loading: false,
    visible: false,
  });
  const [changed, setChanged] = useState(false);
  const [values, setValues] = useState(props.values);

  var ObjectID = require("bson-objectid");

  function makeid(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [form] = useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields(["_id", "channel"]);
      props.setVisible(false);
    }
  };

  async function validateProduct() {
    let validate = true;
    const formValues = (await form.getFieldsValue()) as ChannelDetailFormData;

    let channel = formValues.channel;
    if (channel === "" || channel === undefined) validate = false;

    return validate;
  }

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  const onSubmit = async () => {
    try {
      const formValues = (await form.getFieldsValue()) as Channel;

      let channel;

      if (props.isCreate === true) {
        const _id = ObjectID();
        channel = updateParams({
          ...values,
          ...formValues,
          _id: _id,
        });
      } else
        channel = updateParams({
          ...values,
          ...formValues,
          _id: props.values._id,
        });
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await channelService.updateChannel(channel);
        else res = await channelService.addChannel(channel);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          // setResult(res.data);
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields(["channel"]);
          setValues(channel_default);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
      // props.setVisible(false);
      // setChanged(false);
      // console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "channel"]);
    props.setVisible(false);
  };

  const onSearch = async (searchWord: string) => {
    state.loading = true;
    if (!searchWord) {
      message.error("Please enter a valid search string!");
      return;
    }
  };

  //const loading = loadingProducts;
  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {props.visible && (
          <Spin tip="Loading..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              initialValues={props.values}
              style={{ fontWeight: "bold" }}
              onChange={() => {
                setChanged(true);
              }}
            >
              <Form.Item label="ID" style={{ display: "none" }} name={"_id"}>
                <Input value={props.values._id} disabled />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "#ff4d4f" }}>*</span>&nbsp;Channel
                  </span>
                }
                name={"channel"}
              >
                <Input defaultValue={props.values.channel} />
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Drawer>
    </>
  );
};

export default ChannelsForm;

import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { AuthService } from "../../services/auth.service";
import { getToken } from "../../services/authorizedApiClient";
import parseJwt from "../../helpers/token";

const NavBar: React.FC = function () {
  var authService = new AuthService();
  const [user, setUser ] = useState<null | any>(null);
  useEffect(() => {
    (async function() {
      const token = await getToken();
      const user = parseJwt(token);
      setUser(user)
    })();
    
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <a
          onClick={() => {
            authService.logout();
          }}
        >
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Row>
        <Col span={1} offset={0}>
          <img
            height={30}
            src="https://instashelfcommonstorage.blob.core.windows.net/instashelf-app-userflow-media/images/Group-4d1.png"
            alt={"broken"}
          />
        </Col>
        <Col span={5} offset={18} style={{ textAlign: "right" }}>
          <Dropdown overlay={menu}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {user?.name || ""}{" "}
              <DownOutlined />
            </a>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(NavBar);

import {
  Form,
  Select,
  Drawer,
  Button,
  Modal,
  Collapse,
  message,
  Spin,
  AutoComplete,
  List,
  Input,
} from "antd/lib/";
import React, { useEffect, useState } from "react";
import Option from "antd/lib/select";
import moment from "moment";
import { Manufacturer } from "./types";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { manufacturerService } from "../../services/ManufacturerService";
import { productService } from "../../services/ProductService";
import searchRegex from "../../helpers/searchRegex";
import apiClient from "../../services/authorizedApiClient";
import ManufactureLabel from "./ManufactureLabel";
import { DeleteOutlined } from "@ant-design/icons";
const { Panel } = Collapse;

interface IManufacturerProps {
  values: Manufacturer;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  setIsCreate: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  onSubmit?: (data: Manufacturer) => void;
  manufacturers: any;
  onClose: () => void;
  optionManufacturers: [];
}

const ManufacturerForm: React.FC<IManufacturerProps> = (props) => {
  const [changed, setChanged] = useState(false);
  const [man, setMan] = useState([] as any);
  const [mans, setManS] = useState([] as any);
  const [category, setCategory] = useState([] as any);
  const [country, setCountry] = useState([] as any);
  const [pageSize, setPageSize] = useState(5);
  const [current, setCurrent] = useState(1);
  const [state, setState] = useState({
    loading: false,
    visible: false,
    isCreate: false,
    data: new Map<string, any>(),
  });

  function makeid(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    let arrMan: never[] = [];
    setMan([]);
    setManS([]);
    setMan(arrMan);

    let arrMans = [];
    for (let i = 0; i < props.manufacturers.length; i++) {
      arrMans.push(
        <Option key={makeid(10)} value={props.manufacturers[i].name}>
          {props.manufacturers[i].name}
        </Option>
      );
    }
    setManS(arrMans);

    if (props.isCreate === false) {
      props.values.createdAt =
        props.values.createdAt === ""
          ? undefined
          : moment(Date.parse(props.values.createdAt));
      props.values.updatedAt =
        props.values.updatedAt === ""
          ? undefined
          : moment(Date.parse(props.values.updatedAt));
      let man = new Map();
      man.set("_id", props.values._id);
      man.set("name", props.values.name);
      man.set("cv_labels", props.values.cv_labels);
      state.data = man;

    } else {
      let man = new Map();
      man.set("name", "");
      man.set("cv_labels", []);
      state.data = man;
      props.values.name = "";
      props.values.cv_labels = [];
      props.values.verification_labels = []
    }

    async function fetchDetail() {
      setState({ ...state, loading: true });
      setCountry([]);
      if (props.values.countries) {
        try {
          const result = await apiClient().post("/countries/getByIds", {
            ids: props.values.countries
          });
          if (result.data) {
            setCountry(result.data.map((i: {
              _id: string,
              country: string
            }) =>
              ({ value: i._id, label: i.country })));
          }
        } catch {
          message.error("Something went wrong in fetching the country names from ids")
        }
      }

      setCategory([]);
      if (props.values.categories) {
        try {
          const result = await apiClient().post("category/getByIds", {
            ids: props.values.categories
          });
          setCategory(result.data.map((i: { _id: string, category: string }) =>
            ({ value: i._id, label: i.category })));
        } catch {
          message.error("Something went wrong in fetching the category names from ids")
        }
      }
      setState({ ...state, loading: false });
    }

    fetchDetail();
    form.setFieldsValue(props.values);
    console.log(props.values)
  }, [props.values]);

  const [form] = useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields(["_id", "name", "cv_labels"]);
      props.setVisible(false);
    }
  };

  async function validateProduct() {
    try {
      await form.validateFields();
      return true;
    } catch (err) {
      return false;
    }
  }

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  const onSubmit = async () => {
    try {
      const formValues = await form.validateFields();
      let data = {
        ...formValues,
        is_client: false,
      }
      if (props.isCreate) {
        delete data._id;
      }
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await manufacturerService.updateManufacturer(data);
        else res = await manufacturerService.addManufacturer(data);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          setChanged(false);
        }
        if (changed === true) {
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "name", "cv_labels"]);
    props.setVisible(false);
  };

  const onSearch = async (searchWord: string) => {
    state.loading = true;
    if (!searchWord) {
      message.error("Please enter a valid search string!");
      return;
    }
    setState({ ...state, loading: true });
    const searchData = [
      {
        fields: "milkywayID",
        key: "milkywayID",
        value: encodeURIComponent(searchWord),
      },
    ];
    let searchedData = [{ milkywayID: "" }];
    searchedData = await searchRegex(searchData, productService, "milkywayID");
    state.loading = false;
    let arrMan: JSX.Element[] = [];
    if (searchedData.length > 0)
      searchedData.forEach((item) =>
        arrMan.push(<Option value={item.milkywayID}>{item.milkywayID}</Option>)
      );
    setMan(arrMan);
    setState({ ...state, loading: false });
  };

  async function fetchCountry(value: string) {
    const arrCountries = [];
    if (value !== "") {
      setState({ ...state, loading: true });
      try {
        const result = await apiClient().get(
          "/countries/search?q=country:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.countries.length; i++) {
            arrCountries.push({
              value: result.data.countries[i]._id,
              label: result.data.countries[i].country,
            });
          }
        }
      } catch (err: any) {
        message.error("Something went wrong in searching the country");
      }
      setState({ ...state, loading: false });
    }
    return arrCountries

  }

  const deleteItem = (abc: () => void) => (
    <DeleteOutlined
      style={{ color: "#DC3445", fontSize: 16 }}
      onClick={() => {
        abc()
      }}
    />
  );


  async function fetchCategory(value: string) {
    const arrCatagories = [];
    if (value !== "") {
      setState({ ...state, loading: true });
      try {
        const result = await apiClient().get(
          "/category/search?q=category:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.categories.length; i++) {
            arrCatagories.push({
              value: result.data.categories[i]._id,
              label: result.data.categories[i].category,
            });
          }
        }
      } catch {
        message.error("Something went wrong in searching the category")
      }
      setState({ ...state, loading: false });
    }
    return arrCatagories;
  }

  return (
    <Drawer
      title="Details"
      placement="right"
      width={1050}
      closable={false}
      onClose={onClose}
      visible={props.visible}
      getContainer={false}
      style={{
        display: props.visible ? "block" : "none",
        position: "fixed",
      }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={submitWarning} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      {props.visible && (
        <Spin tip="Loading..." spinning={state.loading}>
          <Form
            {...formItemLayout}
            form={form}
            initialValues={props.values}
            style={{ fontWeight: "bold" }}
            onChange={() => {
              setChanged(true);
            }}
          >
            <Form.Item
              name={"_id"}
              style={{ display: "none "}}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Name"
              rules={[{ required: true, message: "" }]}
              name={"name"}
            >
              <AutoComplete
                style={{ width: "100%" }}
                options={props.optionManufacturers}
                placeholder=""
                filterOption={(inputValue, option: any) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "" }]}
              label="Countries"
              name="countries"
            >
              <Select
                style={{ width: "100%", fontWeight: "normal" }}
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.props.label
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option?.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onSearch={async (e) => {
                  setState({ ...state, loading: true });
                  const data = await fetchCountry(e);
                  setState({ ...state, loading: false });
                  setCountry(data);
                }}
                options={country}
                getPopupContainer={(trigger) => trigger.parentNode}
                mode={"multiple"}
              ></Select>
            </Form.Item>
            <Form.Item
              label="Categories"
              rules={[{ required: true, message: "" }]}
              name="categories"
            >
              <Select
                style={{ width: "100%", fontWeight: "normal" }}
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.props.label
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option?.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onSearch={async (e) => {
                  setState({ ...state, loading: true });
                  const data = await fetchCategory(e);
                  setState({ ...state, loading: false });
                  setCategory(data);
                }}
                options={category}
                getPopupContainer={(trigger) => trigger.parentNode}
                mode={"multiple"}
              ></Select>
            </Form.Item>
            <Form.Item
              label={"CV LABELS"}
              name={"cv_labels"}
              style={{ display: "none" }}
              initialValue={[]}
            >
              <Select
                onChange={() => {
                  setChanged(true);
                }}
                onSearch={(value) => {
                  onSearch(value);
                }}
                mode="multiple"
                style={{ width: "100%" }}
                placeholder=""
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option !== undefined &&
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {man}
              </Select>
            </Form.Item>
            <Form.Item name="verification_labels" label="Verification Labels">
              <Form.List name="verification_labels">
                {(fields, { add, remove }) =>
                  <Collapse style={{ fontWeight: "normal" }}>
                    <Panel header="Items" key="verification-labels-1">

                      <Button
                        type="primary"
                        onClick={() => {
                          add();
                          setState({ ...state, loading: false });
                          setCurrent(~~(fields.length/pageSize + 1))
                        }}
                        style={{ marginBottom: 10 }}
                      >
                        Add New
                      </Button>
                      <List
                        itemLayout="vertical"
                        size="large"
                        pagination={{
                          current,
                          pageSize,
                          showQuickJumper: true,
                          onShowSizeChange: (cur, ps) => setPageSize(ps),
                          onChange: (cur, size) => {
                            setCurrent(cur);
                            setPageSize(size)
                          }
                        }}
                        dataSource={fields}
                        renderItem={(minfacingIdentifierField: any) => (
                          <Collapse key={minfacingIdentifierField.key}>
                            <Panel
                              header={`Item`}
                              key={minfacingIdentifierField.name + "verification_labels"}
                              extra={deleteItem(() => remove(minfacingIdentifierField.name))}
                            >
                              <ManufactureLabel
                                key={`verification-labels-1-${minfacingIdentifierField.key}`}
                                field={minfacingIdentifierField}
                                fetchCategory={fetchCategory}
                                fetchCountry={fetchCountry}
                                form={form}
                                data={form.getFieldValue("verification_labels")[minfacingIdentifierField.name]}
                              />
                            </Panel>
                          </Collapse>
                        )}
                      />
                    </Panel>
                  </Collapse>
                }
              </Form.List>
            </Form.Item>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

export default ManufacturerForm;

import {
  CloseCircleOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Table,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import search from "../../helpers/search";
import useManufacturer from "../../hooks/useManufacturers";
import { manufacturerService } from "../../services/ManufacturerService";
import ManufacturersForm from "../ManufacturersPage/ManufacturersForm";
import { manufacturer_default } from "./types";

const { Header, Content } = Layout;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
];
const ManufacturerPage: React.FC = function () {
  const [optionManufacturers, setOptionManufacturers] = useState([] as any);
  const [form] = useForm();
  const [searching, setSearching] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [manufacturersToDisplay, setManufacturersToDisplay] = useState([]);
  const [state, setState] = useState({
    selected: Object.assign({}, manufacturer_default),
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    pagination_search: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [manufacturers, loadingManufacturers] = useManufacturer(
    state.pagination.current
  );

  const onSearch = async (key: string) => {
    const { searchWord } = await form.getFieldsValue();
    if (!searchWord) {
      message.error("Please enter a valid search string!");
      return;
    }

    setSearching(true);
    const searchData = [
      {
        key,
        value: encodeURIComponent(searchWord),
        collection: "manufacturers",
      },
    ];

    const searchedData = await search(searchData, manufacturerService, key);

    setManufacturersToDisplay(searchedData.data);
    setState({
      ...state,
      pagination_search: {
        ...state.pagination_search,
        total: searchedData.total,
        current: 1,
      },
    });
    setSearching(false);
  };

  const onClose = async () => {
    const { searchWord } = await form.getFieldsValue();
    setState({
      ...state,
    });
    setSearching(true);
    if (searchWord) {
      const searchData = [
        {
          key: "name",
          value: encodeURIComponent(searchWord),
          collection: "manufacturers",
        },
      ];

      const searchedData = await search(
        searchData,
        manufacturerService,
        "name"
      );
      setManufacturersToDisplay(searchedData.data);
    } else {
      const searchedData = await manufacturerService.getAll(
        state.pagination.current
      );
      const data_manufacturersToDisplay = searchedData.data.manufacturers.length
        ? searchedData.data.manufacturers
        : manufacturers;
      setManufacturersToDisplay(data_manufacturersToDisplay);
    }

    setSearching(false);
  };


  const handleTableChange = async (pagination: any) => {
    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination: pagination,
    });
    const data = await manufacturerService.getAll(pagination.current);
    setManufacturersToDisplay(data.data.manufacturers);
    setSearching(false);
  };

  const handleTableSearchChange = async (pagination: any) => {
    const { searchWord } = await form.getFieldsValue();

    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination_search: pagination,
    });
    const searchData = [
      {
        key: "name",
        value: encodeURIComponent(searchWord),
        page: pagination.current,
        collection: "manufacturers",
      },
    ];

    const data = await search(searchData, manufacturerService, "name");
    setManufacturersToDisplay(data.data);
    setSearching(false);
  };

  useEffect(() => {
    setManufacturersToDisplay(manufacturers.manufacturers);

    var arrManufacturers = [];
    for (var i = 0; i < manufacturers.length; i++) {
      arrManufacturers.push({ value: manufacturers[i].name });
    }
    setOptionManufacturers(arrManufacturers);

    setState({
      ...state,
      pagination: {
        ...state.pagination,
        total: manufacturers.total,
      },
    });
  }, [manufacturers]);

  const loading = searching || loadingManufacturers;

  return (
    <Layout style={{ padding: 20 }}>
      <Header
        style={{
          textAlign: "left",
          backgroundColor: "#F0F2F5",
          padding: 0,
          borderRadius: 15,
        }}
      >
        <Row>
          <Col span={12}>
            <h3 style={{ fontWeight: "bold", paddingLeft: 0 }}>
              Manufacturers (
              <span style={{ color: "#0a1e96" }}>
                {manufacturers && manufacturers.total ? manufacturers.total : 0}
              </span>
              )
            </h3>
          </Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: 0 }}>
            <Button
              onClick={() => {
                setVisible(true);
                setIsCreate(true);
                setState({ ...state, selected: manufacturer_default });
              }}
              type="primary"
              shape="round"
              icon={<PlusCircleFilled />}
              style={{ width: 150 }}
            >
              Add New
            </Button>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row
          style={{
            textAlign: "left",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
            marginTop: 0,
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                What are you looking for?
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Form
                form={form}
                initialValues={{ searchWord: "" }}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                }}
                onValuesChange={(changedVals) => {
                  if (changedVals.searchWord === "") {
                    onClose();
                  }
                }}
              >
                <Col span={20}>
                  <Form.Item name="searchWord">
                    <Input
                      style={{ width: 300, borderRadius: 10 }}
                      prefix={<SearchOutlined style={{ color: "#ccc" }} />}
                      placeholder={"Search"}
                      suffix={
                        <Tooltip title="Clear Search">
                          <CloseCircleOutlined
                            onClick={() => {
                              setManufacturersToDisplay(
                                manufacturers.manufacturers
                              );
                              setState({
                                ...state,
                                pagination: {
                                  current: 1,
                                  pageSize: 10,
                                  total: manufacturers.total,
                                },
                              });
                              setIsCreate(false);
                              form.setFieldsValue({
                                searchWord: ""
                              });
                            }}
                            style={{
                              color: "rgba(0,0,0,.45)",
                            }}
                          />
                        </Tooltip>
                      }
                    ></Input>
                  </Form.Item>

                </Col>
                <Col span={4} style={{ textAlign: "right", paddingRight: 0 }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      shape="round"
                      style={{ width: 150 }}
                      htmlType="submit"
                      onClick={() => onSearch("name")}
                    >
                      Search By Name
                    </Button>
                  </Form.Item>

                </Col>
              </Form>

            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: 20,
            backgroundColor: "white",
            marginTop: 20,
            borderRadius: 15,
          }}
        >
          <Col span={24}>
            {!form.getFieldValue("searchWord") ? (
              <Table
                pagination={state.pagination}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: () => {
                      setState({
                        ...state,
                        selected: record,
                      });
                      setVisible(true);
                      setIsCreate(false);
                    },
                  };
                }}
                dataSource={manufacturersToDisplay}
                columns={columns}
                onChange={handleTableChange}
              />
            ) : (
              <Table
                pagination={state.pagination_search}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: () => {
                      setState({
                        ...state,
                        selected: record,
                      });
                      setVisible(true);
                      setIsCreate(false);
                    },
                  };
                }}
                dataSource={manufacturersToDisplay}
                columns={columns}
                onChange={handleTableSearchChange}
              />
            )}
          </Col>
        </Row>
      </Content>
      <ManufacturersForm
        visible={visible}
        manufacturers={manufacturers}
        optionManufacturers={optionManufacturers}
        form={form}
        values={{
          ...state.selected,
        }}
        isChanged={false}
        setVisible={setVisible}
        isCreate={isCreate}
        setIsCreate={setIsCreate}
        onClose={onClose}
      />
    </Layout>
  );
};

export default ManufacturerPage;

import React from "react";
import { ISideNavLayoutProps } from "./types";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";

const { Header, Sider } = Layout;

const SideNavLayout: React.FC<ISideNavLayoutProps> = function (props) {
  return (
    <div>
      <Layout style={{  maxHeight: '100vh', overflow: 'hidden'}}>
        <Header
          style={{
            backgroundColor: "white",
            borderBottom: "1px solid #fff",
          }}
        >
          {props.navigationComponent}
        </Header>
        <Layout style={{ overflow: 'hidden' }}>
          <Sider
            style={{
              overflow: 'auto',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            {props.navigationDrawer}
          </Sider>
          <Content style={{ overflowY: 'auto' }}>
            <div>{props.children}</div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default SideNavLayout;

export interface SequenceSetting {
    _id: string,
    date: string,
    manufacturer: string,
    manufacturer_name: string,
    country:string,
    country_name:string,
    category:string,
    category_name:string,
    sequence:any[]
}

export const sequence_setting_default = {
    _id: "",
    date: "",
    manufacturer: "",
    manufacturer_name: "",
    country:"",
    country_name: "",
    category:"",
    category_name: "",
    sequence:Array({regions:[],channels:[],outlet_classes:[],retailers:[],identifier_type:[],identifier_id:[],points:[0],type:[]})
}

export interface SequenceSettingDetailFormData {
    _id: string;
    date: string;
    manufacturer: string;
    manufacturer_name: string,
    country:string;
    country_name:string;
    category:string;
    category_name:string;
    sequence:Array<{regions:string[],channels:string[],outlet_classes:string[],retailers:string[],identifier_type:string[],identifier_id:any[][],points:number[],type:string[]}>
}

export interface SequenceSettingUpdateProps {
    date: string;
    _id: string;
    manufacturer: string;
    country:string;
    category:string;
    sequence:any[];
}

export const updateParams = (params: SequenceSettingUpdateProps) => {
    return {
        _id: params._id,
        date: params.date,
        manufacturer: params.manufacturer,
        country:params.country,
        category:params.category,
        sequence:params.sequence
    };
}
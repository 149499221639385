import {
  Form,
  Input,
  DatePicker,
  Select,
  Drawer,
  Button,
  Modal,
  Collapse,
  message,
  Spin,
  Row,
  Col,
  Divider,
} from "antd/lib/";
import React, { useEffect, useState } from "react";
import Option from "antd/lib/select";
import moment from "moment";
import {
  SpaceShareSetting,
  SpaceShareSettingDetailFormData,
  space_share_setting_default,
} from "./types";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { spaceShareSettingsService } from "../../services/SpaceShareSettingsService";
import {
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { updateParams } from "./types";
import apiClient from "../../services/authorizedApiClient";
import NumberFormat from "react-number-format";
const { Panel } = Collapse;

interface IChannelProps {
  values: SpaceShareSettingDetailFormData;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  form: FormInstance<any>;
  onSubmit?: (data: SpaceShareSettingDetailFormData) => void;
  onClose: () => void;
}

const SpaceShareSettingsForm: React.FC<IChannelProps> = (props) => {
  const [state, setState] = useState({
    loading: false,
    visible: false,
  });
  const [changed, setChanged] = useState(false);
  const [values, setValues] = useState(props.values);
  const [manufacturer, setManufacturer] = useState([] as any);
  const [country, setCountry] = useState([] as any);
  const [region, setRegion] = useState([] as any);
  const [channel, setChannel] = useState([] as any);
  const [outletclass, setOutletclass] = useState([] as any);
  const [retailer, setRetailer] = useState([] as any);
  const [identifierID, setIdentifierID] = useState([] as any);
  const [category, setCategory] = useState([] as any);
  const [universeID, setUniverseID] = useState([] as any);
  const [competitor, setCompetitor] = useState([] as any);

  var ObjectID = require("bson-objectid");

  function makeid(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [form] = useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };

  const deleteItem = (ind: number) => (
    <DeleteOutlined
      style={{
        width: "5%",
        textAlign: "right",
        color: "#DC3445",
        fontSize: 16,
      }}
      onClick={(event) => {
        delete values.space_share[ind];
        delete props.values.space_share[ind];
        setState({ ...state, loading: false });
      }}
    />
  );

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields(["_id", "channel"]);
      props.setVisible(false);
    }
  };

  async function validateProduct() {
    let validate = true;
    const formValues =
      (await form.getFieldsValue()) as SpaceShareSettingDetailFormData;
    console.log(formValues);
    let date = formValues.date ? formValues.date : values.date;
    if (date === "" || date === undefined) validate = false;

    let manufacturer = formValues.manufacturer
      ? formValues.manufacturer
      : values.manufacturer;
    if (manufacturer === "" || manufacturer === undefined) validate = false;

    let country = formValues.country ? formValues.country : values.country;
    if (country === "" || country === undefined) validate = false;

    let category = formValues.category ? formValues.category : values.category;
    if (category === "" || category === undefined) validate = false;

    return validate;
  }

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  const onSubmit = async () => {
    try {
      const formValues = (await form.getFieldsValue()) as SpaceShareSetting;

      let channel;

      if (props.values.space_share) {
        props.values.space_share = props.values.space_share.filter(function (
          el
        ) {
          return el != null;
        });
      }

      for (var i = 0; i < props.values.space_share.length; i++) {
        if (
          props.values.space_share[i] &&
          props.values.space_share[i].identifier_type.length
        )
          props.values.space_share[i].identifier_type =
            props.values.space_share[i].identifier_type.filter(function (el) {
              return el != null;
            });
        if (
          props.values.space_share[i] &&
          props.values.space_share[i].identifier_id.length
        ) {
          props.values.space_share[i].identifier_id = props.values.space_share[
            i
          ].identifier_id.filter(function (el) {
            return el != null;
          });
          for (
            var comp = 0;
            comp < props.values.space_share[i].identifier_id.length;
            comp++
          ) {
            if (!props.values.space_share[i].competitors[comp])
              props.values.space_share[i].competitors[comp] = [];
          }
        }
        if (
          props.values.space_share[i] &&
          props.values.space_share[i].universe_type.length
        )
          props.values.space_share[i].universe_type = props.values.space_share[
            i
          ].universe_type.filter(function (el) {
            return el != null;
          });
        if (
          props.values.space_share[i] &&
          props.values.space_share[i].universe_id.length
        )
          props.values.space_share[i].universe_id = props.values.space_share[
            i
          ].universe_id.filter(function (el) {
            return el != null;
          });
        if (
          props.values.space_share[i] &&
          props.values.space_share[i].type.length
        )
          props.values.space_share[i].type = props.values.space_share[
            i
          ].type.filter(function (el) {
            return el != null;
          });
        if (
          props.values.space_share[i] &&
          props.values.space_share[i].req_display_type.length
        )
          props.values.space_share[i].req_display_type =
            props.values.space_share[i].req_display_type.filter(function (el) {
              return el != null;
            });
        if (
          props.values.space_share[i] &&
          props.values.space_share[i].target.length
        )
          props.values.space_share[i].target = props.values.space_share[
            i
          ].target.filter(function (el) {
            return el != null;
          });
        if (
          props.values.space_share[i] &&
          props.values.space_share[i].points.length
        ) {
          props.values.space_share[i].points = props.values.space_share[
            i
          ].points.filter(Number.isFinite);
          for (var j = 0; j < props.values.space_share[i].points.length; j++) {
            if (props.values.space_share[i].points[j] >= 0) {
              props.values.space_share[i].points[j] = Number(
                props.values.space_share[i].points[j]
              );
            }
          }
        }
      }

      if (props.isCreate === true) {
        //const _id = ObjectID();
        channel = updateParams({
          ...values,
          ...formValues,
          //_id: _id,
          space_share: props.values.space_share,
          manufacturer: values.manufacturer
            ? values.manufacturer
            : props.values.manufacturer,
          country: values.country ? values.country : props.values.country,
          category: values.category ? values.category : props.values.category,
          date: values.country ? values.date : props.values.date,
        });
      } else
        channel = updateParams({
          ...values,
          ...formValues,
          //_id: props.values._id,
          space_share: props.values.space_share,
          manufacturer: values.manufacturer
            ? values.manufacturer
            : props.values.manufacturer,
          country: values.country ? values.country : props.values.country,
          category: values.category ? values.category : props.values.category,
          date: values.country ? values.date : props.values.date,
        });
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await spaceShareSettingsService.update(channel);
        else res = await spaceShareSettingsService.add(channel);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          // setResult(res.data);
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields(["channel"]);
          setValues(space_share_setting_default);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
      // props.setVisible(false);
      // setChanged(false);
      // console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "channel"]);
    props.setVisible(false);
  };

  const onSearch = async (searchWord: string) => {
    state.loading = true;
    if (!searchWord) {
      message.error("Please enter a valid search string!");
      return;
    }
  };

  function handleDate(date: any, dateString: any) {
    props.values.date = date.format();
  }

  function handleManufacturer(value: any) {
    props.values.manufacturer = value;
  }

  async function fetchManufacturer(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrManufacturers = [];
      const result = await apiClient().get(
        "/manufacturers/search?q=name:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.manufacturers.length; i++) {
          arrManufacturers.push({
            value: result.data.manufacturers[i]._id,
            label: result.data.manufacturers[i].name,
          });
        }
      }
      await setManufacturer(arrManufacturers);
      setState({ ...state, loading: false });
    }
  }

  function handleCountry(value: any) {
    props.values.country = value;
  }

  async function fetchCountry(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrCountries = [];
      const result = await apiClient().get(
        "/countries/search?q=country:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.countries.length; i++) {
          arrCountries.push({
            value: result.data.countries[i]._id,
            label: result.data.countries[i].country,
          });
        }
      }
      await setCountry(arrCountries);
      setState({ ...state, loading: false });
    }
  }

  function handleRegion(value: any, index: any) {
    props.values.space_share[index].regions = value;
  }

  async function fetchRegion(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrRegions = [];
      const result = await apiClient().get("/regions/search?q=region:" + value);
      if (result) {
        console.log(result);
        for (var i = 0; i < result.data.regions.length; i++) {
          arrRegions.push({
            value: result.data.regions[i]._id,
            label: result.data.regions[i].region,
          });
        }
      }
      await setRegion(arrRegions);
      setState({ ...state, loading: false });
    }
  }

  function handleCatagory(value: any) {
    props.values.category = value;
  }

  async function fetchCategory(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrCatagories = [];
      const result = await apiClient().get(
        "/category/search?q=category:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.categories.length; i++) {
          arrCatagories.push({
            value: result.data.categories[i]._id,
            label: result.data.categories[i].category,
          });
        }
      }
      await setCategory(arrCatagories);
      setState({ ...state, loading: false });
    }
  }

  function handleChannel(value: any, index: any) {
    props.values.space_share[index].channels = value;
    console.log(props.values);
  }

  async function fetchChannel(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrChannels = [];
      const result = await apiClient().get(
        "/channels/search?q=channel:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.channels.length; i++) {
          arrChannels.push({
            value: result.data.channels[i]._id,
            label: result.data.channels[i].channel,
          });
        }
      }
      await setChannel(arrChannels);
      setState({ ...state, loading: false });
    }
  }

  function handleOutletclass(value: any, index: any) {
    props.values.space_share[index].outlet_classes = value;
    console.log(props.values);
  }

  async function fetchOutletclass(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrOutletclasses = [];
      const result = await apiClient().get(
        "/outlet_classes/search?q=outlet_class:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.outlet_classes.length; i++) {
          arrOutletclasses.push({
            value: result.data.outlet_classes[i]._id,
            label: result.data.outlet_classes[i].outlet_class,
          });
        }
      }
      await setOutletclass(arrOutletclasses);
      setState({ ...state, loading: false });
    }
  }

  function handleRetailer(value: any, index: any) {
    props.values.space_share[index].retailers = value;
    console.log(props.values);
  }

  async function fetchRetailer(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrRetailers = [];
      const result = await apiClient().get("/retailers/search?q=name:" + value);
      if (result) {
        for (var i = 0; i < result.data.retailers.length; i++) {
          arrRetailers.push({
            value: result.data.retailers[i]._id,
            label: result.data.retailers[i].name,
          });
        }
      }
      await setRetailer(arrRetailers);
      setState({ ...state, loading: false });
    }
  }

  useEffect(() => {
    let arrManufacturer = [];
    setManufacturer([]);
    arrManufacturer.push({
      value: props.values.manufacturer,
      label: props.values.manufacturer_name,
    });
    setManufacturer(arrManufacturer);

    let arrCountry = [];
    setCountry([]);
    arrCountry.push({
      value: props.values.country,
      label: props.values.country_name,
    });
    setCountry(arrCountry);

    let arrCategory = [];
    setCategory([]);
    arrCategory.push({
      value: props.values.category,
      label: props.values.category_name,
    });
    setCategory(arrCategory);

    async function fetchDetail() {
      let regionsIds = [];
      setRegion([]);
      if (props.values.space_share) {
        for (let i = 0; i < props.values.space_share.length; i++) {
          if (props.values.space_share[i].regions) {
            for (
              let j = 0;
              j < props.values.space_share[i].regions.length;
              j++
            ) {
              regionsIds.push(props.values.space_share[i].regions[j]);
            }
          }
        }
        if (regionsIds.length > 0) {
          try {
            const result = await apiClient().post("/regions/getByIds", {
              ids: regionsIds
            });
            if (result.data) {
              setRegion(result.data.map((i: { _id: string, region: string }) =>
                ({ value: i._id, label: i.region })));
            }
          } catch {
            message.error("Something went wrong in fetching the region names from ids")
          }
        }
      }
      let arrChannel = [];
      setChannel([]);
      if (props.values.space_share) {
        for (let i = 0; i < props.values.space_share.length; i++) {
          if (props.values.space_share[i].channels) {
            //arrChannel[i] = new Array();
            for (
              let j = 0;
              j < props.values.space_share[i].channels.length;
              j++
            ) {
              try {
                const result = await apiClient().get(
                  "/channels/" +
                    props.values.space_share[i].channels[j] +
                    "/getName"
                );
                arrChannel.push({
                  value: props.values.space_share[i].channels[j],
                  label: result.data,
                });
              } catch (e) {}
            }
          }
        }
        console.log(arrChannel);
        setChannel(arrChannel);
      }
      let arrOutletclass = [];
      setOutletclass([]);
      if (props.values.space_share) {
        for (let i = 0; i < props.values.space_share.length; i++) {
          if (props.values.space_share[i].outlet_classes) {
            //arrOutletclass[i] = new Array();
            for (
              let j = 0;
              j < props.values.space_share[i].outlet_classes.length;
              j++
            ) {
              try {
                const result = await apiClient().get(
                  "/outlet_classes/" +
                    props.values.space_share[i].outlet_classes[j] +
                    "/getName"
                );
                arrOutletclass.push({
                  value: props.values.space_share[i].outlet_classes[j],
                  label: result.data,
                });
              } catch (e) {}
            }
          }
        }
        setOutletclass(arrOutletclass);
      }
      let arrRetailer = [];
      setRetailer([]);
      if (props.values.space_share) {
        for (let i = 0; i < props.values.space_share.length; i++) {
          if (props.values.space_share[i].retailers) {
            //arrRetailer[i] = new Array();
            for (
              let j = 0;
              j < props.values.space_share[i].retailers.length;
              j++
            ) {
              try {
                const result = await apiClient().get(
                  "/retailers/" +
                    props.values.space_share[i].retailers[j] +
                    "/getName"
                );
                arrRetailer.push({
                  value: props.values.space_share[i].retailers[j],
                  label: result.data,
                });
              } catch (e) {}
            }
          }
        }
        setRetailer(arrRetailer);
      }

      if (props.values.space_share) {
        const arrData = [];
        for (var ind = 0; ind < props.values.space_share.length; ind++) {
          for (
            var ind1 = 0;
            ind1 < props.values.space_share[ind].identifier_id.length;
            ind1++
          ) {
            for (
              var ind2 = 0;
              ind2 < props.values.space_share[ind].identifier_id[ind1].length;
              ind2++
            ) {
              if (
                props.values.space_share[ind].identifier_type[ind1] ===
                "product"
              ) {
                arrData.push({
                  value:
                    props.values.space_share[ind].identifier_id[ind1][ind2],
                  label:
                    props.values.space_share[ind].identifier_id[ind1][ind2],
                });
              } else if (
                props.values.space_share[ind].identifier_type[ind1] ===
                "sub_brand"
              ) {
                try {
                  const result = await apiClient().get(
                    "/sub_brands/" +
                      props.values.space_share[ind].identifier_id[ind1][ind2]
                  );

                  if (result) {
                    arrData.push({
                      value: result.data._id,
                      label: result.data.sub_brand,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].identifier_type[ind1] === "brand"
              ) {
                try {
                  const result = await apiClient().get(
                    "/brands/getBrandName/" +
                      props.values.space_share[ind].identifier_id[ind1][ind2]
                  );

                  if (result) {
                    arrData.push({
                      value: result.data._id,
                      label: result.data.brand,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].identifier_type[ind1] ===
                "category"
              ) {
                try {
                  const result = await apiClient().get(
                    "/category/getCategoryName/" +
                      props.values.space_share[ind].identifier_id[ind1][ind2]
                  );

                  if (result) {
                    arrData.push({
                      value:
                        props.values.space_share[ind].identifier_id[ind1][ind2],
                      label: result.data,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].identifier_type[ind1] ===
                "subcategory1"
              ) {
                try {
                  const result = await apiClient().get(
                    "/category/getSubCategoryName/1/" +
                      props.values.space_share[ind].identifier_id[ind1][ind2]
                  );

                  if (result) {
                    arrData.push({
                      value:
                        props.values.space_share[ind].identifier_id[ind1][ind2],
                      label: result.data,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].identifier_type[ind1] ===
                "subcategory2"
              ) {
                try {
                  const result = await apiClient().get(
                    "/category/getSubCategoryName/2/" +
                      props.values.space_share[ind].identifier_id[ind1][ind2]
                  );

                  if (result) {
                    arrData.push({
                      value:
                        props.values.space_share[ind].identifier_id[ind1][ind2],
                      label: result.data,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].identifier_type[ind1] ===
                "subcategory3"
              ) {
                try {
                  const result = await apiClient().get(
                    "/category/getSubCategoryName/3/" +
                      props.values.space_share[ind].identifier_id[ind1][ind2]
                  );

                  if (result) {
                    arrData.push({
                      value:
                        props.values.space_share[ind].identifier_id[ind1][ind2],
                      label: result.data,
                    });
                  }
                } catch (e) {}
              }
            }
          }
        }
        setIdentifierID(arrData);

        const arrDataUniverse = [];
        for (var ind = 0; ind < props.values.space_share.length; ind++) {
          for (
            var ind1 = 0;
            ind1 < props.values.space_share[ind].universe_id.length;
            ind1++
          ) {
            for (
              var ind2 = 0;
              ind2 < props.values.space_share[ind].universe_id[ind1].length;
              ind2++
            ) {
              if (
                props.values.space_share[ind].universe_type[ind1] === "product"
              ) {
                arrDataUniverse.push({
                  value: props.values.space_share[ind].universe_id[ind1][ind2],
                  label: props.values.space_share[ind].universe_id[ind1][ind2],
                });
              } else if (
                props.values.space_share[ind].universe_type[ind1] ===
                "sub_brand"
              ) {
                try {
                  const result = await apiClient().get(
                    "/sub_brands/" +
                      props.values.space_share[ind].universe_id[ind1][ind2]
                  );

                  if (result) {
                    arrDataUniverse.push({
                      value: result.data._id,
                      label: result.data.sub_brand,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].universe_type[ind1] === "brand"
              ) {
                try {
                  const result = await apiClient().get(
                    "/brands/getBrandName/" +
                      props.values.space_share[ind].universe_id[ind1][ind2]
                  );

                  if (result) {
                    arrDataUniverse.push({
                      value: result.data._id,
                      label: result.data.brand,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].universe_type[ind1] === "category"
              ) {
                try {
                  const result = await apiClient().get(
                    "/category/getCategoryName/" +
                      props.values.space_share[ind].universe_id[ind1][ind2]
                  );

                  if (result) {
                    arrDataUniverse.push({
                      value:
                        props.values.space_share[ind].universe_id[ind1][ind2],
                      label: result.data,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].universe_type[ind1] ===
                "subcategory1"
              ) {
                try {
                  const result = await apiClient().get(
                    "/category/getSubCategoryName/1/" +
                      props.values.space_share[ind].universe_id[ind1][ind2]
                  );

                  if (result) {
                    arrDataUniverse.push({
                      value:
                        props.values.space_share[ind].universe_id[ind1][ind2],
                      label: result.data,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].universe_type[ind1] ===
                "subcategory2"
              ) {
                try {
                  const result = await apiClient().get(
                    "/category/getSubCategoryName/2/" +
                      props.values.space_share[ind].universe_id[ind1][ind2]
                  );

                  if (result) {
                    arrDataUniverse.push({
                      value:
                        props.values.space_share[ind].universe_id[ind1][ind2],
                      label: result.data,
                    });
                  }
                } catch (e) {}
              } else if (
                props.values.space_share[ind].universe_type[ind1] ===
                "subcategory3"
              ) {
                try {
                  const result = await apiClient().get(
                    "/category/getSubCategoryName/3/" +
                      props.values.space_share[ind].universe_id[ind1][ind2]
                  );

                  if (result) {
                    arrDataUniverse.push({
                      value:
                        props.values.space_share[ind].universe_id[ind1][ind2],
                      label: result.data,
                    });
                  }
                } catch (e) {}
              }
            }
          }
        }
        setUniverseID(arrDataUniverse);

        let manfsIds = []
        for (var ind = 0; ind < props.values.space_share.length; ind++) {
          for (
            var ind1 = 0;
            ind1 < props.values.space_share[ind].competitors.length;
            ind1++
          ) {
            for (
              var ind2 = 0;
              ind2 < props.values.space_share[ind].competitors[ind1].length;
              ind2++
            ) {
              manfsIds.push(props.values.space_share[ind].competitors[ind1][ind2])
            }
          }
        }
        try {
          const result = await apiClient().post("manufacturers/getByIds", {
            ids: manfsIds
          });
          setCompetitor(result.data.map((i: { _id: string, name: string }) =>
            ({ value: i._id, label: i.name })));
        } catch {
          message.error("Something went wrong in fetching the manufacture names from ids")
        }
      }
    }
    fetchDetail();
  }, [props.values]);
  //const loading = loadingProducts;

  const addIdentifier = (ind: number) => (
    <PlusCircleOutlined
      style={{
        width: "5%",
        textAlign: "right",
        color: "#52c41a",
        fontSize: 16,
      }}
      onClick={(event) => {
        event.stopPropagation();
        props.values.space_share[ind].identifier_type.push("");
        props.values.space_share[ind].identifier_id.push("");
        props.values.space_share[ind].universe_type.push("");
        props.values.space_share[ind].universe_id.push("");
        props.values.space_share[ind].points.push(0);
        props.values.space_share[ind].type.push("");
        props.values.space_share[ind].target.push("");
        setState({ ...state, loading: false });
      }}
    />
  );

  function handleIndentifierType(value: any, index: any, index1: any) {
    props.values.space_share[index].identifier_type[index1] = value;
    message.error("Changing identifier type will clear all the entries.");
    /*for (
      var i = 0;
      i < props.values.adjacency[index].identifier_id[index1].length;
      i++
    ) {
      props.values.adjacency[index].identifier_id[index1] = [];
    }*/
    props.values.space_share[index].identifier_id[index1] = [];
    /*while (props.values.eye_level[index].identifier_id.length > 0) {
      props.values.eye_level[index].identifier_id.pop();
    }*/
    setState({ ...state, loading: false });
  }

  function handleIndentifierID(value: any, index: any, index1: any) {
    props.values.space_share[index].identifier_id[index1] = value;
    setState({ ...state, loading: false });
  }

  async function fetchIndentifierID(value: string, index: any, index1: any) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrData = [];
      if (
        props.values.space_share[index].identifier_type[index1] === "product"
      ) {
        const result = await apiClient().get(
          "/products/search/milkywayids?id=" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i].milkywayID,
              label: result.data[i].milkywayID,
            });
          }
        }
      } else if (
        props.values.space_share[index].identifier_type[index1] === "sub_brand"
      ) {
        const result = await apiClient().get(
          "/sub_brands/search?q=sub_brand:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.sub_brands.length; i++) {
            arrData.push({
              value: result.data.sub_brands[i]._id,
              label: result.data.sub_brands[i].sub_brand,
            });
          }
        }
      } else if (
        props.values.space_share[index].identifier_type[index1] === "brand"
      ) {
        const result = await apiClient().get("/brands/search?q=brand:" + value);
        if (result) {
          for (var i = 0; i < result.data.brands.length; i++) {
            arrData.push({
              value: result.data.brands[i]._id,
              label: result.data.brands[i].brand,
            });
          }
        }
      } else if (
        props.values.space_share[index].identifier_type[index1] === "category"
      ) {
        const result = await apiClient().get(
          "/category/search?q=category:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.categories.length; i++) {
            arrData.push({
              value: result.data.categories[i]._id,
              label: result.data.categories[i].category,
            });
          }
        }
      } else if (
        props.values.space_share[index].identifier_type[index1] ===
        "subcategory1"
      ) {
        const result = await apiClient().get(
          "/category/subcategory1/search?q=subcategory1:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.subcategories.length; i++) {
            arrData.push({
              value: result.data.subcategories[i]._id,
              label: result.data.subcategories[i].subcategory1,
            });
          }
        }
      } else if (
        props.values.space_share[index].identifier_type[index1] ===
        "subcategory2"
      ) {
        const result = await apiClient().get(
          "/category/subcategory2/search?q=subcategory2:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.subcategories.length; i++) {
            arrData.push({
              value: result.data.subcategories[i]._id,
              label: result.data.subcategories[i].subcategory2,
            });
          }
        }
      } else if (
        props.values.space_share[index].identifier_type[index1] ===
        "subcategory3"
      ) {
        const result = await apiClient().get(
          "/category/subcategory3/search?q=subcategory3:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.subcategories.length; i++) {
            arrData.push({
              value: result.data.subcategories[i]._id,
              label: result.data.subcategories[i].subcategory3,
            });
          }
        }
      }
      setIdentifierID(arrData);
      setState({ ...state, loading: false });
    }
  }

  function handleUniverseType(value: any, index: any, index1: any) {
    props.values.space_share[index].universe_type[index1] = value;
    message.error("Changing universe type will clear all the entries.");
    /*for (
      var i = 0;
      i < props.values.adjacency[index].identifier_id[index1].length;
      i++
    ) {
      props.values.adjacency[index].identifier_id[index1] = [];
    }*/
    props.values.space_share[index].universe_id[index1] = [];
    /*while (props.values.eye_level[index].identifier_id.length > 0) {
      props.values.eye_level[index].identifier_id.pop();
    }*/
    setState({ ...state, loading: false });
  }

  function handleDisplayType(value: any, index: any, index1: any) {
    props.values.space_share[index].req_display_type[index1] = value;
    setState({ ...state, loading: false });
  }

  function handleCompetitor(value: any, index: any, index1: any) {
    props.values.space_share[index].competitors[index1] = value;
    setState({ ...state, loading: false });
  }

  async function fetchCompetitor(value: string, index: any, index1: any) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrData = [];

      const result = await apiClient().get(
        "/manufacturers/search?q=name:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.manufacturers.length; i++) {
          arrData.push({
            value: result.data.manufacturers[i]._id,
            label: result.data.manufacturers[i].name,
          });
        }
      }

      setCompetitor(arrData);
      setState({ ...state, loading: false });
    }
  }

  function handleUniverseID(value: any, index: any, index1: any) {
    props.values.space_share[index].universe_id[index1] = value;
    setState({ ...state, loading: false });
  }

  async function fetchUniverseID(value: string, index: any, index1: any) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrData = [];
      if (props.values.space_share[index].universe_type[index1] === "product") {
        const result = await apiClient().get(
          "/products/search/milkywayids?id=" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i].milkywayID,
              label: result.data[i].milkywayID,
            });
          }
        }
      } else if (
        props.values.space_share[index].universe_type[index1] === "sub_brand"
      ) {
        const result = await apiClient().get(
          "/sub_brands/search?q=sub_brand:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.sub_brands.length; i++) {
            arrData.push({
              value: result.data.sub_brands[i]._id,
              label: result.data.sub_brands[i].sub_brand,
            });
          }
        }
      } else if (
        props.values.space_share[index].universe_type[index1] === "brand"
      ) {
        const result = await apiClient().get("/brands/search?q=brand:" + value);
        if (result) {
          for (var i = 0; i < result.data.brands.length; i++) {
            arrData.push({
              value: result.data.brands[i]._id,
              label: result.data.brands[i].brand,
            });
          }
        }
      } else if (
        props.values.space_share[index].universe_type[index1] === "category"
      ) {
        const result = await apiClient().get(
          "/category/search?q=category:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.categories.length; i++) {
            arrData.push({
              value: result.data.categories[i]._id,
              label: result.data.categories[i].category,
            });
          }
        }
      } else if (
        props.values.space_share[index].universe_type[index1] === "subcategory1"
      ) {
        const result = await apiClient().get(
          "/category/subcategory1/search?q=subcategory1:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.subcategories.length; i++) {
            arrData.push({
              value: result.data.subcategories[i]._id,
              label: result.data.subcategories[i].subcategory1,
            });
          }
        }
      } else if (
        props.values.space_share[index].universe_type[index1] === "subcategory2"
      ) {
        const result = await apiClient().get(
          "/category/subcategory2/search?q=subcategory2:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.subcategories.length; i++) {
            arrData.push({
              value: result.data.subcategories[i]._id,
              label: result.data.subcategories[i].subcategory2,
            });
          }
        }
      } else if (
        props.values.space_share[index].universe_type[index1] === "subcategory3"
      ) {
        const result = await apiClient().get(
          "/category/subcategory3/search?q=subcategory3:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.subcategories.length; i++) {
            arrData.push({
              value: result.data.subcategories[i]._id,
              label: result.data.subcategories[i].subcategory3,
            });
          }
        }
      }
      setUniverseID(arrData);
      setState({ ...state, loading: false });
    }
  }

  function handlepPoints(value: any, index: any, index1: any) {
    props.values.space_share[index].points[index1] = value.floatValue;
    console.log(props.values);
  }

  function handleTarget(value: any, index: any, index1: any) {
    props.values.space_share[index].target[index1] = value;
  }

  function handleType(value: any, index: any, index1: any) {
    props.values.space_share[index].type[index1] = value;
  }

  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {props.visible && (
          <Spin tip="Loading..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              initialValues={props.values}
              style={{ fontWeight: "bold" }}
              onChange={() => {
                setChanged(true);
              }}
            >
              <Form.Item label="ID" style={{ display: "none" }} name={"_id"}>
                <Input value={props.values._id} disabled />
              </Form.Item>
              <Form.Item
                name="date"
                label={
                  <span>
                    <span style={{ color: "#ff4d4f" }}>*</span>
                    &nbsp;Date
                  </span>
                }
              >
                {props.isCreate === false && (
                  <>
                    <DatePicker
                      defaultValue={moment.utc(props.values.date).local()}
                      onChange={handleDate}
                    />
                  </>
                )}
                {props.isCreate === true && (
                  <>
                    <DatePicker
                      //defaultValue={moment(new Date(), "YYYY-MM-DD")}
                      onChange={handleDate}
                    />
                  </>
                )}
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "#ff4d4f" }}>*</span>
                    &nbsp;Manufacturer
                  </span>
                }
                name={"manufacturer"}
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  defaultValue={props.values.manufacturer}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchManufacturer(e)}
                  onChange={(e) => handleManufacturer(e)}
                  options={manufacturer}
                  getPopupContainer={(trigger) => trigger.parentNode}
                ></Select>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "#ff4d4f" }}>*</span>&nbsp;Country
                  </span>
                }
                name={"country"}
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  defaultValue={props.values.country}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchCountry(e)}
                  onChange={(e) => handleCountry(e)}
                  options={country}
                  getPopupContainer={(trigger) => trigger.parentNode}
                ></Select>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "#ff4d4f" }}>*</span>&nbsp;Category
                  </span>
                }
                name={"category"}
              >
                <Select
                  style={{ fontWeight: "normal" }}
                  defaultValue={props.values.category}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={(e) => fetchCategory(e)}
                  onChange={(e) => handleCatagory(e)}
                  options={category}
                  getPopupContainer={(trigger) => trigger.parentNode}
                ></Select>
              </Form.Item>
              <Form.Item label="Space Share" name="space_share">
                <Collapse style={{ fontWeight: "normal" }}>
                  <Panel header="Items" key="1">
                    <Collapse>
                      {props.values.space_share &&
                        props.values.space_share.map((val, index) => {
                          return (
                            <Panel
                              /*header={`Item #${
                              props.values.space_share.indexOf(val) + 1
                            }`}*/
                              header={`Item`}
                              key={props.values.space_share.indexOf(val)}
                              extra={deleteItem(
                                props.values.space_share.indexOf(val)
                              )}
                            >
                              {
                                <>
                                  <Form.Item
                                    label={
                                      <span style={{ width: "70px" }}>
                                        Region
                                      </span>
                                    }
                                    //name={"regions"}
                                  >
                                    <Select
                                      style={{ fontWeight: "normal" }}
                                      defaultValue={
                                        props.values.space_share[index].regions
                                      }
                                      showSearch
                                      showArrow={true}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option?.props.label
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0 ||
                                        option?.props.value
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      onSearch={(e) => fetchRegion(e)}
                                      onChange={(e) => handleRegion(e, index)}
                                      options={region}
                                      mode={"multiple"}
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                    ></Select>
                                  </Form.Item>
                                  <Form.Item
                                    label={
                                      <span style={{ width: "70px" }}>
                                        Channel
                                      </span>
                                    }
                                    //name={"channels"}
                                  >
                                    <Select
                                      style={{ fontWeight: "normal" }}
                                      defaultValue={
                                        props.values.space_share[index].channels
                                      }
                                      showSearch
                                      showArrow={true}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option?.props.label
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0 ||
                                        option?.props.value
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      onSearch={(e) => fetchChannel(e)}
                                      onChange={(e) => handleChannel(e, index)}
                                      options={channel}
                                      mode={"multiple"}
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                    ></Select>
                                  </Form.Item>
                                  <Form.Item
                                    label={
                                      <span style={{ width: "70px" }}>
                                        Outlet class
                                      </span>
                                    }
                                    //name={"outlet_classes"}
                                  >
                                    <Select
                                      style={{ fontWeight: "normal" }}
                                      defaultValue={
                                        props.values.space_share[index]
                                          .outlet_classes
                                      }
                                      showSearch
                                      showArrow={true}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option?.props.label
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0 ||
                                        option?.props.value
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      onSearch={(e) => fetchOutletclass(e)}
                                      onChange={(e) =>
                                        handleOutletclass(e, index)
                                      }
                                      options={outletclass}
                                      mode={"multiple"}
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                    ></Select>
                                  </Form.Item>
                                  <Form.Item
                                    label={
                                      <span style={{ width: "70px" }}>
                                        Retailer
                                      </span>
                                    }
                                    //name={"retailers"}
                                  >
                                    <Select
                                      style={{ fontWeight: "normal" }}
                                      defaultValue={
                                        props.values.space_share[index]
                                          .retailers
                                      }
                                      showSearch
                                      showArrow={true}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option?.props.label
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0 ||
                                        option?.props.value
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      onSearch={(e) => fetchRetailer(e)}
                                      onChange={(e) => handleRetailer(e, index)}
                                      options={retailer}
                                      mode={"multiple"}
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                    ></Select>
                                  </Form.Item>
                                  <Collapse>
                                    <Panel
                                      header="Identifier"
                                      key={index}
                                      //extra={addIdentifier(index)}
                                    >
                                      {props.values.space_share[index]
                                        .identifier_type &&
                                        props.values.space_share[
                                          index
                                        ].identifier_type.map(
                                          (identifier_type, index1) => {
                                            return (
                                              <>
                                                <Row>
                                                  <Col span={6}>
                                                    <Form.Item
                                                      label="Identifier Type"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                      //name={"products"}
                                                    >
                                                      <Select
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .identifier_type[
                                                            index1
                                                          ]
                                                        }
                                                        //showSearch
                                                        showArrow={true}
                                                        optionFilterProp="children"
                                                        onChange={(e) =>
                                                          handleIndentifierType(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        getPopupContainer={(
                                                          trigger
                                                        ) => trigger.parentNode}
                                                      >
                                                        <Option
                                                          value={"product"}
                                                        >
                                                          product
                                                        </Option>
                                                        <Option
                                                          value={"sub_brand"}
                                                        >
                                                          sub_brand
                                                        </Option>
                                                        <Option value={"brand"}>
                                                          brand
                                                        </Option>
                                                        <Option
                                                          value={"category"}
                                                        >
                                                          category
                                                        </Option>
                                                        <Option
                                                          value={"subcategory1"}
                                                        >
                                                          subcategory1
                                                        </Option>
                                                        <Option
                                                          value={"subcategory2"}
                                                        >
                                                          subcategory2
                                                        </Option>
                                                        <Option
                                                          value={"subcategory3"}
                                                        >
                                                          subcategory3
                                                        </Option>
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>

                                                  <Col span={1}></Col>
                                                  <Col span={15}>
                                                    <Form.Item
                                                      //style={{ marginLeft: 10 }}
                                                      //name={"values"}
                                                      label="Identifier ID"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                    >
                                                      <Select
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .identifier_id[
                                                            index1
                                                          ]
                                                        }
                                                        value={
                                                          props.values
                                                            .space_share[index]
                                                            .identifier_id[
                                                            index1
                                                          ]
                                                        }
                                                        showSearch
                                                        showArrow={true}
                                                        optionFilterProp="children"
                                                        filterOption={(
                                                          input,
                                                          option
                                                        ) =>
                                                          option?.props.label
                                                            .toLowerCase()
                                                            .indexOf(
                                                              input.toLowerCase()
                                                            ) >= 0 ||
                                                          option?.props.value
                                                            .toLowerCase()
                                                            .indexOf(
                                                              input.toLowerCase()
                                                            ) >= 0
                                                        }
                                                        onSearch={(e) =>
                                                          fetchIndentifierID(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        onChange={(e) =>
                                                          handleIndentifierID(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        options={identifierID}
                                                        mode={"multiple"}
                                                        getPopupContainer={(
                                                          trigger
                                                        ) => trigger.parentNode}
                                                      ></Select>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col
                                                    span={2}
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <DeleteOutlined
                                                      style={{
                                                        width: "5%",
                                                        textAlign: "right",
                                                        color: "#DC3445",
                                                        fontSize: 16,
                                                        marginTop: 10,
                                                      }}
                                                      onClick={(event) => {
                                                        delete values
                                                          .space_share[index]
                                                          .identifier_type[
                                                          index1
                                                        ];
                                                        delete values
                                                          .space_share[index]
                                                          .identifier_id[
                                                          index1
                                                        ];
                                                        delete values
                                                          .space_share[index]
                                                          .universe_type[
                                                          index1
                                                        ];
                                                        delete values
                                                          .space_share[index]
                                                          .universe_id[index1];
                                                        delete values
                                                          .space_share[index]
                                                          .req_display_type[
                                                          index1
                                                        ];
                                                        delete values
                                                          .space_share[index]
                                                          .points[index1];
                                                        delete props.values
                                                          .space_share[index]
                                                          .type[index1];
                                                        delete props.values
                                                          .space_share[index]
                                                          .target[index1];
                                                        setState({
                                                          ...state,
                                                          loading: false,
                                                        });
                                                      }}
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col span={6}>
                                                    <Form.Item
                                                      label="Universe Type"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                      //name={"products"}
                                                    >
                                                      <Select
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .universe_type[
                                                            index1
                                                          ]
                                                        }
                                                        //showSearch
                                                        showArrow={true}
                                                        optionFilterProp="children"
                                                        onChange={(e) =>
                                                          handleUniverseType(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        getPopupContainer={(
                                                          trigger
                                                        ) => trigger.parentNode}
                                                      >
                                                        <Option
                                                          value={"product"}
                                                        >
                                                          product
                                                        </Option>
                                                        <Option
                                                          value={"sub_brand"}
                                                        >
                                                          sub_brand
                                                        </Option>
                                                        <Option value={"brand"}>
                                                          brand
                                                        </Option>
                                                        <Option
                                                          value={"category"}
                                                        >
                                                          category
                                                        </Option>
                                                        <Option
                                                          value={"subcategory1"}
                                                        >
                                                          subcategory1
                                                        </Option>
                                                        <Option
                                                          value={"subcategory2"}
                                                        >
                                                          subcategory2
                                                        </Option>
                                                        <Option
                                                          value={"subcategory3"}
                                                        >
                                                          subcategory3
                                                        </Option>
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>

                                                  <Col span={1}></Col>
                                                  <Col span={15}>
                                                    <Form.Item
                                                      //style={{ marginLeft: 10 }}
                                                      //name={"values"}
                                                      label="Universe ID"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                    >
                                                      <Select
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .universe_id[index1]
                                                        }
                                                        value={
                                                          props.values
                                                            .space_share[index]
                                                            .universe_id[index1]
                                                        }
                                                        showSearch
                                                        showArrow={true}
                                                        optionFilterProp="children"
                                                        filterOption={(
                                                          input,
                                                          option
                                                        ) =>
                                                          option?.props.label
                                                            .toLowerCase()
                                                            .indexOf(
                                                              input.toLowerCase()
                                                            ) >= 0 ||
                                                          option?.props.value
                                                            .toLowerCase()
                                                            .indexOf(
                                                              input.toLowerCase()
                                                            ) >= 0
                                                        }
                                                        onSearch={(e) =>
                                                          fetchUniverseID(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        onChange={(e) =>
                                                          handleUniverseID(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        options={universeID}
                                                        mode={"multiple"}
                                                        getPopupContainer={(
                                                          trigger
                                                        ) => trigger.parentNode}
                                                      ></Select>
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col span={6}>
                                                    <Form.Item
                                                      label="Display Type"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                      //name={"products"}
                                                    >
                                                      <Select
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .req_display_type[
                                                            index1
                                                          ]
                                                        }
                                                        //showSearch
                                                        showArrow={true}
                                                        optionFilterProp="children"
                                                        onChange={(e) =>
                                                          handleDisplayType(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        getPopupContainer={(
                                                          trigger
                                                        ) => trigger.parentNode}
                                                      >
                                                        <Option value={"all"}>
                                                          all
                                                        </Option>
                                                        <Option
                                                          value={"primary"}
                                                        >
                                                          primary
                                                        </Option>
                                                        <Option
                                                          value={"secondary"}
                                                        >
                                                          secondary
                                                        </Option>
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>

                                                  <Col span={1}></Col>
                                                  <Col span={15}>
                                                    <Form.Item
                                                      //style={{ marginLeft: 10 }}
                                                      //name={"values"}
                                                      label="Competitors"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                    >
                                                      <Select
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .competitors[index1]
                                                        }
                                                        showSearch
                                                        showArrow={true}
                                                        optionFilterProp="children"
                                                        filterOption={(
                                                          input,
                                                          option
                                                        ) =>
                                                          option?.props.label
                                                            .toLowerCase()
                                                            .indexOf(
                                                              input.toLowerCase()
                                                            ) >= 0 ||
                                                          option?.props.value
                                                            .toLowerCase()
                                                            .indexOf(
                                                              input.toLowerCase()
                                                            ) >= 0
                                                        }
                                                        onSearch={(e) =>
                                                          fetchCompetitor(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        onChange={(e) =>
                                                          handleCompetitor(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        options={competitor}
                                                        mode={"multiple"}
                                                        getPopupContainer={(
                                                          trigger
                                                        ) => trigger.parentNode}
                                                      ></Select>
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col xs={6}>
                                                    <Form.Item
                                                      label="Type"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                      //name={"products"}
                                                    >
                                                      <Select
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .type[index1]
                                                        }
                                                        //showSearch
                                                        showArrow={true}
                                                        optionFilterProp="children"
                                                        onChange={(e) =>
                                                          handleType(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        getPopupContainer={(
                                                          trigger
                                                        ) => trigger.parentNode}
                                                      >
                                                        <Option value={"base"}>
                                                          base
                                                        </Option>
                                                        <Option value={"bonus"}>
                                                          bonus
                                                        </Option>
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col xs={1}></Col>
                                                  <Col xs={7}>
                                                    <Form.Item
                                                      label="Points"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                      //name={"values"}
                                                    >
                                                      <NumberFormat
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .points[index1]
                                                        }
                                                        onValueChange={(e) =>
                                                          handlepPoints(
                                                            e,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                        //value={2456981}
                                                        //displayType="text"
                                                        className="ant-input"
                                                        thousandSeparator={true}
                                                        prefix=""
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col xs={1}></Col>
                                                  <Col xs={7}>
                                                    <Form.Item
                                                      label="Target"
                                                      labelCol={{ span: 24 }}
                                                      wrapperCol={{ span: 24 }}
                                                      //name={"values"}
                                                    >
                                                      <Input
                                                        defaultValue={
                                                          props.values
                                                            .space_share[index]
                                                            .target[index1]
                                                        }
                                                        //style={{ marginLeft: 10 }}
                                                        //value={val.values[index1]}
                                                        onChange={(e) =>
                                                          handleTarget(
                                                            e.target.value,
                                                            index,
                                                            index1
                                                          )
                                                        }
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                                <Divider
                                                  style={{
                                                    backgroundColor: "#1891FF",
                                                  }}
                                                ></Divider>
                                              </>
                                            );
                                          }
                                        )}
                                      <Button
                                        type="primary"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          props.values.space_share[
                                            index
                                          ].identifier_type.push("");
                                          props.values.space_share[
                                            index
                                          ].identifier_id.push();
                                          props.values.space_share[
                                            index
                                          ].universe_type.push("");
                                          props.values.space_share[
                                            index
                                          ].universe_id.push();
                                          props.values.space_share[
                                            index
                                          ].points.push(0);
                                          props.values.space_share[
                                            index
                                          ].type.push("");
                                          props.values.space_share[
                                            index
                                          ].target.push("");
                                          setState({
                                            ...state,
                                            loading: false,
                                          });
                                        }}
                                        style={{ marginTop: 10 }}
                                      >
                                        Add New Idetifier
                                      </Button>
                                    </Panel>
                                  </Collapse>
                                </>
                              }
                            </Panel>
                          );
                        })}
                    </Collapse>
                    <Button
                      type="primary"
                      onClick={() => {
                        props.values.space_share.push({
                          regions: [],
                          channels: [],
                          outlet_classes: [],
                          retailers: [],
                          identifier_type: [],
                          identifier_id: [],
                          universe_type: [],
                          universe_id: [],
                          points: [],
                          type: [],
                          target: [],
                          req_display_type: [],
                          competitors: [],
                        });
                        setState({ ...state, loading: false });
                      }}
                      style={{ marginTop: 10 }}
                    >
                      Add New Item
                    </Button>
                  </Panel>
                </Collapse>
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Drawer>
    </>
  );
};

export default SpaceShareSettingsForm;

export interface ShelfBlockSetting {
  _id: string;
  date: string;
  manufacturer: string;
  manufacturer_name: string;
  country: string;
  country_name: string;
  category: string;
  category_name: string;
  shelf_block: any[];
}

export const shelf_block_setting_default = {
  _id: "",
  date: "",
  manufacturer: "",
  manufacturer_name: "",
  country: "",
  country_name: "",
  category: "",
  category_name: "",
  shelf_block: Array({
    regions: [],
    channels: [],
    outlet_classes: [],
    retailers: [],
    identifier_type: [],
    identifier_id: [],
    points: [],
    type: [],
    target: [],
  }),
};

export interface ShelfBlocktSettingDetailFormData {
  _id: string;
  date: string;
  manufacturer: string;
  manufacturer_name: string;
  country: string;
  country_name: string;
  category: string;
  category_name: string;
  shelf_block: Array<{
    regions: string[];
    channels: string[];
    outlet_classes: string[];
    retailers: string[];
    identifier_type: string[];
    identifier_id: any[];
    points: number[];
    type: string[];
    target: number[];
  }>;
}

export interface ShelfBlockSettingUpdateProps {
  date: string;
  _id: string;
  manufacturer: string;
  country: string;
  category: string;
  shelf_block: any[];
}

export const updateParams = (params: ShelfBlockSettingUpdateProps) => {
  return {
    _id: params._id,
    date: params.date,
    manufacturer: params.manufacturer,
    country: params.country,
    category: params.category,
    shelf_block: params.shelf_block,
  };
};

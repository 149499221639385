export interface SubCategory3 {
    _id: string,
    subcategory3: string,
}

export const subcategory3_default = {
    _id: "",
    subcategory3: "",
}

export interface SubCategory3DetailFormData {
    _id: string;
    subcategory3: string;
}

export interface SubCategory3UpdateProps {
    subcategory3: string;
    _id: string;
}

export const updateParams = (params: SubCategory3UpdateProps) => {
    return {
        _id: params._id,
        subcategory3: params.subcategory3,
    };
}
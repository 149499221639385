import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Collapse, Form, InputNumber, List, message, Row, Select, Spin } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { useState } from "react";
import usePopulateIdentifierItems from "../../hooks/usePopulateIdentifierItems";
import apiClient from "../../services/authorizedApiClient";

interface Props {
  formListName: NamePath[],
  form: FormInstance,
  childName: any,
  restField: any,
  listKey: string,
  parentRemove: any,
}

;

export default function VericalblockIdentifierItems({
  formListName,
  form,
  restField,
  childName,
  listKey,
  parentRemove,
}: Props) {

  const identifier_type = form.getFieldValue([...formListName, "identifier_type"] as NamePath);
  const identifier_id_map = form.getFieldValue([...formListName, "identifier_id_map", childName] as NamePath);
  const identifier_id = (identifier_id_map || []).filter((i:any) => !!i).map((i: {id: string, target: string}) => i.id);
  const [isLoading, identifierIDs, setIsLoading, setIdentifierID] = usePopulateIdentifierItems(form, identifier_type, identifier_id);

  const [pageSize, setPageSize] = useState(3);
  const [current, setCurrent] = useState(1);

  const header = (add: () => void) => (
    <>
      <PlusCircleOutlined
        style={{
          textAlign: "right",
          color: "#52c41a",
          marginRight: '5px',
          fontSize: 16,
        }}
        onClick={(e) => {
          e.stopPropagation();
          add()
        }}
      />
      <DeleteOutlined
        style={{ color: "#DC3445", fontSize: 16 }}
        onClick={(e) => {
          e.stopPropagation();
          parentRemove();
        }}
      />
    </>

  );

  async function fetchIndentifierID(value: string) {
    const updatedPath = [...formListName].concat("identifier_type")
    const identifier_type = form.getFieldValue(updatedPath as NamePath);
    if (value !== "") {
      if (!identifier_type) {
        message.error("Please select identifier type first")
      }
      setIsLoading(true);
      const arrData = [];
      if (identifier_type === "product") {
        const result = await apiClient().get(
          "/products/search/milkywayids?id=" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i].milkywayID,
              label: result.data[i].milkywayID,
            });
          }
        }
      } else if (
        identifier_type === "sub_brand"
      ) {
        const result = await apiClient().get(
          "/sub_brands/search?q=sub_brand:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.sub_brands.length; i++) {
            arrData.push({
              value: result.data.sub_brands[i]._id,
              label: result.data.sub_brands[i].sub_brand,
            });
          }
        }
      } else if (
        identifier_type === "brand"
      ) {
        const result = await apiClient().get("/brands/search?q=brand:" + value);
        if (result) {
          for (var i = 0; i < result.data.brands.length; i++) {
            arrData.push({
              value: result.data.brands[i]._id,
              label: result.data.brands[i].brand,
            });
          }
        }
      }
      setIdentifierID(arrData);
      setIsLoading(false);
    }
  }

  return (
    <Form.Item
      {...restField}
      name={[childName]}
    >
      <Form.List name={[childName]}>
        {(fields, { add, remove }) =>
          <Collapse key={listKey}>
            <CollapsePanel
              header={
                <span>
                  Item
                  <span
                    style={{
                      color:
                        "#1891FF",
                      fontWeight:
                        "bold",
                      marginLeft: 5,
                    }}
                  >
                    OR
                  </span>
                </span>
              }
              key={childName + "identifier_id"}
              extra={header(() => {
                add();
                setCurrent(~~(fields.length/pageSize + 1))
              })}
            >
              <Row style={{ marginBottom: 10 }}>
                <Col span={16}>Identifier ID</Col>
                <Col span={1}></Col>
                <Col span={5}>Target</Col>
              </Row>
              <Spin tip="Loading..." spinning={isLoading}>
                <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    current,
                    pageSize,
                    showQuickJumper: true,
                    onShowSizeChange: (cur, ps) => setPageSize(ps),
                    onChange: (cur, size) => {
                      setCurrent(cur);
                      setPageSize(size)
                    }
                  }}
                  dataSource={fields}
                  renderItem={({ key, name: mapfieldName, ...restField }: any) => (
                    <Row>
                      <Col span={16}>
                        <Form.Item
                          {...restField}
                          name={[mapfieldName, 'id']}>
                          <Select
                            style={{
                              fontWeight: "normal",
                            }}
                            showSearch
                            showArrow={true}
                            optionFilterProp="children"
                            filterOption={(
                              input,
                              option: any
                            ) =>
                              option?.props.label
                                .toLowerCase()
                                .indexOf(
                                  input.toLowerCase()
                                ) >= 0 ||
                              option?.props.value
                                .toLowerCase()
                                .indexOf(
                                  input.toLowerCase()
                                ) >= 0
                            }
                            onSearch={(e) =>
                              fetchIndentifierID(
                                e
                              )
                            }
                            options={identifierIDs}
                            getPopupContainer={(
                              trigger
                            ) => trigger.parentNode}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[mapfieldName, 'target']}>
                          <InputNumber />
                        </Form.Item>
                      </Col>
                      <DeleteOutlined
                        style={{
                          width: "5%",
                          textAlign: "right",
                          color: "#DC3445",
                          fontSize: 16,
                          marginTop: 7,
                          marginLeft: 20,
                        }}
                        onClick={() => remove(mapfieldName)}
                      />
                    </Row>
                  )} />
              </Spin>

            </CollapsePanel>
          </Collapse>


        }
      </Form.List>

    </Form.Item>
  )
}
import {
  Form,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Drawer,
  Button,
  Modal,
  Collapse,
  message,
  Spin,
} from "antd/lib/";
import React, { useState, useEffect } from "react";
import Option from "antd/lib/select";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import { productService } from "../../services/ProductService";
import AutoCompleteInput from "../../components/AutoCompleter/AutoCompleteInput";
import { Product, product_default_categories, updateParams } from "./types";
import {
  TYPE,
} from "../../components/AutoCompleter/type";
import Collapsabile from "../../components/Collapsible/Collapsabile";
import { default_categories } from "../../components/Collapsible/types";
import UploadImage from "../../components/ModalImage/ModalImage";
import { DeleteOutlined } from "@ant-design/icons";
import { product_default } from "./types";
import SelectCountry from "../../components/SelectCountry";
import SelectBrand from "../../components/SelectBrand";
import SelectManufacturer from "../../components/SelectManufacturer";
import { getToken } from "../../services/authorizedApiClient";
import parseJwt from "../../helpers/token";
const { Panel } = Collapse;

interface IProductsProps {
  values: Product;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (data: Product) => void;
  onClose: () => void;
}

const ProductsForm: React.FC<IProductsProps> = (props) => {
  const [changed, setChanged] = useState(false);

  const [state, setState] = useState({
    loading: false,
    visible: false,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [values, setValues] = useState(JSON.parse(JSON.stringify(props.values)));
  const [form] = useForm();

  const formIntialValues = {
    ...values,
    created_at:
      values.created_at === ""
        ? moment()
        : moment(values.created_at, "YYYY-MM-DDTHH:mm:ss"),
    created_time:
      values.created_at === ""
        ? moment()
        : moment(values.created_at, "YYYY-MM-DDTHH:mm:ss"),
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 15,
      },
    },
  };

  async function validateProduct() {
    let validate = true;
    const formValues = (await form.getFieldsValue()) as Product;

    if (formValues.milkywayID === "") validate = false;
    if (formValues.name === "") validate = false;
    if (formValues.type === "") validate = false;
    let manufacturer = values.manufacturer
      ? values.manufacturer
      : formValues.manufacturer;

    if (manufacturer === "" || manufacturer === undefined) validate = false;
    let country = values.country ? values.country : formValues.country;
    if (country === "" || country === undefined) validate = false;
    let brand = values.brand ? values.brand : formValues.brand;
    if (brand === "" || brand === undefined) validate = false;
    let categories = values.categories
      ? values.categories
      : formValues.categories;

    if (categories) {
      categories = categories.filter(function (el: any) {
        return el != null;
      });

      for (let i = 0; i < categories.length; i++) {
        if (
          (categories[i] === undefined ||
            categories[i].category === "" ||
            categories[i].manufacturer === "") &&
          categories[i].isDelete === false
        ) {
          validate = false;
        }
      }
    }
    return validate;
  }
  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }
  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }
  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else props.setVisible(false);
  };

  const onSubmit = async () => {
    try {
      if (
        form.getFieldValue("manufacturer") === "" ||
        form.getFieldValue("manufacturer") === undefined
      )
        form.setFieldsValue({
          manufacturer: values.manufacturer,
          manufacturer_name: values.manufacturer_name,
        });
      const formValues = (await form.getFieldsValue()) as Product;

      const list = await productService.searchExact(formValues.milkywayID);

      if ((list && list.data === "") || values._id !== "") {
        values.categories = values.categories.filter(function (el: any) {
          return el != null;
        });

        var barcode = null;
        barcode =
          formValues.barcode != undefined || formValues.barcode != null
            ? formValues.barcode
            : values.barcode;
        if (barcode === "") barcode = null;
        var sub_brand = null;
        sub_brand =
          formValues.sub_brand != undefined || formValues.sub_brand != null
            ? formValues.sub_brand
            : values.sub_brand;
        if (sub_brand === "") sub_brand = null;
        var description = null;
        description =
          formValues.description != undefined || formValues.description != null
            ? formValues.description
            : values.description;
        if (description === "") description = null;
        var flavour = null;
        flavour =
          formValues.flavour != undefined || formValues.flavour != null
            ? formValues.flavour
            : values.flavour;
        if (flavour === "") flavour = null;
        var pack_size = null;
        pack_size =
          formValues.pack_size != undefined || formValues.pack_size != null
            ? formValues.pack_size
            : values.pack_size;
        if (pack_size === "") pack_size = null;
        var base_picture_url = formValues.base_picture_url.filter(function (
          el
        ) {
          return el != "";
        });

        var created_at: any;
        if (
          formValues.created_at !== null &&
          formValues.created_at !== ""
        ) {
          var tzoffset = new Date().getTimezoneOffset() * 60000;
          var localISOTime = new Date(formValues.created_at - tzoffset)
            .toISOString()
            .slice(0, -1);
          created_at = localISOTime;
        } else {
          created_at = moment().toISOString();
        }
        const token = await getToken();
        const user = parseJwt(token);
        let user_email = "";
        if (user) {
          user_email = user.emails[0];
        }
        const products = updateParams({
          ...values,
          ...formValues,
          name: formValues.name,
          description: description,
          type: formValues.type,
          pack_size: pack_size,
          barcode: barcode,
          flavour: flavour,
          sub_brand: sub_brand,
          cv_size_factor: formValues.cv_size_factor,
          categories: values.categories,
          base_picture_url: base_picture_url,
          milkywayid_parent: values.milkywayid_parent || '',
          created_at: created_at,
          updated_by: user_email,
        });

        try {
          setState({ ...state, loading: true });
          let res;
          if (
            values._id !== "" &&
            values._id !== "undefined" &&
            values._id !== undefined
          )
            res = await productService.updateProduct(products);
          else res = await productService.addProduct(products);
          if (res.status !== 200) {
            throw new Error("Failed to fetch!");
          } else {
            message.success("Data saved.");
            setChanged(false);
          }
          if (changed === true) {
            props.onClose();
            props.setVisible(false);
          }
        } catch (err: any) {
          const error = err.toString();
          message.error(error);
        } finally {
          setState({ ...state, loading: false });
        }
      } else message.error("Milkyway ID existed.");
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    props.setVisible(false);
  };

  const deleteCategory = (ind: number) => (
    <DeleteOutlined
      style={{
        width: "5%",
        textAlign: "right",
        color: "#DC3445",
        fontSize: 16,
      }}
      onClick={(event) => {
        delete values.categories[ind];
        setValues(values);
        setState({ ...state, loading: false });
      }}
    />
  );

  const setCategoriesLabel = async () => {
    setState({...state, loading: true });
    const immutValues = JSON.parse(JSON.stringify(props.values));
    if (immutValues.categories && immutValues.categories.length > 0) {
      for (var i = 0; i < immutValues.categories.length; i++) {
        if (
          immutValues.categories[i].manufacturer &&
          immutValues.categories[i].manufacturer !== "" &&
          immutValues.categories[i].category &&
          immutValues.categories[i].category !== "" &&
          immutValues.country &&
          immutValues.country !== "" &&
          immutValues.milkywayID &&
          immutValues.milkywayID !== ""
        ) {
          const searchData = {
            manufacturer: immutValues.categories[i].manufacturer,
            category: immutValues.categories[i].category,
            country: immutValues.country,
            milkywayID: immutValues.milkywayID,
          };
          const existed = await productService.checkLabelExists(searchData);
          if (existed && existed.data)
          immutValues.categories[i].addtolabels = existed.data.exists;
          else immutValues.categories[i].addtolabels = false;
        } else {
          immutValues.categories[i].addtolabels = false;
        }
      }
    }
    setValues(immutValues);
    form.resetFields();
    form.setFieldsValue({
      ...immutValues,
      created_at:
        immutValues.created_at === ""
          ? moment()
          : moment(immutValues.created_at, "YYYY-MM-DDTHH:mm:ss"),
      created_time:
        immutValues.created_at === ""
          ? moment()
          : moment(immutValues.created_at, "YYYY-MM-DDTHH:mm:ss"),
    });
    setState({...state, loading: false });
    
  }
  useEffect(() => {
    setCategoriesLabel();
  }, [props.values]);

  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                const date = moment();
                form.setFieldsValue({
                  _id: "",
                  base_picture_url: [],
                  created_at: date,
                  milkywayID: "",
                  milkywayid_parent: "",
                });
                setValues({ ...values, _id: "", base_picture_url: [], created_at: date.toISOString() });
              }}
              style={{ marginRight: 8 }}
            >
              Clone
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        {props.visible && (
          <Spin tip="Updating..." spinning={state.loading}>
            <Form
              {...formItemLayout}
              form={form}
              style={{ fontWeight: "bold" }}
              initialValues={formIntialValues}
              onChange={() => {
                setChanged(true);
              }}
            >
              <Form.Item label="ID" name="_id">
                <Input value={values._id} disabled />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "" }]}
                label="NAME"
                name="name"
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item label="DESCRIPTION" name="description">
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="MILKYWAY ID"
                rules={[{ required: true, message: "" }]}
                name="milkywayID"
              >
                <Input allowClear disabled={values._id !== ""} />
              </Form.Item>
              <Form.Item
                label="TYPE"
                name="type"
                rules={[{ required: true, message: "" }]}
              >
                <Select allowClear style={{ fontWeight: "normal" }}>
                  <Option value="master">Master</Option>
                  <Option value="promo">Promo</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={<span>MILKYWAYID PARENT</span>}
                name="milkywayid_parent"
              >
                {/* Todo: Refactor Autocomplete input */}
                <AutoCompleteInput
                  type={TYPE.MilkywayID}
                  collapsibleProps={{
                    categories: default_categories,
                    products: values,
                    setProduct: (abc) => {
                      setValues(abc)
                    },
                  }}
                  setValue={(abc) => {
                    setValues(abc)
                  }}
                />
              </Form.Item>
              <Form.Item label="PACK SIZE" name="pack_size">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="BARCODE" name="barcode">
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="COUNTRY"
                rules={[{ required: true, message: "" }]}
                name="country"
              >
                <SelectCountry intialValue={{ value: values.country, label: values.country_name }} />
              </Form.Item>
              <Form.Item
                label="MANUFACTURER"
                rules={[{ required: true, message: "" }]}
                name="manufacturer"
              >
                <SelectManufacturer intialValue={{ value: values.manufacturer, label: values.manufacturer_name }}/>
              </Form.Item>
              <Form.Item
                label="BRAND"
                name="brand"
                rules={[{ required: true, message: "" }]}
              >
                <SelectBrand intialValue={{ value: values.brand, label: values.brand_name }}/>
              </Form.Item>
              <Form.Item name="sub_brand_name" label="SUB BRAND">
                <AutoCompleteInput
                  type={TYPE.SubBrand}
                  collapsibleProps={{
                    categories: default_categories,
                    products: values,
                    setProduct: setValues,
                  }}
                  setValue={setValues}
                />
              </Form.Item>
              <Form.Item
                label="CREATED AT"
                name="created_at"
                rules={[{ required: true, message: "" }]}
              >
                <Form.Item
                  name="created_time"
                  style={{
                    display: "none",
                    width: "calc(50% - 12px)",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Time is required",
                    },
                  ]}
                >
                  <TimePicker />
                </Form.Item>
                <Form.Item
                  name="created_at"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 12px)",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Date is required",
                    },
                  ]}
                >
                  <DatePicker showTime />
                </Form.Item>
              </Form.Item>
              <Form.Item label="FLAVOUR" name="flavour">
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="CV SIZE FACTOR"
                name="cv_size_factor"
                shouldUpdate={(prevValue, nextValue) => {                  
                  if (prevValue.cv_size_factor !== nextValue.cv_size_factor) {
                    return true
                  }
                  return false;
                }}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="CATEGORIES"
                name="categories"
                rules={[
                  {
                    required: false,
                    message: "This field can't be empty!",
                  },
                ]}
              >
                <Collapse style={{ fontWeight: "normal" }}>
                  <Panel header="categories" key="1">
                    {values.categories &&
                      values.categories.map((val: any) => {
                        if (val && val.isDelete === false)
                          return (
                            <Collapse>
                              <Panel
                                header={`Item`}
                                key={values.categories.indexOf(val)}
                                extra={deleteCategory(
                                  values.categories.indexOf(val)
                                )}
                              >
                                <Collapsabile
                                  categories={val}
                                  products={values}
                                  index={values.categories.indexOf(val)}
                                  setProduct={setValues}
                                />
                              </Panel>
                            </Collapse>
                          );
                      })}
                    <Button
                      type="primary"
                      onClick={() => {
                        const immutVal = JSON.parse(JSON.stringify(values));
                        const immutdefaultCat = JSON.parse(JSON.stringify(product_default_categories))
                        immutVal.categories.push(...immutdefaultCat);
                        setValues(immutVal);
                        setState({ ...state, loading: false });
                      }}
                      style={{ marginTop: 10 }}
                    >
                      Add New
                    </Button>
                  </Panel>
                </Collapse>
              </Form.Item>
              <Form.Item label="IMAGE URLS" name="base_picture_url">
                <Button
                  onClick={() => {
                    setModalVisible(true);
                  }}
                >
                  Open
                </Button>
                <UploadImage
                  images={values.base_picture_url}
                  products={values}
                  formValues={form.getFieldsValue()}
                  milkywayID={values.milkywayID}
                  setProduct={(val: any) => {
                    form.setFieldsValue({
                      ...val,
                      created_at:
                      val.created_at === ""
                        ? moment()
                        : moment(val.created_at, "YYYY-MM-DDTHH:mm:ss"),
                    created_time:
                    val.created_at === ""
                        ? moment()
                        : moment(val.created_at, "YYYY-MM-DDTHH:mm:ss"),
                    })
                    setValues(val)
                  }}
                  setVisible={setModalVisible}
                  visible={modalVisible}
                ></UploadImage>
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Drawer>
    </>
  );
};

export default ProductsForm;

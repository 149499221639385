

import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Row,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";


import apiClient from "../../services/authorizedApiClient";
import { Modal } from "antd/lib/";

const { Header, Content } = Layout;

const DeletePage: React.FC = function () {
  const [form] = useForm();
  const [searching, setSearching] = useState(false);
  const [collectionName, setCollectionName] = useState("");
  const [objectId, setObjectId] = useState("");

  const onDelete = async () => {
    if (collectionName === "" || objectId === "")
      message.error("Plese enter Collection Name and Object Id");
    else {
      try {
        const data = { _id: objectId, collection: collectionName };
        const result = await apiClient()
          .post("admin/delete_doc_common", data)
          .catch(function (error) {
            if (error.response) {
              Modal.error({
                title: "Error details",
                content: "Preview: " + JSON.stringify(error.response.data),
              });
            } else if (error.request) {
            } else {
            }
          });
        message.success("Done");
      } catch (err: any) {
        const error = err.toString().indexOf("401")
          ? "Failed to fetch!"
          : err.toString();
        message.error(error);
      }
    }
  };

  useEffect(() => {}, []);

  const updateCollectionName = (event: any) => {
    setCollectionName(event.target.value);
  };

  const updateObjectId = (event: any) => {
    setObjectId(event.target.value);
  };

  return (
    <Layout style={{ padding: 20 }}>
      <Header
        style={{
          textAlign: "left",
          backgroundColor: "#F0F2F5",
          padding: 0,
          borderRadius: 15,
        }}
      >
        <Row>
          <Col span={12}>
            <h3 style={{ fontWeight: "bold", paddingLeft: 0 }}>Delete</h3>
          </Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: 0 }}></Col>
        </Row>
      </Header>
      <Content>
        <Row
          style={{
            textAlign: "left",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
            marginTop: 0,
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                Collection Name
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={20}>
                <Input
                  style={{ width: 300, borderRadius: 10 }}
                  onChange={updateCollectionName}
                  //placeholder={"Search"}
                ></Input>
              </Col>
              <Col
                span={4}
                style={{ textAlign: "right", paddingRight: 0 }}
              ></Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={24} style={{ fontWeight: "bold" }}>
                Object Id
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={20}>
                <Input
                  style={{ width: 300, borderRadius: 10 }}
                  onChange={updateObjectId}
                  //placeholder={"Search"}
                ></Input>
              </Col>
              <Col span={4} style={{ textAlign: "right", paddingRight: 0 }}>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => onDelete()}
                  style={{ width: 120 }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default DeletePage;

import { DeleteOutlined } from "@ant-design/icons";
import { Col, Divider, Form, InputNumber, Row, Select, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { Option } from "antd/lib/mentions";
import usePopulateIdentifierItems from "../../hooks/usePopulateIdentifierItems";
import apiClient from "../../services/authorizedApiClient";

interface Props {
  formListName: NamePath[],
  form: FormInstance,
  field: any,
  remove: () => void
}

interface Option {
  value: string,
  label: string
};

export default function EyeLevelItemIdentifier({
  formListName,
  remove,
  form,
  field,
}: Props) {
  const { name, ...restfield } = field;
  const identifier_type = form.getFieldValue([...formListName, name, "identifier_type"] as NamePath);
  const identifier_id = form.getFieldValue([...formListName, name, "identifier_id"] as NamePath);
  const [isLoading, identifierIDs, setIsLoading, setIdentifierID] = usePopulateIdentifierItems(form, identifier_type, identifier_id);

  async function fetchIndentifierID(value: string) {
    if (value !== "") {
      setIsLoading(true);
      const arrData = [];
      if (identifier_type === "product") {
        const result = await apiClient().get(
          "/products/search/milkywayids?id=" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i].milkywayID,
              label: result.data[i].milkywayID,
            });
          }
        }
      } else if (
        identifier_type === "sub_brand"
      ) {
        const result = await apiClient().get(
          "/sub_brands/search?q=sub_brand:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.sub_brands.length; i++) {
            arrData.push({
              value: result.data.sub_brands[i]._id,
              label: result.data.sub_brands[i].sub_brand,
            });
          }
        }
      } else if (
        identifier_type === "brand"
      ) {
        const result = await apiClient().get("/brands/search?q=brand:" + value);
        if (result) {
          for (var i = 0; i < result.data.brands.length; i++) {
            arrData.push({
              value: result.data.brands[i]._id,
              label: result.data.brands[i].brand,
            });
          }
        }
      } else if (
        identifier_type === "category"
      ) {
        const result = await apiClient().get(
          "/category/search?q=category:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i]._id,
              label: result.data[i].category,
            });
          }
        }
      } else if (
        identifier_type === "subcategory1"
      ) {
        const result = await apiClient().get(
          "/category/subcategory1/search?q=subcategory1:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i]._id,
              label: result.data[i].subcategory1,
            });
          }
        }
      } else if (
        identifier_type === "subcategory2"
      ) {
        const result = await apiClient().get(
          "/category/subcategory2/search?q=subcategory2:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i]._id,
              label: result.data[i].subcategory2,
            });
          }
        }
      } else if (
        identifier_type === "subcategory3"
      ) {
        const result = await apiClient().get(
          "/category/subcategory3/search?q=subcategory3:" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i]._id,
              label: result.data[i].subcategory3,
            });
          }
        }
      }
      setIdentifierID(arrData);
      setIsLoading(false);
    }
  }

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <>
        <Row>
          <Col span={6}>
            <Form.Item
              {...restfield}
              name={[name, "identifier_type"]}
              dependencies={[name, "identifier_id"]}
              label="Identifier Type"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            //name={"products"}
            >
              <Select
                style={{
                  fontWeight: "normal",
                }}
                showArrow={true}
                optionFilterProp="children"
                getPopupContainer={(
                  trigger
                ) => trigger.parentNode}
                onChange={() => {
                  form.setFieldValue([...formListName, name, "identifier_id"], '');
                  setIdentifierID([]);
                }}
              >
                <Option
                  value={"product"}
                >
                  product
                </Option>
                <Option
                  value={"sub_brand"}
                >
                  sub brand
                </Option>
                <Option value={"brand"}>
                  Brand
                </Option>

              </Select>
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={15}>
            <Form.Item
              {...restfield}
              name={[name, "identifier_id"]}
              dependencies={[name, "identifier_type"]}
              label="Identifier ID"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{
                  fontWeight: "normal",
                }}
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(
                  input,
                  option: any
                ) =>
                  option?.props.label
                    .toLowerCase()
                    .indexOf(
                      input.toLowerCase()
                    ) >= 0 ||
                  option?.props.value
                    .toLowerCase()
                    .indexOf(
                      input.toLowerCase()
                    ) >= 0
                }
                onSearch={(e) =>
                  fetchIndentifierID(
                    e
                  )
                }
                options={identifierIDs}
                getPopupContainer={(
                  trigger
                ) => trigger.parentNode}
              ></Select>
            </Form.Item>
          </Col>
          <Col
            span={2}
            style={{
              textAlign: "center",
            }}
          >
            <DeleteOutlined
              style={{
                width: "5%",
                textAlign: "right",
                color: "#DC3445",
                fontSize: 16,
                marginTop: 10,
              }}
              onClick={() => remove()}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={5}>
            <Form.Item
              label="Type"
              {...restfield}
              name={[name, "type"]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{
                  fontWeight: "normal",
                }}
                showArrow={true}
                optionFilterProp="children"
                getPopupContainer={(
                  trigger
                ) => trigger.parentNode}
              >
                <Option value={"base"}>
                  base
                </Option>
                <Option value={"bonus"}>
                  bonus
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={1}></Col>
          <Col xs={5}>
            <Form.Item
              {...restfield}
              name={[name, "points"]}
              label="Points"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={1}></Col>
          <Col xs={10}>
            <Form.Item
              {...restfield}
              name={[name, "shelf_numbers"]}
              label="Shelf Numbers"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{
                  fontWeight: "normal",
                }}
                mode={"multiple"}
                showArrow={true}
                optionFilterProp="children"
              >
                <Option value={"1"}>
                  1
                </Option>
                <Option value={"2"}>
                  2
                </Option>
                <Option value={"3"}>
                  3
                </Option>
                <Option value={"4"}>
                  4
                </Option>
                <Option value={"5"}>
                  5
                </Option>
                <Option value={"6"}>
                  6
                </Option>
                <Option value={"7"}>
                  7
                </Option>
                <Option value={"8"}>
                  8
                </Option>
                <Option value={"9"}>
                  9
                </Option>
                <Option value={"10"}>
                  10
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider
          style={{
            backgroundColor: "#1891FF",
          }}
        ></Divider>
      </>
    </Spin>
  )
}
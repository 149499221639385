import axios, { AxiosInstance } from "axios";
import { AuthService } from "./auth.service";

export const API_HOST = process.env.REACT_APP_API_SERVER;

var authService = new AuthService();

export async function getToken() {
  const user = await authService.getUser();
  if (!user) {
    await authService.login();
  } else {
    const istokenExpired = user.expired;
    if (istokenExpired) {
      try {
        const user = await authService.renewToken();
        return user.access_token
      } catch {
        await authService.login();
      }
    }
    return user.access_token;
  }
  return null;
}

function apiClient(headers?: any, logoutIfUnauth: boolean = true): AxiosInstance {
  const instance = axios.create({
    baseURL: API_HOST,
    withCredentials: true,
    headers,
  });
  instance.interceptors.request.use(async function (config) {
    const token = await getToken();
    return Object.assign({}, config,
      {
        headers: Object.assign({}, config.headers,
          { authorization: `Bearer ${token}` })
      }
    );
  });
  instance.interceptors.response.use((response) => {
    return response;
  }, async (error) => {
    if (error.response.status === 401 && logoutIfUnauth) {
      await authService.logout()
    } else {
      return Promise.reject(error);
    }
  })
  return instance;
}

export default apiClient;

export enum TYPE {
	Manufacturer,
	Brand,
	MilkywayID,
	Category,
	SubCategory1,
	SubCategory2,
	SubCategory3,
	Category_Manufacturer,
	Category_Category,
	SubBrand,
	Country,
}

export const enumToValues = new Map<TYPE, string>([
	[TYPE.Manufacturer, "manufacturers"],
	[TYPE.Brand,"brands"],
	[TYPE.MilkywayID, "products"], //searches for milkywayID in product
	[TYPE.Category, "category"], 
	[TYPE.SubCategory1, "subcategory1"], 
	[TYPE.SubCategory2, "subcategory2"], 
	[TYPE.SubCategory3, "subcategory3"], 
	[TYPE.Category_Manufacturer, "manufacturers"],
	[TYPE.Category_Category, "category"],
	[TYPE.SubBrand, "sub_brands"],
	[TYPE.Country, "countries"],
]);


export const enumToField = new Map<TYPE, string>([
	[TYPE.Manufacturer, "name"],
	[TYPE.Brand,"brand"],
	[TYPE.MilkywayID, "milkywayID"],
	[TYPE.Category, "category"], 
	[TYPE.SubCategory1, "subcategory1"], 
	[TYPE.SubCategory2, "subcategory2"], 
	[TYPE.SubCategory3, "subcategory3"], 
	[TYPE.Category_Manufacturer, "name"],
	[TYPE.Category_Category, "category"],
	[TYPE.SubBrand, "sub_brand"],
	[TYPE.Country, "country"],
]);
import { DeleteOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, InputNumber, message, Row, Select, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { Option } from "antd/lib/mentions";
import usePopulateIdentifierItems from "../../hooks/usePopulateIdentifierItems";
import apiClient from "../../services/authorizedApiClient";

interface Props {
  formListName: NamePath[],
  form: FormInstance,
  field: any,
  remove: () => void
}

interface Option {
  value: string,
  label: string
};

export default function NewProductItemIdentifier({
  formListName,
  remove,
  form,
  field,
}: Props) {
  const { name, ...restfield } = field;

  const identifier_type = form.getFieldValue([...formListName, name, "identifier_type"] as NamePath);
  const identifier_id = form.getFieldValue([...formListName, name, "identifier_id"] as NamePath);
  const [isLoading, identifierIDs, setIsLoading, setIdentifierID] = usePopulateIdentifierItems(form, identifier_type, identifier_id);

  async function fetchIndentifierID(value: string) {
    if (value !== "") {
      if(!identifier_type) {
        message.error("Please select identifier type first")
      }
      setIsLoading(true);
      const arrData = [];
      if (identifier_type === "product") {
        const result = await apiClient().get(
          "/products/search/milkywayids?id=" + value
        );
        if (result) {
          for (var i = 0; i < result.data.length; i++) {
            arrData.push({
              value: result.data[i].milkywayID,
              label: result.data[i].milkywayID,
            });
          }
        }
      }
      setIdentifierID(arrData);
      setIsLoading(false);
    }
  }

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <>
        <Row>
          <Col span={6}>
            <Form.Item
              {...restfield}
              name={[name, "identifier_type"]}
              dependencies={[name, "identifier_id"]}
              label="Identifier Type"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            //name={"products"}
            >
              <Select
                style={{
                  fontWeight: "normal",
                }}
                showArrow={true}
                optionFilterProp="children"
                getPopupContainer={(
                  trigger
                ) => trigger.parentNode}
                onChange={() => {
                  form.setFieldValue([...formListName, name, "identifier_id"], '');
                  setIdentifierID([]);
                }}
              >
                <Option
                  value={"product"}
                >
                  product
                </Option>

              </Select>
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={15}>
            <Form.Item
              {...restfield}
              name={[name, "identifier_id"]}
              dependencies={[name, "identifier_type"]}
              label="Identifier ID"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{
                  fontWeight: "normal",
                }}
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(
                  input,
                  option: any
                ) =>
                  option?.props.label
                    .toLowerCase()
                    .indexOf(
                      input.toLowerCase()
                    ) >= 0 ||
                  option?.props.value
                    .toLowerCase()
                    .indexOf(
                      input.toLowerCase()
                    ) >= 0
                }
                onSearch={(e) =>
                  fetchIndentifierID(
                    e
                  )
                }
                options={identifierIDs}
                getPopupContainer={(
                  trigger
                ) => trigger.parentNode}
              ></Select>
            </Form.Item>
          </Col>
          <Col
            span={2}
            style={{
              textAlign: "center",
            }}
          >
            <DeleteOutlined
              style={{
                width: "5%",
                textAlign: "right",
                color: "#DC3445",
                fontSize: 16,
                marginTop: 10,
              }}
              onClick={() => remove()}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={5}>
            <Form.Item
              label="Type"
              {...restfield}
              name={[name, "type"]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{
                  fontWeight: "normal",
                }}
                showArrow={true}
                optionFilterProp="children"
                getPopupContainer={(
                  trigger
                ) => trigger.parentNode}
              >
                <Option value={"base"}>
                  base
                </Option>
                <Option value={"bonus"}>
                  bonus
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={1}></Col>
          <Col xs={5}>
            <Form.Item
              {...restfield}
              name={[name, "points"]}
              label="Points"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={1}></Col>
          <Col xs={10}>
            <Form.Item
              {...restfield}
              name={[name, "target"]}
              label="Target"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Divider
          style={{
            backgroundColor: "#1891FF",
          }}
        ></Divider>
      </>
    </Spin>
  )
}

import React, { useEffect, useState } from 'react';
import AuthenticationContext from './AuthenticationContext';
import { authenticationStore } from '../../services/AuthenticationStore';

const AuthenticationProvider: React.FC = (props) => {
  const [currentUser, setCurrentUser] = useState(authenticationStore.currentUser);

  useEffect(()=>{
    function updateUser(){
      setCurrentUser(authenticationStore.currentUser);
	}

	authenticationStore.on('change', updateUser);
    return ()=>{ authenticationStore.off('change', updateUser); };
  }, []);

  return (
    <AuthenticationContext.Provider value={currentUser}>
      {props.children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationProvider;

export interface OutletClass {
    _id: string,
    outlet_class: string,
}

export const outlet_class_default = {
    _id: "",
    outlet_class: "",
}

export interface OutletClassDetailFormData {
    _id: string;
    outlet_class: string;
}

export interface OutletClassUpdateProps {
    outlet_class: string;
    _id: string;
}

export const updateParams = (params: OutletClassUpdateProps) => {
    return {
        _id: params._id,
        outlet_class: params.outlet_class,
    };
}
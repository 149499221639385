export interface SpaceShareSetting {
    _id: string,
    date: string,
    manufacturer: string,
    manufacturer_name: string,
    country:string,
    country_name:string,
    category:string,
    category_name:string,
    space_share:any[]
}

export const space_share_setting_default = {
    _id: "",
    date: "",
    manufacturer: "",
    manufacturer_name: "",
    country:"",
    country_name: "",
    category:"",
    category_name: "",
    space_share:Array({regions:[],channels:[],outlet_classes:[],retailers:[],identifier_type:[],identifier_id:[],universe_type:[],universe_id:[],points:[],type:[],target:[],req_display_type:[],competitors:[]})
}

export interface SpaceShareSettingDetailFormData {
    _id: string;
    date: string;
    manufacturer: string;
    manufacturer_name: string,
    country:string;
    country_name:string;
    category:string;
    category_name:string;
    space_share:Array<{regions:string[],channels:string[],outlet_classes:string[],retailers:string[],identifier_type:string[],identifier_id:any[],universe_type:string[],universe_id:any[],points:number[],type:string[],target:string[],req_display_type:string[],competitors:any[]}>
}

export interface SpaceShareSettingUpdateProps {
    date: string;
    _id: string;
    manufacturer: string;
    country:string;
    category:string;
    space_share:any[];
}

export const updateParams = (params: SpaceShareSettingUpdateProps) => {
    return {
        _id: params._id,
        date: params.date,
        manufacturer: params.manufacturer,
        country:params.country,
        category:params.category,
        space_share:params.space_share
    };
}
import {
  Form,
  Input,
  Drawer,
  Button,
  Modal,
  message,
  Spin,
} from "antd/lib/";
import React, { useEffect, useState } from "react";
import { Language, LanguageDetailFormData, language_default } from "./types";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { languageService } from "../../services/LanguageService";
import { updateParams } from "./types";

interface IChannelProps {
  values: Language;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  form: FormInstance<any>;
  onSubmit?: (data: LanguageDetailFormData) => void;
  onClose: () => void;
}

const LanguagesForm: React.FC<IChannelProps> = (props) => {
  const [state, setState] = useState({
    loading: false,
    visible: false,
  });
  const [changed, setChanged] = useState(false);
  const [values, setValues] = useState(props.values);

  var ObjectID = require("bson-objectid");

  const [form] = useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 19,
      },
    },
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields(["_id", "language"]);
      props.setVisible(false);
    }
  };

  async function validateProduct() {
    let validate = true;
    const formValues = (await form.getFieldsValue()) as LanguageDetailFormData;

    let language = formValues.language
      ? formValues.language
      : props.values.language;
    if (language === "" || language === undefined) validate = false;

    return validate;
  }

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
      form.validateFields();
    }
  }

  const onSubmit = async () => {
    try {
      const formValues = (await form.getFieldsValue()) as Language;

      let language;

      if (props.isCreate === true) {
        const _id = ObjectID();
        language = updateParams({
          ...values,
          ...formValues,
          language: formValues.language
            ? formValues.language
            : props.values.language,
          _id: _id,
        });
      } else
        language = updateParams({
          ...values,
          ...formValues,
          language: formValues.language
            ? formValues.language
            : props.values.language,
          _id: props.values._id,
        });
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await languageService.updateLanguage(language);
        else res = await languageService.addLanguage(language);

        if (res.status !== 200) {
          throw new Error("Failed to fetch!");
        } else {
          message.success("Data saved.");
          setChanged(false);
        }
        if (changed === true) {
          form.resetFields(["language"]);
          setValues(language_default);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "channel"]);
    props.setVisible(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      language: props.isCreate ? "" : props.values.language,
    })
  }, [props.isCreate, props.values.language])

  return (
    <Drawer
      title="Details"
      placement="right"
      width={"80%"}
      closable={false}
      onClose={onClose}
      visible={props.visible}
      getContainer={false}
      style={{
        display: props.visible ? "block" : "none",
        position: "fixed",
      }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={submitWarning} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      {props.visible && (
        <Spin tip="Loading..." spinning={state.loading}>
          <Form
            {...formItemLayout}
            form={form}
            initialValues={props.values}
            style={{ fontWeight: "bold" }}
            onChange={() => {
              setChanged(true);
            }}
          >
            <Form.Item label="ID" style={{ display: "none" }} name={"_id"}>
              <Input value={props.values._id} disabled />
            </Form.Item>
            <Form.Item
              label="Language"
              rules={[{ required: true, message: "" }]}
              name={"language"}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

export default LanguagesForm;

import {
  CloseCircleOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Table,
  Tooltip,
  Form,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import search from "../../helpers/search";
import useCategories from "../../hooks/useCategories";
import { category_default } from "./types";
import {
  categoriesService,
} from "../../services/CategoriesService";
import CategoriesForm from "../CategoriesPage/CategoriesForm";

const { Header, Content } = Layout;

const columns = [
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
];
const CategoriesPage: React.FC = function () {
  const [form] = useForm();
  const [searching, setSearching] = useState(false);
  const [categoriesToDisplay, setCategoriesToDisplay] = useState([]);
  const initialFormState = {
    isCreate: false,
    selected: category_default,
    visible: false,
  }
  const [formstate, setFormState] = useState(initialFormState)
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    pagination_search: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [categories, loadingCategories] = useCategories(
    state.pagination.current
  );
  const onSearch = async (key: string = "") => {
    const formValues = (await form.getFieldsValue());
    setSearching(true);
    // made array to cater more query types in future
    const searchData = [
      {
        key,
        value: encodeURIComponent(formValues.search),
      },
    ];

    const searchedData = await search(searchData, categoriesService, key);

    setCategoriesToDisplay(searchedData.data);
    setState({
      ...state,
      pagination_search: {
        ...state.pagination_search,
        total: searchedData.total,
        current: 1,
      },
    });
    setSearching(false);
  };

  const onClose = async () => {
    setState({
      ...state,
    });
    setFormState(initialFormState);
    setSearching(true);
    const formValues = (await form.getFieldsValue());
    if (formValues.search) {
      const searchData = [
        {
          key: "category",
          value: encodeURIComponent(formValues.search),
        },
      ];

      const searchedData = await search(
        searchData,
        categoriesService,
        "category"
      );
      setCategoriesToDisplay(searchedData.data);
    } else {
      const searchedData = await categoriesService.getAll(
        state.pagination.current
      );
      const data_categoriesToDisplay = searchedData.data.categories
        ? searchedData.data.categories
        : categories;
      setCategoriesToDisplay(data_categoriesToDisplay);
    }

    setSearching(false);
  };


  const handleTableChange = async (pagination: any) => {
    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination: pagination,
    });
    const data = await categoriesService.getAll(pagination.current);
    setCategoriesToDisplay(data.data.categories);
    setSearching(false);
  };

  const handleTableSearchChange = async (pagination: any) => {
    setSearching(true);
    setState({
      ...state,
      data: [],
      pagination_search: pagination,
    });
    const formValues = (await form.getFieldsValue());
    const searchData = [
      {
        key: "category",
        value: encodeURIComponent(formValues.search),
        page: pagination.current,
      },
    ];

    const data = await search(searchData, categoriesService, "category");
    setCategoriesToDisplay(data.data);
    setSearching(false);
  };

  useEffect(() => {
    setCategoriesToDisplay(categories.categories);
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        total: categories.total,
      },
    });
  }, [categories]);

  const loading = searching || loadingCategories;

  return (
    <Layout style={{ padding: 20 }}>
      <Header
        style={{
          textAlign: "left",
          backgroundColor: "#F0F2F5",
          padding: 0,
          borderRadius: 15,
        }}
      >
        <Row>
          <Col span={12}>
            <h3 style={{ fontWeight: "bold", paddingLeft: 0 }}>
              Categories (
              <span style={{ color: "#0a1e96" }}>
                {categories && categories.total ? categories.total : 0}
              </span>
              )
            </h3>
          </Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: 0 }}>
            <Button
              onClick={() => {
                setFormState({
                  ...formstate,
                  isCreate: true,
                  selected: category_default,
                  visible: true
                });
              }}
              type="primary"
              shape="round"
              icon={<PlusCircleFilled />}
              style={{ width: 150 }}
            >
              Add New
            </Button>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row
          style={{
            textAlign: "left",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
            marginTop: 0,
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={24} style={{ fontWeight: "bold" }}>
                What are you looking for?
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Form
                form={form}
                initialValues={{ search: "" }}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                }}
                onValuesChange={(changedVals) => {
                  if (changedVals.search === "") {
                    onClose();
                  }
                }}
              >

                <Col>
                  <Form.Item
                    name={"search"}
                    shouldUpdate={(prev, cur) => {
                      if (cur.search === "") {
                        onClose();
                      }
                      return true;
                    }}
                  >
                    <Input
                      style={{ width: 300, borderRadius: 10 }}
                      placeholder={"Search"}
                      prefix={<SearchOutlined style={{ color: "#ccc" }} />}
                      suffix={
                        <Tooltip title="Clear Search">
                          <CloseCircleOutlined
                            onClick={() => {
                              setCategoriesToDisplay(categories.categories);
                              setState({
                                ...state,
                                pagination: {
                                  current: 1,
                                  pageSize: 10,
                                  total: categories.total,
                                },
                              });
                              setFormState({
                                ...formstate,
                                isCreate: false,
                              });
                              form.setFieldsValue({
                                search: ""
                              });
                            }}
                            style={{
                              color: "rgba(0,0,0,.45)",
                            }}
                          />
                        </Tooltip>
                      }
                    ></Input>
                  </Form.Item>
                </Col>


                <Col style={{ textAlign: "right", paddingRight: 0 }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      onClick={() => onSearch("category")}
                      style={{ width: 160 }}
                    >
                      Search By Category
                    </Button>
                  </Form.Item>
                </Col>

              </Form>

            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: 20,
            backgroundColor: "white",
            marginTop: 20,
            borderRadius: 15,
          }}
        >
          <Col span={24}>
            {/* Todo Refactor Table with search word */}
            {form.getFieldValue("search") === "" ? (
              <Table
                //pagination={{ position: ["bottomRight", "topRight"] }}
                pagination={state.pagination}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: (event) => {
                      setFormState({
                        ...formstate,
                        selected: record,
                        visible: true,
                        isCreate: false,
                      })
                    },
                  };
                }}
                dataSource={categoriesToDisplay}
                columns={columns}
                onChange={handleTableChange}
              />
            ) : (
              <Table
                //pagination={{ position: ["bottomRight"] }}
                pagination={state.pagination_search}
                loading={loading}
                onRow={(record, _) => {
                  return {
                    onClick: () => {
                      setFormState({
                        ...formstate,
                        isCreate: false,
                        selected: record,
                        visible: true
                      })
                      // console.log(record);
                    },
                  };
                }}
                dataSource={categoriesToDisplay}
                columns={columns}
                onChange={handleTableSearchChange}
              />
            )}
          </Col>
        </Row>
      </Content>
      <CategoriesForm
        values={{
          ...formstate.selected,
        }}
        visible={formstate.visible}
        isChanged={false}
        setVisible={() => setFormState({ ...formstate, visible: false })}
        isCreate={formstate.isCreate}
        onFormDataChange={(val) => setFormState({ ...formstate, selected: val })}
        onClose={onClose}
      />
    </Layout>
  );
};

export default CategoriesPage;

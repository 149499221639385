import { SequenceSettingDetailFormData } from "./types";

export function structureSequenceSetting(data: SequenceSettingDetailFormData) {
  const { sequence } = data;
  const unewproduct = sequence.map((i) => {
    const immutdata = JSON.parse(JSON.stringify(i))
    const { identifier_id, identifier_type, points, type } = immutdata
    if (identifier_id.length > 0 || identifier_type.length > 0) {
      const map = identifier_type.map((item: string, i: number) => ({
        identifier_id: identifier_id[i] || [],
        identifier_type: item || '',
        points: points[i] || null,
        type: type[i] || ''
      }));
      delete immutdata.identifier_id;
      delete immutdata.identifier_type;
      delete immutdata.points;
      delete immutdata.type;
      return Object.assign({}, immutdata, { identifier_map: map });
    } else {
      return i
    }
  });
  return Object.assign({}, data, { sequence: unewproduct })
}

export function unstructureSequenceSetting(data: any) {
  const { sequence } = data;
  const unewproduct = sequence.map((i: any) => {
    const immutdata = JSON.parse(JSON.stringify(i))
    const { identifier_map } = immutdata
    if (identifier_map && identifier_map.length > 0) {
      const { identifier_id, identifier_type, points, type } =
        identifier_map.reduce(({ identifier_id, identifier_type, points, type }: any, i: any) => {
          if (!i) {
            return { identifier_id, identifier_type, points, type };
          }
          i.identifier_id && identifier_id.push(i.identifier_id);
          i.identifier_type && identifier_type.push(i.identifier_type);
          i.points && points.push(i.points);
          i.type && type.push(i.type)
          return { identifier_id, identifier_type, points, type };
        }, { identifier_id: [], identifier_type: [], points: [], type: [] });
      delete immutdata.identifier_map;
      return Object.assign({}, immutdata, { identifier_id, identifier_type, points, type });
    } else {
      return i
    }
  });
  return Object.assign({}, data, { sequence: unewproduct })
}
import { DeleteOutlined } from "@ant-design/icons";
import {
  Button, Collapse, Drawer, Form,
  Input, message, Modal, Select, Spin
} from "antd/lib/";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import apiClient from "../../services/authorizedApiClient";
import { storeService } from "../../services/StoreService";
import { Store, StoreDetailFormData, updateParams } from "./types";

const { Panel } = Collapse;

interface IBrandProps {
  values: Store;
  isChanged: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate: boolean;
  onSubmit?: (data: StoreDetailFormData) => void;
  onClose: () => void;
  countries: [];
  retailers: [];
  regions: [];
  channels: [];
  outlet_classes: [];
  manufacturers: [];
  sub_regions_1: [];
}

const StoreForm: React.FC<IBrandProps> = (props) => {
  const [state, setState] = useState({
    loading: false,
    visible: false,
  });
  const [changed, setChanged] = useState(false);
  const [countries, setCountries] = useState([] as Array<{ value: string, label: string }>);
  const [retailers, setRetailers] = useState([] as Array<{ value: string, label: string }>);
  const [clients, setClients] = useState([] as any);
  const [regions, setRegions] = useState([] as any);
  const [channels, setChannels] = useState([] as any);
  const [outletclasses, setOutletclasses] = useState([] as any);
  var ObjectID = require("bson-objectid");

  const [form] = useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };

  function warning() {
    Modal.warning({
      cancelText: "Discard Changes",
      okText: "Keep Editing",
      onOk: handleOk,
      onCancel: handleCancel,
      centered: true,
      title: "Warning",
      content: "Do you really want to discard your changes!",
      okCancel: true,
    });
  }

  const onClose = () => {
    props.onClose();
    if (changed) warning();
    else {
      form.resetFields(["_id", "name", "address"]);
      props.setVisible(false);
    }
  };

  async function submitWarning() {
    if ((await validateProduct()) === true) {
      Modal.confirm({
        cancelText: "Cancel",
        okText: "Submit",
        onOk: onSubmit,
        centered: true,
        title: "Confirm Dialog",
        content: "Do you really want to save your changes",
        okCancel: true,
      });
    } else {
      message.error("Please fill all '*' fields.");
    }
  }

  async function validateProduct() {
    try {
      await form.validateFields();
      return true;
    } catch (err: any) {
      return false;
    }
  }

  const onSubmit = async () => {
    try {
      const values = (await form.getFieldsValue()) as Store;

      let stores;

      if (values.manufacturer_properties) {
        values.manufacturer_properties =
          values.manufacturer_properties.filter(function (el) {
            return el != null;
          });
      }

      for (var i = 0; i < values.manufacturer_properties.length; i++) {
        if (values.manufacturer_properties[i].audit_frequency > 0) {
          values.manufacturer_properties[i].audit_frequency = Number(
            values.manufacturer_properties[i].audit_frequency
          );
        }
      }

      let dataManufacturerProperties = [];
      for (
        let ind = 0;
        ind < values.manufacturer_properties.length;
        ind++
      ) {
        if (values.manufacturer_properties[ind])
          dataManufacturerProperties.push({
            manufacturer: values.manufacturer_properties[ind].manufacturer
              ? values.manufacturer_properties[ind].manufacturer
              : "",
            region: values.manufacturer_properties[ind].region
              ? values.manufacturer_properties[ind].region
              : "",
            channel: values.manufacturer_properties[ind].channel
              ? values.manufacturer_properties[ind].channel
              : "",
            outlet_class: values.manufacturer_properties[ind].outlet_class
              ? values.manufacturer_properties[ind].outlet_class
              : "",
            sub_region1: values.manufacturer_properties[ind].sub_region1
              ? values.manufacturer_properties[ind].sub_region1
              : "",
            sub_region2: values.manufacturer_properties[ind].sub_region2
              ? values.manufacturer_properties[ind].sub_region2
              : "",
            audit_frequency: values.manufacturer_properties[ind]
              .audit_frequency
              ? values.manufacturer_properties[ind].audit_frequency
              : 0,
          });
      }

      const commonData = {
        ...values,
        name: values.name ? values.name : props.values.name,
        address: values.address
          ? values.address
          : props.values.address,
        location: values.location
          ? values.location.replace(/ /g, "")
          : props.values.location,
        country: values.country
          ? values.country
          : props.values.country,
        category: values.category
          ? values.category
          : props.values.category,
        retailer: values.retailer
          ? values.retailer
          : props.values.retailer,
        manufacturer_properties: dataManufacturerProperties,
      }
      if (props.isCreate === true) {
        const _id = ObjectID();
        stores = updateParams(Object.assign({}, commonData, { _id }));
      } else
        stores = updateParams(Object.assign({}, commonData, { id: values._id }));
      try {
        setState({ ...state, loading: true });
        let res;
        if (props.isCreate === false)
          res = await storeService.updateStore(stores);
        else res = await storeService.addStore(stores);
        if (res)
          if (res.status !== 200) {
            throw new Error("Failed to fetch!");
          } else {
            message.success("Data saved.");
            // setResult(res.data);
            setChanged(false);
          }
        if (changed === true) {
          form.resetFields([
            "name",
            "address",
            "location",
            "country",
            "retailer",
          ]);
          props.onClose();
          props.setVisible(false);
        }
      } catch (err: any) {
        const error = err.toString();
        message.error(error);
      } finally {
        setState({ ...state, loading: false });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleOk = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
    setChanged(false);
    form.resetFields(["_id", "name", "address", "location"]);
    props.setVisible(false);
  };

  const deleteManufacturer = (remove: () => void) => (
    <DeleteOutlined
      style={{ color: "#DC3445", fontSize: 16 }}
      onClick={() => {
        remove()
        setState({ ...state, loading: false });
      }}
    />
  );

  async function fetchCountry(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrCountries = [];
      const result = await apiClient().get(
        "/countries/search?q=country:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.countries.length; i++) {
          arrCountries.push({
            value: result.data.countries[i]._id,
            label: result.data.countries[i].country,
          });
        }
      }
      await setCountries(arrCountries);
      setState({ ...state, loading: false });
    }
  }

  async function fetchRetailer(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrRetailers = [];
      const result = await apiClient().get("/retailers/search?q=name:" + value);
      if (result) {
        for (var i = 0; i < result.data.retailers.length; i++) {
          arrRetailers.push({
            value: result.data.retailers[i]._id,
            label: result.data.retailers[i].name,
          });
        }
      }
      await setRetailers(arrRetailers);
      setState({ ...state, loading: false });
    }
  }

  async function fetchClient(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrClients = [];
      const result = await apiClient().get(
        "/manufacturers/search?q=name:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.manufacturers.length; i++) {
          arrClients.push({
            value: result.data.manufacturers[i]._id,
            label: result.data.manufacturers[i].name,
          });
        }
      }
      await setClients(arrClients);
      setState({ ...state, loading: false });
    }
  }

  async function fetchRegion(value: string = "", client: string = "") {
    const country = form.getFieldValue("country") || ''
    if (!(country && client)) {
      return;
    };
    setState({ ...state, loading: true });
    const arrRegions = [];
    const result = await apiClient().get("/regions/searchFiltered",
      {
        params:
        {
          q: `region:${value || ''}`,
          ...country && { country },
          ...client && { client }
        }
      });
    if (result) {
      for (var i = 0; i < result.data.regions.length; i++) {
        arrRegions.push({
          value: result.data.regions[i]._id,
          label: result.data.regions[i].region,
        });
      }
    }
    await setRegions(arrRegions);
    setState({ ...state, loading: false });
  }

  async function fetchChannel(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrChannels = [];
      const result = await apiClient().get(
        "/channels/search?q=channel:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.channels.length; i++) {
          arrChannels.push({
            value: result.data.channels[i]._id,
            label: result.data.channels[i].channel,
          });
        }
      }
      await setChannels(arrChannels);
      setState({ ...state, loading: false });
    }
  }

  async function fetchOutletClass(value: string) {
    if (value !== "") {
      setState({ ...state, loading: true });
      const arrOutletClasses = [];
      const result = await apiClient().get(
        "/outlet_classes/search?q=outlet_class:" + value
      );
      if (result) {
        for (var i = 0; i < result.data.outlet_classes.length; i++) {
          arrOutletClasses.push({
            value: result.data.outlet_classes[i]._id,
            label: result.data.outlet_classes[i].outlet_class,
          });
        }
      }
      await setOutletclasses(arrOutletClasses);
      setState({ ...state, loading: false });
    }
  }

  useEffect(() => {
    async function fetchStoreDetail() {
      setState({ ...state, loading: true });
      const arrCountries = [];
      if (props.values.country && props.values.country !== "") {
        const result_country = await apiClient().get(
          "/countries/" + props.values.country + "/getName"
        );
        arrCountries.push({
          value: props.values.country,
          label: result_country.data
            ? result_country.data
            : props.values.country,
        });
      }
      setCountries(arrCountries);
      const arrRetailers = [];
      if (props.values.retailer && props.values.retailer !== "") {
        const result_retailer = await apiClient().get(
          "/retailers/" + props.values.retailer + "/getName"
        );
        arrRetailers.push({
          value: props.values.retailer,
          label: result_retailer.data
            ? result_retailer.data
            : props.values.retailer,
        });
      }
      setRetailers(arrRetailers);

      if (props.values._id && props.values._id !== "") {
        const result = await apiClient().get(
          "/stores/details/" + props.values._id
        );

        const arrClients = [];
        for (var i = 0; i < result.data.manufacturer_properties.length; i++) {
          arrClients.push({
            value: result.data.manufacturer_properties[i].manufacturer,
            label: result.data.manufacturer_properties[i].manufacturer_name,
          });
        }
        setClients(arrClients);
        const arrRegions = [];
        for (var i = 0; i < result.data.manufacturer_properties.length; i++) {
          arrRegions.push({
            value: result.data.manufacturer_properties[i].region,
            label: result.data.manufacturer_properties[i].region_name,
          });
        }
        setRegions(arrRegions);
        const arrChannels = [];
        for (var i = 0; i < result.data.manufacturer_properties.length; i++) {
          arrChannels.push({
            value: result.data.manufacturer_properties[i].channel,
            label: result.data.manufacturer_properties[i].channel_name,
          });
        }
        setChannels(arrChannels);
        const arrOutletClasses = [];
        for (var i = 0; i < result.data.manufacturer_properties.length; i++) {
          arrOutletClasses.push({
            value: result.data.manufacturer_properties[i].outlet_class,
            label: result.data.manufacturer_properties[i].outlet_class_name,
          });
        }
        setOutletclasses(arrOutletClasses);
      }
      setState({ ...state, loading: false });
    }
    fetchStoreDetail();
    form.setFieldsValue(props.values)
  }, [props.values]);

  console.log(countries, retailers)
  return (
    <>
      <Drawer
        title="Details"
        placement="right"
        width={"80%"}
        closable={false}
        onClose={onClose}
        visible={props.visible}
        getContainer={false}
        style={{
          display: props.visible ? "block" : "none",
          position: "fixed",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitWarning} type="primary">
              Submit
            </Button>
          </div>
        }
      >
        <Spin tip="Loading..." spinning={state.loading}>
          {/* This is purely controlled form */}
          <Form
            {...formItemLayout}
            initialValues={props.values}
            form={form}
            style={{ fontWeight: "bold" }}
            onChange={() => {
              setChanged(true);
            }}
          >
            <Form.Item label="ID" style={{ display: "none" }} name={"_id"}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Name"
              rules={[{ required: true, message: "" }]}
              name={"name"}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Address"
              rules={[{ required: true, message: "" }]}
              name={"address"}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Location"
              rules={[{ required: true, message: "" }]}
              name={"location"}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Country"
              rules={[{ required: true, message: "" }]}
              name={"country"}
            >
              <Select
                style={{ width: "100%", fontWeight: "normal" }}
                placeholder="Please select"
                showSearch
                optionFilterProp="children"
                //onSearch={onSearch}
                filterOption={(input, option) => {
                  if (option) {
                    return option?.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                      option?.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                  } else {
                    return false;
                  }
                }}
                onSearch={(e) => fetchCountry(e)}
                options={countries}
              ></Select>
            </Form.Item>
            <Form.Item
              label="Retailer"
              rules={[{ required: true, message: "" }]}
              name={"retailer"}
            >
              <Select
                style={{ width: "100%", fontWeight: "normal" }}
                placeholder="Please select"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option) {
                    return option?.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                      option?.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                  } else {
                    return false;
                  }
                }}
                onSearch={(e) => fetchRetailer(e)}
                options={retailers}
              ></Select>
            </Form.Item>
            <Form.Item name="manufacturer_properties" label="Manufacturers">
              <Form.List name="manufacturer_properties">
                {(fields, { add, remove }) =>
                  <Collapse style={{ fontWeight: "normal" }}>
                    <Panel header="Items" key="manufacturer-items-1">

                      <Button
                        type="primary"
                        onClick={() => {
                          add();
                          setState({ ...state, loading: false });
                        }}
                        style={{ marginBottom: 10 }}
                      >
                        Add New
                      </Button>
                      {fields.map(({ key, name, ...restField }) => (
                        <Collapse key={key}>
                          <Panel
                            header={`Item`}
                            key={name + "manufacturer_properties"}
                            extra={deleteManufacturer(() => remove(name))}
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "manufacturer"]}
                              label="Client"
                              rules={[{ required: true, message: "" }]}
                            >
                              <Select
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option?.props.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                  option?.props.value
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onSearch={(e) => fetchClient(e)}
                                options={clients}
                              ></Select>
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate
                            >
                              {() => {
                                return (
                                  <Form.Item
                                    {...restField}
                                    name={[name, "region"]}
                                    label="Region"
                                    rules={[{ required: true, message: "" }]}
                                    shouldUpdate
                                    initialValue={""}
                                  >
                                    <Select
                                      style={{ width: "100%" }}
                                      onClick={(e: any) => fetchRegion(e.target.value, form.getFieldValue(["manufacturer_properties", name, "manufacturer"]))}
                                      placeholder="Please select"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option?.props.label
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0 ||
                                        option?.props.value
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      disabled={!(form.getFieldValue("country") && form.getFieldValue(["manufacturer_properties", name, "manufacturer"]))}
                                      onSearch={(e) => fetchRegion(e, form.getFieldValue(["manufacturer_properties", name, "manufacturer"]))}
                                      options={regions}
                                    />
                                  </Form.Item>
                                )
                              }}
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label="Channel"
                              name={[name, "channel"]}
                              rules={[{ required: true, message: "" }]}
                            >
                              <Select
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option?.props.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                  option?.props.value
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onSearch={(e) => fetchChannel(e)}
                                options={channels}
                              ></Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label="Outlet Class"
                              name={[name, "outlet_class"]}
                              rules={[{ required: true, message: "" }]}

                            >
                              <Select
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                showSearch
                                optionFilterProp="children"
                                //onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option?.props.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                  option?.props.value
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onSearch={(e) => fetchOutletClass(e)}
                                options={outletclasses}
                              ></Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label="Audit frequency"
                              name={[name, "audit_frequency"]}
                              rules={[{ required: true, message: "" }]}
                            >
                              <Input />
                            </Form.Item>
                          </Panel>
                        </Collapse>
                      ))}
                    </Panel>
                  </Collapse>
                }
              </Form.List>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default StoreForm;

export interface Store {
    _id: string,
    name: string,
    address: string,
    location:string,
    retailer: string,
    retailer_name: string,
    country: string,
    country_name: string,
    category: string,
    category_name: string,
    manufacturer_properties:any[]
}

export const store_default = {
    _id: "",
    name: "",
    address: "",
    location:"",
    retailer: "",
    retailer_name: "",
    country: "",
    country_name: "",
    category: "",
    category_name: "",
    manufacturer_properties:[]
}

export interface StoreDetailFormData {
    _id: string;
    name: string;
    address: string;
    location:string;
    retailer: string;
    retailer_name: string;
    country: string;
    country_name: string;
    category: string;
    category_name: string;
    manufacturer_properties:any[]
}

export interface StoreUpdateProps {
    _id: string;
    name: string;
    address: string;
    location:string;
    retailer: string;
    country: string;
    category: string;
    manufacturer_properties:any[]
}

export const updateParams = (params: StoreUpdateProps) => {
    return {
        _id: params._id,
        name: params.name,
        address: params.address,
        location: params.location,
        retailer: params.retailer,
        country: params.country,
        category: params.category,
        manufacturer_properties: params.manufacturer_properties
    };
}
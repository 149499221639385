"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = void 0;
var oidc_client_1 = require("oidc-client");

var AuthService = /** @class */ (function() {
  function AuthService() {
    var settings = {
      authority: process.env.REACT_APP_AUTHORITY,

      loadUserInfo: true,

      redirect_uri: process.env.REACT_APP_REDIRECT_URI,

      response_type: "code",

      client_id: process.env.REACT_APP_CLIENT_ID,

      scope: process.env.REACT_APP_SCOPE,
      userStore: new oidc_client_1.WebStorageStateStore({ store: window.localStorage}),
      metadata: {
        issuer: process.env.REACT_APP_ISSUER,
        authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
        token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
        jwks_uri: process.env.REACT_APP_JWKS_URI,
        end_session_endpoint: process.env.REACT_APP_END_SESSION_ENDPOINT,
      },
    };
    this.userManager = new oidc_client_1.UserManager(settings);
    oidc_client_1.Log.logger = window.console;
    oidc_client_1.Log.level = oidc_client_1.Log.INFO;
  }
  AuthService.prototype.getUser = function() {
    return this.userManager.getUser();
  };
  AuthService.prototype.login = function() {
    return this.userManager.signinRedirect();
  };
  AuthService.prototype.renewToken = function() {
    return this.userManager.signinSilent();
  };
  AuthService.prototype.logout = function() {
    return this.userManager.signoutRedirect();
  };
  return AuthService;
})();
exports.AuthService = AuthService;

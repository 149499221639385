import { DeleteOutlined } from "@ant-design/icons";
import { Col, Form, InputNumber, Row, Select, Spin } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { NamePath } from "antd/lib/form/interface";
import { useState } from "react";
import apiClient from "../../services/authorizedApiClient";

interface Props {
  formListName: NamePath,
  form: FormInstance,
  field: any,
  remove: () => void
}

interface Option {
  value: string,
  label: string
};

export default function RspItemProduct({
  formListName,
  field,
  remove,
  form: formInstance,
}: Props) {
  const { name, ...restfield } = field;
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<{
    value: any;
    label: any;
  }[]>([]);

  async function fetchProduct(value: string) {
    if (value !== "") {
      setIsLoading(true)
      const arrData = [];
      const result = await apiClient().get(
        "/products/search/milkywayids?id=" + value
      );
      if (result) {
        for (var i = 0; i < result.data.length; i++) {
          arrData.push({
            value: result.data[i].milkywayID,
            label: result.data[i].milkywayID,
          });
        }
      }
      setProduct(arrData);
      setIsLoading(false)
    }
  }

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Row>
        <Col span={16}>
          <Form.Item
            {...restfield}
            name={[name, "product"]}
            rules={[
              (form) => ({
                // A hack for the dependency validation
                // kindly check - https://github.com/ant-design/ant-design/issues/26351
                validator: async (_, value) => {
                  const path = [...formListName as NamePath[], name, "value"] as NamePath
                  const shareVal = form.getFieldValue(path);
                  if (value && (shareVal === null || isNaN(shareVal))) {
                    return Promise.reject(new Error('Please enter both product and share field'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              placeholder="Please select"
              showSearch={true}
              onSearch={(e) =>
                fetchProduct(e)
              }
              onChange={async () => {
                try {
                  const path = [...formListName as NamePath[], name, "value"] as NamePath
                  await formInstance.validateFields([path]);
                } catch {
                  // Do nothing
                }
              }}
              options={product}
              getPopupContainer={(
                trigger
              ) => trigger.parentNode}
            />
          </Form.Item>
        </Col>
        <Col span={1}></Col>
        <Col span={5}>
          <Form.Item
            {...restfield}
            name={[name, "value"]}
            rules={[
              (form) => ({
                // A hack for the dependency validation
                // kindly check - https://github.com/ant-design/ant-design/issues/26351
                validator: async (_, value)  => {
                  const path = [...formListName as NamePath[], name, "product"] as NamePath
                  const productVal = form.getFieldValue(path);
                  if (value && !productVal) {
                    return Promise.reject(new Error('Please enter both product and value field'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              onChange={async () => {
                try {
                  const path = [...formListName as NamePath[], name, "product"] as NamePath
                  await formInstance.validateFields([path]);
                } catch {
                  // Do nothing
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col
          span={2}
          style={{
            textAlign: "center",
          }}
        >
          <DeleteOutlined
            style={{
              width: "5%",
              textAlign: "right",
              color: "#DC3445",
              fontSize: 16,
              marginTop: 7,
              marginLeft: 20,
            }}
            onClick={() => remove()}
          />
        </Col>
      </Row>
    </Spin>
  )
}
export interface SubBrand {
    _id: string,
    sub_brand: string,
    brand: string,
    brand_name: string,
    manufacturer: string,
    manufacturer_name: string,
}

export const sub_brand_default = {
    _id: "",
    sub_brand: "",
    brand: "",
    brand_name: "",
    manufacturer: "",
    manufacturer_name: "",
}

export interface SubBrandDetailFormData {
    _id: string;
    sub_brand: string;
    manufacturer: string;
    manufacturer_name: string;
    brand: string;
    brand_name: string;
}

export interface SubBrandUpdateProps {
    sub_brand: string;
    manufacturer: string;
   brand: string;
    _id: string;
}

export const updateParams = (params: SubBrandUpdateProps) => {
    return {
        _id: params._id,
        sub_brand: params.sub_brand,
        brand: params.brand,
        manufacturer: params.manufacturer,
    };
}
import React from "react";
import { Card, Row, Col, Spin } from "antd";


import useCountries from "../../hooks/useCountries";
import useBrands from "../../hooks/useBrands";
import useCategories from "../../hooks/useCategories";
import useChannels from "../../hooks/useChannels";
import useLanguages from "../../hooks/useLanguages";
import useManufacturers from "../../hooks/useManufacturers";
import useOutletClasses from "../../hooks/useOutletClasses";
import useProducts from "../../hooks/useProducts";
import useRegions from "../../hooks/useRegions";
import useRetailers from "../../hooks/useRetailers";
import useStores from "../../hooks/useStores";
import useSubBrands from "../../hooks/useSubBrands";
import useSubCategories1 from "../../hooks/useSubCategories1";
import useSubCategories2 from "../../hooks/useSubCategories2";
import useSubCategories3 from "../../hooks/useSubCategories3";
import useUsers from "../../hooks/useUsers";

const DashboardPage: React.FC = function () {
  const [countries, loadingCountries] = useCountries(1);
  const [brands, loadingBrands] = useBrands(1);
  const [categories, loadingCategories] = useCategories(1);
  const [channels, loadingChannels] = useChannels(1);
  const [languages, loadingLanguages] = useLanguages(1);
  const [manufacturers, loadingManufacturers] = useManufacturers(1);
  const [outletClasses, loadingOutletClasses] = useOutletClasses(1);
  const [products, loadingProducts] = useProducts(1);
  const [regions, loadingRegions] = useRegions(1);
  const [retailers, loadingRetailers] = useRetailers(1);
  const [stores, loadingStores] = useStores(1);
  const [subBrands, loadingSubBrands] = useSubBrands(1);
  const [subCategories1, loadingSubCategories1] = useSubCategories1(1);
  const [subCategories2, loadingSubCategories2] = useSubCategories2(1);
  const [subCategories3, loadingSubCategories3] = useSubCategories3(1);
  const [users, loadingUsers] = useUsers(3);
  const loading =
    loadingCountries ||
    loadingBrands ||
    loadingCategories ||
    loadingChannels ||
    loadingLanguages ||
    loadingManufacturers ||
    loadingOutletClasses ||
    loadingProducts ||
    loadingRegions ||
    loadingRetailers ||
    loadingStores ||
    loadingSubBrands ||
    loadingSubCategories1 ||
    loadingSubCategories1 ||
    loadingSubCategories3 ||
    loadingUsers;

  function formatNumber(num: any) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return (
    <div style={{ padding: 10 }}>
      {/*<h1>Dashboard</h1>*/}
      <Spin tip="Loading..." spinning={loading}>
        <Row>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Brands
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(brands.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Categories
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(categories.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Channels
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(channels.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Countries
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(countries.total)}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Languages
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(languages.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Manufacturers
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(manufacturers.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Outlet Classes
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(outletClasses.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Products
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(products.total)}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Regions
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(regions.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Retailers
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(retailers.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Stores
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(stores.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Sub Brand
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(subBrands.total)}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Sub Category 1
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(subCategories1.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Sub Category 2
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(subCategories2.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Sub Category 3
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(subCategories3.total)}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6} style={{ padding: 10 }}>
            <Card title="" bordered={false} style={{ borderRadius: 7 }}>
              <Row>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    color: "#4B5DE8",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Users
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(users.total)}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>

    </div>
  );
};

export default DashboardPage;
